import AddNotes from "./Addnote";
import DeleteNote from "./DeleteNote";
import "./notes.scss";
import { useContext } from "react";
import { NextContext } from "../../../../Context";

const NoteList = ({ handleAddNote, handleDeleteNote }) => {
  const { jobCard } = useContext(NextContext);

  return (
    <div className="notes-list">
      {jobCard?.notes?.map((item, idx) => (
        <DeleteNote
          id={item.id}
          text={item.text}
          key={idx}
          handleDeleteNote={handleDeleteNote}
        />
      ))}
      <AddNotes handleAddNote={handleAddNote} />
    </div>
  );
};
export default NoteList;
