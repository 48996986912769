import React from "react";
import "./collaborators.scss";

import CollabCard from "./CollaboratorCards";

// Team Photos
import Simona from "../../../../pictures/Simona_Ziauberyte.webp";
import Ismail from "../../../../pictures/Ismail_Duzel.webp";
import Esra from "../../../../pictures/Esra_Akkan.webp";
import Kareem from "../../../../pictures/Abdulkarim_Alarmanazi.webp";

export default function Collaborators() {
  const collabData = [
    {
      name: "Simona Ziauberyte",
      position: "Full Stack Web Developer",
      about: `Full stack developer with extensive international background in hospitality and retail. Passionate about taking new challenges, user-centered products, solving problems.`,
      linkedin: "https://www.linkedin.com/in/semuona/",
      github: "https://github.com/semuona",
      img: Simona,
    },
    {
      name: "Esra Akkan",
      position: "Full Stack Web Developer",
      about: `I am a dedicated, detail-oriented Junior Full Stack Developer, building user-friendly websites and apps with passion and patience.`,
      linkedin: "https://www.linkedin.com/in/akkanesra/",
      github: "https://github.com/esrkkn",
      img: Esra,
    },
    {
      name: "Abdulkarim Alarmanazi",
      position: "Full Stack Web Developer",
      about: `Professional with an extensive background in Web Development.
      Finding joy in solving problems while working in a team.`,
      linkedin: "https://www.linkedin.com/in/abdulkarim-alarmanazi/",
      github: "https://github.com/kemdev",
      img: Kareem,
    },
    {
      name: "Ismail Duzel",
      position: "Full Stack Web Developer",
      about: ` An innovative and hardworking web developer who is extremely
      enthusiastic about the layout, design and coding of websites.`,
      linkedin: "https://www.linkedin.com/in/ismailduzel/",
      github: "https://github.com/iduzel",
      img: Ismail,
    },
  ];

  return (
    <div className="collabContainer">
      <h1 className="teamH1">Our Team</h1>
      <div className="row">
        <section className="mb-10 text-center ">
          <div className="row gx-lg-5 m-auto" style={{ maxWidth: 2500 }}>
            {collabData?.map((collab, index) => (
              <CollabCard key={index} {...collab} index={index} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
