import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

const log = (config) => (set, get, api) =>
  config(
    (...args) => {
      console.log('  applying', args);
      set(...args);
      console.log('  new state', get());
    },
    get,
    api
  );

const useCurrentJobsDetailsStore = create(
  log((set, get) => ({
    currentJobDetails: {
      jobDetails: {},
      companyLogo: null,
    },
    open: false,
    currentJobDetailsID: '',

    setCurrentJobDetails: (currentJobDetails) => set({ currentJobDetails }),
    setCurrentJobDetailsId: (currentJobDetailsID) =>
      set({ currentJobDetailsID }),
    setOpen: (open) => set({ open }),
  }))
);

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('Store', useCurrentJobsDetailsStore);
}

export { useCurrentJobsDetailsStore };
