import React, { useState } from "react";
import CustomCard from "./CustomCard";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import Tooltip from "@mui/material/Tooltip";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";

import { motion } from "framer-motion";

export default function OneCard({
  currentCardData,
  setCurrentContactCard,
  jobCard,
}) {


  const [isCopiedMail, setIsCopiedMail] = useState(0)
  const [isCopiedPhone, setIsCopiedPhone] = useState(0)

  
  return (
    <div
      className="OneCard d-flex flex-wrap justify-content-center"
      style={{ overflowY: "scroll", maxHeight: "20rem" }}
    >
      {jobCard?.contacts?.map((contact, idx) => (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          transition={{ type: "spring", stiffness: 100 }}
          className="w-50 p-2"
          key={idx}
        >
          <CustomCard
            color={contact?.backgroundColor}
            avatar={contact?.avatar}
            title={contact?.name}
            brand={contact?.jobTitle}
            linkedin={
              <a
                href={contact.linkedin}
                className="card-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            }
            website={
              <a
                href={contact.companyWebsite}
                className="card-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LanguageIcon />
              </a>
            }
            cover={
              "https://p4.wallpaperbetter.com/wallpaper/631/101/285/3d-abstract-fractal-fractal-flowers-wallpaper-preview.jpg"
            }
            phone={
              <Tooltip
                title={idx === isCopiedPhone ? 'copied' : `${contact?.phone} click to copy`}
                arrow
                placement="top"
                onClick={() => {
                  navigator.clipboard.writeText(contact.phone);
                  setIsCopiedPhone(idx)
                }}
                style={{ cursor: "pointer" }}
              >
                <LocalPhoneIcon />
              </Tooltip>
            }
            email={
              <Tooltip
                title={idx === isCopiedMail ? 'copied' :  `${contact?.contactEmail} click to copy` }
                arrow
                placement="top"
                onClick={() => {
                  navigator.clipboard.writeText(contact?.contactEmail);
                  setIsCopiedMail(idx);
                }}
                style={{ cursor: "pointer" }}
              >
                <EmailIcon />
              </Tooltip>
            }
          />
        </motion.div>
      ))}

    </div>
  );
}
