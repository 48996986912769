import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
// image
import brand from '../img/logo_black.png';
import { useNavigate, useLocation } from 'react-router';
import { useJobsStore } from 'src/stores/jobStore';

import { shallow } from 'zustand/shallow';

export const NextContext = createContext();

export default function NextContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem('authorizedUser')) || {}
  );

  const navigate = useNavigate();
  const location = useLocation();

  const defaultJobCard = {
    owner: currentUser?._id,
    company: '',
    title: '',
    description: '',
    location: '',
    salary: '',
    link: '',
    status: 'applied',
    favorites: false,
    color: 'hotpink',
    contacts: [],
    toDoList: [],
    notes: [],
    coverLetter: '',
  };

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading');

  const [allPosts, setAllPosts] = useState([]);
  const [currentCardData, setCurrentCardData] = useState(false);
  const [requiredData, setRequiredData] = useState(false);
  const [currentContactCard, setCurrentContactCard] = useState('');
  const [toastInfo, setToastInfo] = useState({
    open: false,
    message: '',
    variant: '',
  });

  const [jobCard, setJobCard] = useState(defaultJobCard);
  const [note, setNote] = useState('');
  const [todo, setTodo] = useState('');
  const [todos, setTodos] = useState([]);
  const [totalTodoList, setTotalTodolist] = useState();
  const [status, setStatus] = useState('applied');
  const [favorite, setFavorite] = useState(false);
  const [statusState, setStatusState] = useState({
    initialState: {
      wishlist: {
        title: 'Wishlist',
        items: [],
      },
      applied: {
        title: 'Applied',
        items: [],
      },
      interview: {
        title: 'Interview',
        items: [],
      },
      offer: {
        title: 'Offer',
        items: [],
      },
      rejected: {
        title: 'Rejected',
        items: [],
      },
    },
  });

  const jobStore = useJobsStore((state) => ({ ...state }), shallow);

  useEffect(() => {
    const saveUserToLocal = (user) => {
      const stringUser = JSON.stringify(user);
      localStorage.setItem('authorizedUser', stringUser);
    };
    saveUserToLocal(currentUser);
  }, [currentUser]);

  useEffect(() => {
    const getData = async (id) => {
      setLoading(true);
      setLoadingMessage('Fetching Your Data from Database');
      try {
        const response = await axios.get(`/posts/list/${currentUser._id}`);
        if (response.data) {
          setAllPosts(response?.data?.posts);
          setTimeout(() => {
            setLoadingMessage('Successfully Fetched Your Data from Database');
          }, 1000);
          setLoading(false);
        }
      } catch (e) {
        console.log('Error:', e);
        setLoadingMessage('Error Fetching Your Data from Database');
        setTimeout(() => {
          setLoading(false);
          navigate('error', {
            replace: true,
            state: {
              error: {
                code: e?.response?.status,
                message: e?.response?.statusText,
              },
            },
          });
        }, 1000);
      }
    };

    if (
      currentUser._id &&
      location.pathname === '/dashboard' &&
      !allPosts?.length
    ) {
      getData();
    }

    if (!currentUser._id && location.pathname === '/dashboard') {
      navigate('/login', { replace: true });
    }

    return () => {
      setLoading(false);
      setLoadingMessage('Loading');
    };
  }, [allPosts?.length, currentUser._id, location.pathname, navigate]);

  return (
    <NextContext.Provider
      value={{
        allPosts,
        setAllPosts,
        currentUser,
        setCurrentUser,
        brand,
        jobCard,
        setJobCard,
        currentCardData,
        setCurrentCardData,
        note,
        setNote,
        todo,
        setTodo,
        todos,
        setTodos,
        favorite,
        setFavorite,
        status,
        setStatus,
        requiredData,
        setRequiredData,
        statusState,
        setStatusState,
        currentContactCard,
        setCurrentContactCard,
        defaultJobCard,
        totalTodoList,
        setTotalTodolist,
        loading,
        setLoading,
        loadingMessage,
        setLoadingMessage,
        toastInfo,
        setToastInfo,
        jobStore,
      }}
    >
      {children}
    </NextContext.Provider>
  );
}
