import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useState, useEffect } from 'react';
import './dragTest.scss';
import axios from 'axios';
import SingleCard from '../../../../Card/CardComponents/AddJobCard/SingleCard/SingleCard';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Color from 'color';

import AddSpeedDial from './GridComponents/AddSpeedDial.jsx';

const backgroundColor = Color('#6d5295');
const whenDragged = backgroundColor.lighten(0.4);

function GridCards({
  children,
  allPosts,
  currentUser,
  jobCard,
  setJobCard,
  defaultJobCard,
  setAllPosts,
  setCurrentCardData,
}) {
  const [update, setUpdate] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    const handleUpdateCard = async () => {
      const postid = jobCard?._id;

      try {
        const response = await axios.put(
          `/posts/update/${currentUser._id}/${postid}`,
          jobCard
        );
        response?.data?.success && setJobCard(defaultJobCard);

        if (response?.data?.success) {
          const updatedPosts = allPosts?.map((post) =>
            post?._id === postid ? jobCard : post
          );
          setAllPosts(updatedPosts);
          setUpdate(false);
        }

        if (!response?.data)
          console.log(
            "Couldn't retrieve posts from GridCards with error: ",
            response
          );
      } catch (error) {
        console.log('our error is', error.response.request._response);
      }
    };

    if (update) handleUpdateCard();
  }, [update]);

  const [columns, setColumns] = useState({});

  useEffect(() => {
    const wishlistObject = filterPostsByStatus('wishlist');
    const appliedObject = filterPostsByStatus('applied');
    const interviewObject = filterPostsByStatus('interview');
    const offerObject = filterPostsByStatus('offer');
    const rejectedObject = filterPostsByStatus('rejected');

    setColumns({
      wishlist: {
        name: 'Wishlist',
        items: wishlistObject,
      },
      applied: {
        name: 'Applied',
        items: appliedObject,
      },
      interview: {
        name: 'Interview',
        items: interviewObject,
      },
      offer: {
        name: 'Offer',
        items: offerObject,
      },
      rejected: {
        name: 'Rejected',
        items: rejectedObject,
      },
    });
  }, [allPosts]);

  const filterPostsByStatus = (status) =>
    allPosts
      ?.filter((post) => post?.status === status)
      ?.map((post) => ({
        id: post?._id,
        post: post,
      }));

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      setJobCard({ ...jobCard, status: destination.droppableId });

      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns((prevColumns) => ({
        ...prevColumns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      }));
      setUpdate(true);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns((prevColumns) => ({
        ...prevColumns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      }));
    }
  };

  return (
    <div className='w-100 card-container-content h-100'>
      <MDBRow className='m-0'>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.entries(columns)?.map(([columnId, column], colIndex) => (
            <MDBCol
              lg={2}
              md={2}
              key={colIndex}
              className='rounded position-relative p-0'
            >
              <div className='mb-5 rounded'>
                <div className='custom-card-header d-flex justify-content-between align-items-center'>
                  <h3 className='m-auto '>{column.name}</h3>
                  <div className='addSpeedDial'>
                    <AddSpeedDial
                      jobCard={jobCard}
                      setJobCard={setJobCard}
                      statusCol={column.name}
                      setCurrentCardData={setCurrentCardData}
                    />
                  </div>
                </div>
              </div>
              {children}
              <Droppable
                droppableId={columnId}
                key={columnId}
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      padding: 15,
                      width: '100%',
                      height: '70vh',
                      overflowY: 'auto',
                    }}
                    className='rounded dragColumnContainer'
                  >
                    {column?.items?.map((item, index) => (
                      <Draggable
                        key={item?.id}
                        draggableId={item?.id}
                        index={index}
                        onDragStart={() => setJobCard(item.post)}
                      >
                        {(provided, snapshot) => (
                          <div
                            className='card-parent'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: 'none',
                              ...provided.draggableProps.style,
                            }}
                            onMouseDownCapture={() => setJobCard(item.post)}
                          >
                            <SingleCard
                              post={item.post}
                              index={index}
                              setOpenDeleteModal={setOpenDeleteModal}
                              openDeleteModal={openDeleteModal}
                              postID={item.post._id}
                            />
                            <span
                              className='d-none'
                              style={{ display: 'none' }}
                            >
                              {provided.placeholder}
                            </span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    <span
                      className='d-none'
                      style={{ display: 'none' }}
                    >
                      {provided.placeholder}
                    </span>
                  </div>
                )}
              </Droppable>
            </MDBCol>
          ))}
        </DragDropContext>
      </MDBRow>
    </div>
  );
}

export default GridCards;
