import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import NextContextProvider from "./components/Context";

// css
import "./index.scss";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

ReactDOM.render(
  <BrowserRouter>
    <NextContextProvider>
      <App />
    </NextContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
