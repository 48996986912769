import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
} from "./FooterStyled";

const Footer = () => {
return (
	<Box>
        <hr style={{marginBottom: "20px"}}></hr>
	<Container>
		<Row>
		<Column>
			<Heading>About Us</Heading>
			<FooterLink href="#">Aim</FooterLink>
			<FooterLink href="#">Vision</FooterLink>
			<FooterLink href="#">Testimonials</FooterLink>
		</Column>
		<Column>
			<Heading>Services</Heading>
			<FooterLink href="#">Writing</FooterLink>
			<FooterLink href="#">Internships</FooterLink>
			<FooterLink href="#">Coding</FooterLink>
			<FooterLink href="#">Teaching</FooterLink>
		</Column>
		<Column>
			<Heading>Address</Heading>
			<FooterLink href="https://www.google.com/search?q=digital%20career%20institute%20berlin&rlz=1C5CHFA_enDE950DE950&sxsrf=ALiCzsZaulV9LS-lNdJwm1duXWeQA_XNog:1653639853954&ei=oYqQYrvSMtKJxc8PqT4&ved=2ahUKEwiC3tLAoP_3AhXUSPEDHcFVDoUQvS56BAhKEAE&uact=5&oq=digital+career+institute+berlin&gs_lcp=Cgdnd3Mtd2l6EAMyBQgAEIAEMgYIABAeEBYyAggmMgIIJjoHCAAQRxCwAzoHCAAQsAMQQzoKCAAQ5AIQsAMYAToSCC4QxwEQrwEQyAMQsAMQQxgCOgQIABBDOgsILhCABBDHARCvAUoECEEYAEoECEYYAVDCA1jjCmDqC2gBcAF4AIABiwGIAawFkgEDNi4xmAEAoAEByAESwAEB2gEGCAEQARgJ2gEGCAIQARgI&sclient=gws-wiz&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=8073633374843413325&lqi=Ch9kaWdpdGFsIGNhcmVlciBpbnN0aXR1dGUgYmVybGluSIjA-LqLrYCACFovEAAQARACGAAYARgCGAMiH2RpZ2l0YWwgY2FyZWVyIGluc3RpdHV0ZSBiZXJsaW6SARdlZHVjYXRpb25hbF9pbnN0aXR1dGlvbqoBIBABKhwiGGRpZ2l0YWwgY2FyZWVyIGluc3RpdHV0ZSgA&sa=X&rlst=f#rlfi=hd:;si:8073633374843413325,a;mv:[[52.5244636,13.4880371],[52.511077,13.4539823]]">Vulkanstraße 1, 10367 Berlin</FooterLink>
			<iframe style={{borderRadius: "5%", height:"100px"}}  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38862.74313484267!2d13.425954011792223!3d52.498661531286295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a84e9ab1163a17%3A0x700b4ec74a47a34d!2sDCI%20-%20Digital%20Career%20Institute%20gGmbH!5e0!3m2!1sen!2sde!4v1653641104302!5m2!1sen!2sde" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
		</Column>
		<Column>
			<Heading>Social Media</Heading>
			<FooterLink href="#">
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				Facebook
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-instagram">
				<span style={{ marginLeft: "10px" }}>
				Instagram
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				Twitter
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				Youtube
				</span>
			</i>
			</FooterLink>
		</Column>
		</Row>
	</Container>
	</Box>
);
};
export default Footer;
