import TextareaAutosize from '@mui/base/TextareaAutosize';
import React, { useContext } from 'react'
import { NextContext } from '../../../../Context';


const CoverLetter = () => {
  const { jobCard, setJobCard } = useContext(NextContext);

  return (
    <div className="h-100">
      <div className="d-flex flex-column h-100">
        <TextareaAutosize
          style={{ width: "100%", maxHeight: "40rem", padding: "1rem" }}
          maxRows={30}
          minRows={28}
          multiline
          label="Cover Letter"
          variant="outlined"
          type="text"
          placeholder="Add cover letter"
          value={jobCard.coverLetter}
          onChange={(e) =>
            setJobCard({ ...jobCard, coverLetter: e.target.value })
          }
        />
      </div>
    </div>
  );
};

export default CoverLetter;
