import React, { useState } from "react";
import { useLocation } from "react-router-dom";

// spring animation
import { animated, useTransition, config } from "@react-spring/web";

export default function BoxTransition({ children, open }) {
  const location = useLocation();
  const [toggle, setToggle] = useState(true);

  const transitions = useTransition(children, {
    config: config.gentle,
    exitBeforeEnter: true,
  });
  return (
    <div onClick={(e) => setToggle(!toggle)} style={{marginBottom: 60}}>
      {transitions((props, item) => (
        <animated.div style={{ textAlign: "center", ...props }}>
          {children}
        </animated.div>
      ))}
    </div>
  );
}
