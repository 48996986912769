import './loading.scss';

import React, { useContext, useEffect } from 'react';
import nextIcon from '../../img/next-icon.png';
import { NextContext } from '../Context';

import { motion, AnimatePresence } from 'framer-motion';

import Backdrop from '@mui/material/Backdrop';

export default function Loading({ open = false }) {
  const { loadingMessage, loading } = useContext(NextContext);

  return (
    // <AnimatePresence exitBeforeEnter>
    <Backdrop
      key='loading'
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
      open={open}
      component={motion.div}
      visibility='visible'
      // initial={{ opacity: 0 }}
      // exit={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // transition={{ duration: 0.05 }}
      className='loading-container'
    >
      {/* <motion.div
          key='loading'
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className={'loading-container'}
        > */}
      <motion.div className='loading-sub-container'>
        <motion.img
          src={nextIcon}
          className='logo'
          alt='loading'
        />
        <motion.h2 className='mt-5 loading font-small '>
          {loadingMessage}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </motion.h2>
      </motion.div>
      {/* </motion.div> */}
    </Backdrop>
    // </AnimatePresence>
  );
}
