import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { NextContext } from '../../../../Context';
import SingleTodo from './SingleTodo';

const UncompletedTodosList = () => {
  const { jobCard } = useContext(NextContext);
  return (
    <Row className="completedTodo">
    <Col className="m-0 p-0 ">
      <div className="tasks  ">
        {jobCard["toDoList"]?.length > 0 &&
          jobCard?.toDoList
            ?.filter((mylist) => mylist.completed === false)
            ?.map((item, idx) => (
              <SingleTodo item={item} index= {idx} />
            ))}
      </div>
    </Col>
  </Row>
  )
}

export default UncompletedTodosList