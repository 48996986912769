import { useContext } from "react";
import NoteList from "./NoteList";
import { NextContext } from "../../../../Context";
import "./notes.scss";

const App = () => {
  const {
    jobCard,
    setJobCard,
    setAllPosts,
    currentUser,
    note,
    setNote,
  } = useContext(NextContext);

  const addNote = () => {
    const createNewNote = {
      id: Date.now(),
      text: note,
    };
    if (note.trim()?.length) {
      // setAllNotes([...allNotes, createNewNote]);
      const oldData = [...jobCard["notes"]];
    oldData.push(createNewNote);
      setJobCard({ ...jobCard, notes: oldData });
      setNote("");
    }
  };

  const deleteNote = (id) => {
    const newNotes = jobCard?.notes?.filter((item) => item.id !== id);
    setJobCard({ ...jobCard, notes: newNotes });
  };
  return (
    <div className="containerNote">
      <NoteList handleAddNote={addNote} handleDeleteNote={deleteNote} />
    </div>
  );
};

export default App;
