import React, { useState, useEffect } from 'react';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';

import { Link } from 'react-router-dom';

export default function SideBarApp({
  func,
  active,
  current,
  Icon = DashboardRoundedIcon,
  title = current?.charAt(0)?.toUpperCase() + current?.substring(1),
}) {
  const iconSize = 80;
  const bgColor = 'rgb(38, 38, 38)';
  
  return (
    <div className="fs-6 dashboard-apps" onClick={func}>
      <Icon
        sx={{
          fontSize: iconSize,
          color: active  ? '#fff' : bgColor,
          backgroundColor: active ? bgColor : '',
          '&:hover': { border: `4px solid ${bgColor}` },
        }}
      />
      <p>{title}</p>
    </div>
  );
}
