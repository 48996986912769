import React from 'react';

export default function NewFeatureHighLight({
  children,
  title,
  bgcolor = '#26c281',
  ...restStyle
}) {
  // create a style that highlights the new link introduced in the navbar
  const newFeatureStyle = {
    position: 'absolute',
    height: 'fit-content',
    width: 'fit-content',
    lineHeight: 2,
    top: restStyle?.top || 'unset',
    left: restStyle.left && !restStyle.right ? restStyle.left : 'unset',
    right: !restStyle.left && restStyle.right ? restStyle.right : 'unset',
    color: 'white',
    backgroundColor: bgcolor,
    fontSize: '0.5em',
    padding: '0.2em 0.8em',
    borderRadius: '0.5em',
    ...restStyle,
  };

  const newFeatureBottomArrow = {
    content: '',
    position: 'absolute',
    left: 0,
    right: 0,
    // margin: '0 auto',
    width: 0,
    height: 0,
    borderTop: `12.5px solid ${bgcolor}`,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderRadius: '1em',
    // bottom: '-12px',
  };

  return (
    <div className='position-relative h-100'>
      <div style={newFeatureStyle}>
        {title}
        <div style={newFeatureBottomArrow}></div>
      </div>

      {children}
    </div>
  );
}
