import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NextContext } from '../Context';
//components
import Collaborators from './LandingPageComps/Collaborators/Collaborators';
import ContactUs from './LandingPageComps/ContactUs/ContactUs';
import MyCarousel from './MyCarousel';
import Footer from './LandingPageComps/Footer/Footer';
// image
import headerImage from '../../img/nextBeach22.jpg';
import ScrollToTop from './LandingPageComps/ScrollButton/ScrollButton';

//css
import './landingPage.scss';

import { motion, useIsPresent } from 'framer-motion';

import useMediaQueries from '../../utils/useMediaQueries';

export default function LandingPage() {
  const { currentUser } = useContext(NextContext);
  const { isTabletOrMobile } = useMediaQueries();

  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
    setUpdate(true);
    console.log('update');
  }, [update]);

  const isPresent = useIsPresent();

  useEffect(() => {
    isPresent && console.log("I'm present");
  }, [isPresent]);

  return (
    // <LazyMotion features={domAnimation}>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'anticipate', duration: 0.5 }}
    >
      <div className='landingPage-container '>
        <header className='position-relative vh-100'>
          <div className=''>
            <div className='main-header-container text-center'>
              <div className='d-flex flex-column align-items-center'>
                <div className='typeContainer'>
                  <div className='typewriter'>
                    <h1>Organize Your </h1>
                    <h1>Job Search</h1>
                  </div>
                </div>

                {currentUser._id ? (
                  <Link
                    className='text-decoration-none text-white rounded-links header-cta-btn tryNowBtn'
                    to='/dashboard'
                  >
                    To DashBoard
                  </Link>
                ) : (
                  <Link
                    className='text-decoration-none text-white rounded-links header-cta-btn tryNowBtn'
                    to='login'
                  >
                    Try it now!
                  </Link>
                )}
              </div>
              <motion.img
                layout
                className='col-xxl-3 col-sm-4 '
                src={headerImage}
                alt=''
                style={{
                  marginTop: '50px',
                  borderRadius: '5%',
                  width: isTabletOrMobile && '100%',
                }}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                transition={{ ease: 'anticipate', duration: 0.5 }}
              />
            </div>
          </div>
        </header>
        <ScrollToTop />
        <MyCarousel />
        <Collaborators />
        <ContactUs />
        <Footer />
      </div>
    </motion.div>
    // </LazyMotion>
  );
}
