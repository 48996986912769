import React, { useState, useEffect, useMemo } from 'react';
import { useCurrentJobsDetailsStore } from '../../stores/JobsGeneralStores';
import randomColor from 'randomcolor';
import HTMLReactParser from 'html-react-parser';
import { ChipItem, Item } from './ChipItem';
import Stack from '@mui/material/Stack';

// tagNameFixer function moved to a separate file (tagNameFixer.js)
import { tagNameFixer } from './helper/tagNameFixer';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, Divider, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelectedLocationStore } from '../../stores/detailsViewerStore';

const MAX_CHIPS_DISPLAY = 3;

// TODO: Make this component more reusable
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    sx={{
      bgcolor: 'background.paper',
      userSelect: 'none',
      ...props.sx,
    }}
    classes={{ popper: className }}
    TransitionComponent={Zoom}
    TransitionProps={{ timeout: 500 }}
    followCursor
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme?.typography?.pxToRem(15),
    border: '1px solid #dadde9',
    whiteSpace: 'pre-wrap',
    padding: theme?.spacing(2),
    boxShadow: theme?.shadows[1],
    // userSelect: 'none',
  },
}));

export default function Tag({
  classReset,
  chips,
  title,
  chipContainerClassName,
  chipClassName,
  bgcolor,
  isLocation,
  itemComponent,
  itemStyle,
  badgeStyle,
  tagContainerStyle,
  rotate,
  index,
  mode,
  commonTagProps,
  ...props
}) {
  const [randomTagColor, setRandomTagColor] = useState('');
  const [className, setClass] = useState('');
  const open = useCurrentJobsDetailsStore((state) => state.open);

  const setSelectedLocation = useSelectedLocationStore(
    (state) => state.setSelectedLocation
  );

  const selectedLocation = useSelectedLocationStore(
    (state) => state.selectedLocation
  );

  const handleSelectedLocation = (location) => {
    setSelectedLocation(location);
  };

  const chipArray = useMemo(() => {
    let chipArray = [];
    // let restChips = [];
    if (chips?.length > 0) {
      if (isLocation) {
        for (const chip in chips) {
          chipArray.push([
            chips[chip].name,
            chips[chip].count,
            chips[chip].color,
            { otherOptions: chips[chip].otherOptions },
          ]);
        }
      } else {
        for (const chip of chips) {
          if (Array.isArray(chip) && chip.length > 1) {
            for (const chipItem of chip) {
              chipArray.push(chipItem);
            }
          } else {
            chipArray.push(chip);
          }

          chipArray[chip] = tagNameFixer(chips[chip]);
        }
      }
      chipArray = chipArray.filter((chip) => chip !== undefined);
    }

    // return { chipArray, restChips }
    return chipArray;
  }, [chips, isLocation]);

  const visibleChips = useMemo(() => {
    if (mode === 'card') {
      return chipArray.slice(0, 3);
    } else if (mode === 'drawer') {
      return chipArray;
    } else {
      return []; // Default case
    }
  }, [mode, chipArray]);

  useEffect(() => {
    if (classReset) {
      setClass(`${chipContainerClassName || ''} `);
    } else {
      setClass(
        `${
          chipContainerClassName || ''
        } position-absolute align-items-baseline user-select-none height-fit-content`
      );
    }
  }, [chipContainerClassName, chips, classReset]);

  useEffect(() => {
    if (isLocation && chipArray[0]?.length > 0) {
      setSelectedLocation(chipArray[0][3]?.otherOptions);
    }
  }, [isLocation, chipArray, setSelectedLocation]);

  useEffect(() => {
    setRandomTagColor(
      randomColor({
        luminosity: 'dark',
        hue: 'random',
      })
    );
  }, []);

  return (
    <React.Fragment>
      {(chips && (
        <Stack
          direction='row'
          // spacing={isLocation ? 2 : 1}
          className={className + ' tag-stack-container'}
          gap={isLocation ? 2 : 1}
          flexWrap='wrap'
          sx={{
            bottom: 0,
            left: 0,
            // width: '100%',
            ...tagContainerStyle,
          }}
        >
          {visibleChips?.map((chip) => (
            <ChipItem
              key={title + chip + '-chip'}
              chip={chip}
              isLocation={isLocation}
              bgcolor={bgcolor}
              itemComponent={itemComponent}
              itemStyle={itemStyle}
              badgeStyle={badgeStyle}
              randomTagColor={randomTagColor}
              chipClassName={chipClassName}
              tagNameFixer={tagNameFixer}
              title={title}
              index={index}
              chipArray={chipArray}
              setSelectedLocation={setSelectedLocation}
              handleSelectedLocation={handleSelectedLocation}
            />
          ))}

          {/* THe rest of the tags !not the location */}
          {mode === 'card' && chipArray.length > 3 && (
            <HtmlTooltip
              title={
                <Box
                  sx={{
                    userSelect: 'none',
                    p: 1,
                  }}
                >
                  {chipArray.slice(3)?.map((chip, index) => (
                    <React.Fragment key={chip + index}>
                      {index !== 0 && <Divider style={{ margin: '8px 0' }} />}
                      <span>{chip}</span>
                    </React.Fragment>
                  ))}
                </Box>
              }
              placement='right'
              sx={{
                '&.tooltip': {
                  width: 'auto',
                  maxWidth: 'none',
                },
              }}
            >
              <Item
                key={title + 'more'}
                sx={{
                  ...itemStyle,
                  userSelect: 'none',
                }}
                bgcolor={bgcolor}
              >
                {/* {`+${hiddenChipsCount}`} */}
                {`+${chipArray.length - 3}`}
              </Item>
            </HtmlTooltip>
          )}
        </Stack>
      )) ||
        null}
    </React.Fragment>
  );
}
