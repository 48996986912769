import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import './contactUs.scss';
import Snackbar from '@mui/material/Snackbar';

export default function ContactUs() {
  /* -------Colors-------------- */

  const theme = createTheme({
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#0971f1',
        darker: '#053e85',
      },
      neutral: {
        main: '#f5f5f5',
        contrastText: '#fff',
      },
    },
  });

  /* ------------TOAST------------ */

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /* --------------------------------- */

  const [data, setData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = async () => {
    if (!data.email || !data.name || !data.message) return;

    const response = await axios.post('/message/send', data);
    if (response?.data.success) {
      handleClick();
    }

    setData({ name: '', email: '', message: '' });
  };
  const buttons = <div onClick={handleSubmit}>Send Message </div>;

  return (
    <div className="contactUsContainer" id="contact">
      <ThemeProvider theme={theme}>
        <h1
          style={{
            color: 'black',
            marginTop: '200px',
            marginBottom: '50px',
            fontSize: '50px',
          }}>
          Contact Us
        </h1>
        <div
          id="contact"
          style={{
            width: '1000px',
            height: '400px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            backgroundColor: '#f5f5f59d',
            color: 'whitesmoke',
            borderRadius: '15px',
            textAlign: 'center',
            padding: '20px',
            marginTop: '30px',
          }}>
          <TextField
            style={{ textAlign: 'center' }}
            color="neutral"
            id="standard-helperText"
            label="Your name"
            variant="standard"
            type="text"
            // placeholder="Enter your name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <TextField
            color="neutral"
            id="standard-helperText"
            label="Your email"
            required
            variant="standard"
            type="Email"
            // placeholder="Enter your email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <TextField
            color="neutral"
            multiline
            required
            id="standard-helperText"
            label="Your message"
            variant="standard"
            type="Message"
            // placeholder="Enter your message"
            value={data.message}
            onChange={(e) => setData({ ...data, message: e.target.value })}
          />

          <Button
            style={{ color: 'black', marginTop: '10px' }}
            onClick={handleSubmit}>
            {' '}
            {buttons}
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={open}
              onClose={handleClose}
              message="Thank you for your message!"
            />
          </Button>
        </div>{' '}
      </ThemeProvider>{' '}
    </div>
  );
}
