import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Grid } from '@mui/material';
import JobCard2 from './JobCard2';
import Tag from './DetailsViewer/Tag';

import { getChipsForJobDetails } from './DetailsViewer/helper/jobDetailsHelperFunctions';

const GRID_ITEM_PREFIX = 'grid-item-';

const JobsGrid = ({
  currentJobs,
  gridResponsive,
  columns,
  open,
  currentIndex,
  setCurrentIndex,
  setCurrentJobDetails,
  setOpen,
  isMobile,
}) => {
  const paddingValue =
    gridResponsive === 'xs' || (!open && isMobile)
      ? '0 0 40px 0'
      : '0 40px 40px 0';

  const transitionValues = {
    type: 'tween',
    stiffness: 100,
    duration: 0.5,
    opacity: { ease: 'linear' },
    ease: [0.17, 0.67, 0.83, 0.67],
  };

  const styleValues = {
    position: 'relative',
  };

  const commonTagProps = {
    mode: 'card',
    tagContainerStyle: { width: 'fit-content' },
  };

  // const getChipsForLanguages = (jobDetails) => {
  //   if (jobDetails?.sprachkenntnisse) {
  //     const { uuid, ...sprachkenntnisseWithoutUUID } = jobDetails.sprachkenntnisse;
  //     if (Object.keys(sprachkenntnisseWithoutUUID)?.length >= 2) {
  //       return Object.values(sprachkenntnisseWithoutUUID);
  //     }
  //     return [Object.values(sprachkenntnisseWithoutUUID)[0]];
  //   }
  //   return [];
  // };

  // const renderTag = (condition, props) => {
  //   if (!condition) return null;

  //   const combinedProps = { ...props, ...commonTagProps };

  //   return <Tag {...combinedProps} />;
  // };

  const renderTag = (condition, props) =>
    condition ? (
      <Tag
        {...commonTagProps}
        {...props}
      />
    ) : null;

  return (
    <Grid
      container
      alignItems='stretch'
      className='cards-grid-container'
    >
      <AnimatePresence exitBeforeEnter>
        {currentJobs.map(({ job, jobDetails, image }, idx) => (
          <Grid
            component={motion.div}
            key={GRID_ITEM_PREFIX + idx}
            id={GRID_ITEM_PREFIX + idx}
            className='grid-item'
            item
            xs={gridResponsive === 'xs' && columns}
            sm={gridResponsive === 'sm' && columns}
            md={gridResponsive === 'md' ? columns : columns}
            lg={gridResponsive === 'lg' && open ? columns : columns}
            xl={gridResponsive === 'xl' && open ? columns : columns}
            padding={paddingValue}
            initial={{ opacity: 0, scale: 0, y: -100 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            whileTap={{ scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ...transitionValues,
              delay: idx * 0.08,
              y: {
                type: 'spring',
                stiffness: 100,
                delay: idx > 6 ? 0.02 : 0.08,
              },
            }}
            sx={styleValues}
          >
            <JobCard2
              initialJobDetails={{ jobDetails, index: idx }}
              job={job}
              companyLogo={image}
              setCurrentJobDetails={setCurrentJobDetails}
              open={open}
              setOpen={setOpen}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            >
              {/* {jobDetails?.sprachkenntnisse && (
                <Tag
                  title='language'
                  mode='card'
                  chips={
                    (Object.keys(jobDetails?.sprachkenntnisse)?.length >= 2 &&
                      Object.values(jobDetails?.sprachkenntnisse)) ||
                    Object.values(jobDetails?.sprachkenntnisse)[0]
                  }
                  chipContainerClassName='card-top-chips'
                />
              )} */}
              {/*
              {jobDetails?.istGoogleJobsRelevant && (
                <Tag
                  mode='card'
                  chips={['Google related Job']}
                  chipContainerClassName='position-absolute top-0 start-8 user-select-none height-fit-content'
                  classReset
                  tagContainerStyle={{
                    width: 'fit-content',
                  }}
                />
              )}
              {jobDetails?.sonstigesAusbildung && (
                <Tag
                  mode='card'
                  chips={['Training']}
                  chipContainerClassName='position-absolute user-select-none height-fit-content'
                  classReset
                  tagContainerStyle={{
                    width: 'fit-content',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    transform: 'translate(-60%, 12rem) rotate(-90deg)',
                  }}
                  bgcolor='#fca866'
                />
              )} */}

              {renderTag(jobDetails?.sprachkenntnisse, {
                title: 'language',
                chips: getChipsForJobDetails(jobDetails, 'sprachkenntnisse'),
                chipContainerClassName: 'card-top-chips',
              })}
              {renderTag(jobDetails?.istGoogleJobsRelevant, {
                chips: ['Google related Job'],
                chipContainerClassName:
                  'position-absolute top-0 start-8 user-select-none height-fit-content',
                classReset: true,
              })}
              {renderTag(jobDetails?.sonstigesAusbildung, {
                chips: ['Training'],
                chipContainerClassName:
                  'position-absolute user-select-none height-fit-content',
                tagContainerStyle: {
                  top: '0',
                  bottom: '0',
                  left: '0',
                  transform: 'translate(-60%, 12rem) rotate(-90deg)',
                },
                bgcolor: '#fca866',
                classReset: true,
              })}
            </JobCard2>
          </Grid>
        ))}
      </AnimatePresence>
    </Grid>
  );
};

export default JobsGrid;
