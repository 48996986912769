import React from 'react';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDetailsViewerStyleVariationsStore } from 'src/components/Jobs/stores/detailsViewerStyleVariationsStore';

const DividerStyled = styled(Divider)(({ theme, ...res }) => ({
  margin: theme.spacing(2, 0),
  color: res.color || 'primary.main',
  width: '100%',
  borderColor: 'primary.main',
  // borderTopLeftRadius: '10px',
  borderRadius: '10px',
  p: '0.5rem',
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
}));

// const DividerStyled = styled(({ topBoxShadow, theme, ...rest }) => (
//   <Divider
//     {...rest}
//     // style={{ boxShadow: topBoxShadow }} // Apply the custom topBoxShadow as a style here
//   />
// ))(({ theme, ...rest }) => ({
//   margin: theme.spacing(2, 0),
//   color: rest.color || 'primary.main',
//   width: '100%',
//   borderColor: 'primary.main',
//   boxShadow: 3,
//   // borderTopLeftRadius: '10px',
//   borderRadius: '10px',
//   p: '0.5rem',
//   borderBottomRightRadius: 0,
//   borderBottomLeftRadius: 0,
// }));

export default function DividerWithTitle({
  dividerColor,
  dividerClassName,
  typographyColor,
  typographyClassName,
  typographyVariant,
  title,
  icon,
}) {
  const topBoxShadow = useDetailsViewerStyleVariationsStore(
    (state) => state.topBoxShadow
  );

  return (
    <DividerStyled
      className={dividerClassName}
      color={dividerColor}
    >
      {title && (
        <Typography
          sx={{
            fontSize: { xs: '1rem', md: '1.2rem', lg: '1.5rem' },
            color: typographyColor,
            fontWeight: 'bold',
          }}
          variant={typographyVariant}
          className={typographyClassName}
        >
          {title}
        </Typography>
      )}
    </DividerStyled>
  );
}
