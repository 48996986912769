// css
import './dashboard.scss';

import React, { useContext, useState, useEffect } from 'react';
import { NextContext } from '../Context';
import Loading from '../Loading/Loading';
import Board from './DashboardComponents/Board/Board';

import SearchBar from '../Dashboard/SearchBar/SearchBar';
import AddCardModal from '../Card/CardComponents/AddCardModal';
import SideNavbar from './DashboardComponents/SideNavbar/SideNavbar';

import Map from './MapComponent/Maps';
import BingMap from './MapComponent/BingMap';
import MapboxMap from './MapComponent/MapboxMap';

import Contacts from '../Card/CardComponents/AddJobCard/Contacts/Contacts';
import Todos from './DashboardComponents/PersonalTodos/PersonalTodos';
import Metrics from './Metrics/Metric';

import { motion } from 'framer-motion';
// import Jobs from './DashboardComponents/Jobs/Jobs';
import Jobs from '../Jobs/Jobs';

export default function Dashboard() {
  const {
    currentUser,
    setCurrentCardData,
    allPosts,
    jobCard,
    setJobCard,
    defaultJobCard,
    setAllPosts,
  } = useContext(NextContext);

  const [current, setCurrent] = useState('board');


  // !NOTE CHeck later why this was here!
  // if (!currentUser._id || !allPosts?.length) {
  //   console.log('we are here')
  //   return <Loading />;
  // }


  return (
    <motion.div
      key="dashboard"
      className="dashboard d-flex h-100 position-absolute top-0 start-0 w-100">
      <SideNavbar setCurrent={setCurrent} />
      <div className='w-100 px-2 pt-5 overflow-hidden'>
        <motion.div
          layout
          key='searchBar'
          className='d-flex justify-content-end me-lg-5'
          initial={{ opacity: 0, y: -200 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -200 }}
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          <SearchBar />
        </motion.div>
        <AddCardModal />
        <motion.div
          className='content-container d-flex flex-column overflow-auto'
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          {current === 'board' ? (
            <Board
              setCurrentCardData={setCurrentCardData}
              allPosts={allPosts}
              currentUser={currentUser}
              jobCard={jobCard}
              setJobCard={setJobCard}
              defaultJobCard={defaultJobCard}
              setAllPosts={setAllPosts}
            />
          ) : null}
          {current === 'todo' ? <Todos /> : null}

          {current === 'contacts' ? <Contacts /> : null}
          {/* {current === "map" ? <Map /> : null} */}
          {current === 'map' ? <MapboxMap allPosts={allPosts} /> : null}
          {/* {current === 'map' ? <BingMap /> : null} */}
          {current === 'metrics' ? (
            <div>
              <Metrics />
            </div>
          ) : null}
          {current === 'jobs' ? <Jobs /> : null}
        </motion.div>
      </div>
    </motion.div>
  );
}
