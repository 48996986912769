import React, { useEffect } from 'react';
import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Color from 'color';
import HTMLReactParser from 'html-react-parser';

export const Item = styled(Paper)(({ theme, bgcolor, anchor }) => ({
  fontsize: '0.1rem',
  backgroundColor: bgcolor ? bgcolor : '#fc6666',
  padding: '5px 8px',
  borderRadius: '5px',
  textAlign: 'center',
  color: '#fff',
  fontWeight: 'bold',
  fontFamily: 'inherit',
  lineHeight: '1',
  textTransform: 'uppercase',
}));

export function ChipItem({
  chip,
  chipArray,
  isLocation,
  bgcolor,
  itemComponent,
  itemStyle,
  badgeStyle,
  handleSelectedLocation,
  randomTagColor,
  chipClassName,
  tagNameFixer,
  title,
}) {
  return (
    <Badge
      className='tag-badge'
      key={title + chip[0]}
      badgeContent={isLocation && Number(chip[1]) > 1 ? chip[1] : null}
      // color='primary'
      sx={{
        // whiteSpace: chip.length > 15 ? 'pre-wrap' : 'nowrap',
        // whiteSpace: chip.includes('\n') ? 'pre-wrap' : 'nowrap',
        '& .MuiBadge-badge': {
          // right: -3,
          // top: 13,
          bgcolor: '#000',
          color: '#fff',
          fontWeight: 'bold',
          // border: `2px solid ${
          //   chipArray.length > 1 && isLocation ? chip[2] : '#fff'
          // }`,
          padding: '0 4px',
        },
        ...badgeStyle,
      }}
    >
      <Item
        key={title + chip + '-item'}
        component={itemComponent || 'span'}
        // className='card-chip-span'
        bgcolor={
          bgcolor || (isLocation && (isLocation ? chip[2] : randomTagColor))
        }
        // anchor={anchor}
        className={`card-chip-span ${chipClassName || ''}`}
        onClick={() => {
          handleSelectedLocation(chip[3]?.otherOptions);
        }}
        sx={{
          cursor: isLocation ? 'pointer' : 'default',
          width: '100%',
          whiteSpace: 'pre-wrap',
          '&:hover': {
            backgroundColor: isLocation
              ? Color(chip[2] || 'hotpink')
                  .lighten(0.5)
                  .hex()
              : bgcolor,
          },
          ...itemStyle,
        }}
      >
        {HTMLReactParser(
          tagNameFixer(Array.isArray(chip) ? chip[0] : chip).toString()
        )}
      </Item>
    </Badge>
  );
}
