import axios from 'axios';
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { NextContext } from '../Context';
import { useNavigate } from 'react-router';
import Loading from '../Loading/Loading';

export default function GLogin() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, setCurrentUser, setLoading, currentUser } =
    useContext(NextContext);

  const saveUserToLocal = (user) => {
    const stringUser = JSON.stringify(user);
    localStorage.setItem('authorizedUser', stringUser);
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      const response = await axios.get('/users/glogin/' + id);

      if (response.data.success) {
        setCurrentUser(response.data.user);

        setTimeout(() => {
          setLoading(false);
          navigate(`/dashboard`);
        }, 1000);
      }
    };

    setTimeout(() => {
      getData();
    }, 1000);
  });

  // if (loading) {
  //   return <Loading />;
  // }

  // return <div>Hello from glogin at client with id {id}</div>;
  return <Loading />;
}
