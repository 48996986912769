import ColorThief from 'colorthief';

// TODO should be in the AdminPanel
export async function getImageColors(image) {
  const colorThief = new ColorThief();
  const img = new Image();
  img.src = image;
  await new Promise((resolve) => {
    img.addEventListener('load', () => {
      resolve();
    });
  });
  const color = colorThief.getColor(img);
  return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}

// TODO should be in the AdminPanel
export function linearRadialColorGenerator(color, direction, to,lr) {
  switch (lr) {
    case 'l':
      return `linear-gradient(${direction}, ${color} 0%, ${color} ${to}%, rgb(255, 255, 255) ${to}%, rgb(255, 255, 255) 100%)`;
    case 'r':
      // return `radial-gradient(circle, ${color} 0%, ${color} 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%)`;
      // make it radial from the center starting with faded white color and ending with the color
      return `radial-gradient(circle, rgb(255, 255, 255) 0%, ${color} 80% ,${color} 100%)`;
    default:
      return `linear-gradient(${direction}, ${color} 0%, ${color} 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 100%)`;
  }
}
