import React, { useContext, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { NextContext } from '../../../../Context';

const PreparedTodos = () => {
    const { todo, setTodo, jobCard, setJobCard } = useContext(NextContext);
    const [preparedTodo] = useState([
        "Follow Up",
        "Phone Interview",
        "Send Availability",
        "Reach Out",
        "Prep Cover Letter",
        "On Site Interview",
        "Offer Received",
        "Send Thank You",
        "Email",
        "Meeting",
        "Phone Call",
        "Assignment",
      ]);
    
  return (
    <Row className="ps-1 pt-3 w-100">
    <Col className="d-flex flex-wrap w-100 ">
      {preparedTodo.map((item, index) => {
        return (
          <span
            key={index}
            onClick={() => setTodo(item)}
            className="preparedTodo rounded "
            style={{
              color: "navy",
              border: "1px solid gray",
              padding: "4px",
              margin: "4px",
            }}
          >
            {item}
          </span>
        );
      })}
    </Col>
  </Row>
  )
}

export default PreparedTodos