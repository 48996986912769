import React, { useContext } from "react";
import moment from "moment";
import { MdDeleteForever } from "react-icons/md";
import { NextContext } from "../../../../Context";

const SingleTodo = ({item, idx}) => {
    const { todo, setTodo, jobCard, setJobCard } = useContext(NextContext);

     //DELETE TODO
  const deleteTodo = (id) => {
    const updatedTodos = [...jobCard?.toDoList].filter(
      (todo1) => todo1.id !== id
    );
    setJobCard({ ...jobCard, toDoList: updatedTodos });
  };

  //TOGGLE TODO CHECKBOX
  const toggleComplete = (id) => {
    const updatedTodos = jobCard?.toDoList?.map((todo2) => {
      if (todo2.id === id) {
        todo2.completed = !todo2.completed;
      }
      return todo2;
    });
    setJobCard({ ...jobCard, toDoList: updatedTodos });
  };

  return (
    <div
      className=" todolabel d-flex align-items-center "
      htmlFor={item.id}
      key={idx}
    >
      <input
        type="checkbox"
        className="checktodo ps-2   d-flex align-items-center justify-content-center"
        onChange={() => toggleComplete(item.id)}
        checked={item.completed}
      />
      <h6 className="text-start text-dark pt-2  w-100 d-flex align-items-center justify-content-start">
        {item.text}
      </h6>

      <h6 className="todoDate w-50 text-end  text-dark pe-2 pt-1 border-dark  border-end">
        {moment(item?.date).fromNow()}
      </h6>
      <div className="pe-2">
        <MdDeleteForever
          className="deleteiIcon fs-4 "
          onClick={() => deleteTodo(item.id)}
        />
      </div>
    </div>
  );
};

export default SingleTodo;
