import React from 'react';
import './about.scss';
import MyVideo from './media/nextvideo1.mp4';
import MyVideo2 from './media/nextvideo2.mp4';
import ScrollToTop from '../LandingPage/LandingPageComps/ScrollButton/ScrollButton';
import LogoJourney from './media/nextlogo1.jpg';
import DashJourney from './media/nextdash2.jpg';
import Button from '@mui/material/Button';
import Footer from '../LandingPage/LandingPageComps/Footer/Footer';
import { ImQuotesLeft } from 'react-icons/im';

import { motion } from 'framer-motion';

export default function About() {
  const frontendTools = [
    'React',
    'JavaScript',
    'Axios',
    'Google Maps',
    '3D React',
    'Framer Motion',
    'Bootstrap',
    'MDB',
    'MUI',
    'React Beautiful DND',
    'React Tilt',
    'React Nice Avatar',
    'React Icons',
  ];

  const backendTools = [
    'NodeJS',
    'Mongoose',
    'Express',
    'Passport',
    'Cloudinary',
    'Dotenv',
    'Bcrypt',
    'Cookie Parser',
    'Nodemailer',
    'Multer',
    'JsonWebToken',
  ];

  return (
    <motion.div
      className="aboutContainer"
      initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'anticipate', duration: 0.7 }}>
      {/* <Navigation /> */}
      <div>
        <h1>About NextIN</h1>
        <div style={{ display: 'flex' }}>
          <p style={{ width: '80%' }}>
            <strong>
              {' '}
              <i>Perfect job search journey</i>{' '}
            </strong>{' '}
            takes time and can be very overwhelming. Getting lost within
            applications, cover letters, missing recruiters contacts or
            forgetting to follow up on some tasks should not be the case but
            happens to the best of us. Those minor mistakes can cause a lot of
            stress and even your dream job. We do not want that to happen to you
            so we created{' '}
            <strong>
              {' '}
              <i>NextIN</i> <i> - job application task manager app. </i>{' '}
            </strong>{' '}
            App where all information you need is one click away.
          </p>
        </div>{' '}
        <a
          href="https://github.com/semuona/NextIn"
          target="_blank"
          rel="noreferrer">
          {' '}
          <Button
            variant="contained"
            style={{
              marginTop: '1%',
              width: '200px',
              height: '60px',
              backgroundColor: 'black',
              borderRadius: '15px',
            }}>
            See the Github
          </Button>
        </a>
      </div>
      <div className="videoContainer">
        <video
          muted
          autoPlay={'autoplay'}
          preload="auto"
          loop>
          <source
            src={MyVideo}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>

        <video
          muted
          autoPlay={'autoplay'}
          preload="auto"
          loop>
          <source
            src={MyVideo2}
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div className="journeyContainer">
        <div className="textContainer">
          <h2 style={{ textAlign: 'center' }}>
            Application Developing Journey
          </h2>
          <div className="blockquote1">
            <ImQuotesLeft style={{ fontSize: '40px' }} />
            <h1>
              Give a man a program, frustrate him for a day. Teach a man to
              program, frustrate him for a lifetime :)
            </h1>
            <h4>&mdash;Muhammad Waseem</h4>
          </div>
          <p style={{ marginTop: '15%', textAlign: 'center' }}>
            We are four Full Stack Web developers:{' '}
            <strong>
              <i>
                <a
                  href="https://www.linkedin.com/in/semuona/"
                  target="_blank"
                  rel="noreferrer">
                  Simona
                </a>
                ,{' '}
                <a
                  href="https://www.linkedin.com/in/abdulkarim-alarmanazi/"
                  target="_blank"
                  rel="noreferrer">
                  Kareem
                </a>
                ,
                <a
                  href="https://www.linkedin.com/in/akkanesra/"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  Esra{' '}
                </a>{' '}
                and
                <a
                  href="https://github.com/iduzel"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  Ismail
                </a>{' '}
                .
              </i>{' '}
            </strong>{' '}
            <br />
            All of us - graduates of
            <a
              href="https://digitalcareerinstitute.org/"
              target="_blank"
              rel="noreferrer">
              {' '}
              <i> Digital Career Institute </i>
            </a>
            and
            <strong> NextIN</strong> is our final project. <br /> Our goal was
            not just to implement gained knowledge but also take on new
            challenges, bring the application to the next level for developers
            and users experiences combined.
          </p>
        </div>

        <div className="journeyImgContainer">
          <img
            src={LogoJourney}
            alt="logo journey"
          />
          <img
            src={DashJourney}
            alt="dashboard journey"
          />
          <span
            style={{
              border: 'none',
              boxShadow: 'none',
              background: 'none',
              padding: '15px',
              fontStyle: 'italic',
            }}>
            "Logo and Dashboard Sketches"
          </span>
        </div>
      </div>

      <div>
        <h3>Frontend Technologies:</h3>{' '}
        <div className="toolContainer">
          {frontendTools.map((tool, index) => (
            <div
              key={tool + index}
              className="fTool">
              {tool}
            </div>
          ))}{' '}
        </div>
      </div>
      <div>
        <h3>Backend Technologies:</h3>{' '}
        <div className="toolContainer">
          {backendTools.map((tool, index) => (
            <div
              key={(tool, index)}
              className="bTool">
              {tool}
            </div>
          ))}{' '}
        </div>
      </div>

      <ScrollToTop />
      <Footer />
    </motion.div>
  );
}
