import React from "react";
import { Carousel } from "3d-react-carousal";
import background from "../../img/neactSearch.jpg";

// images
import cardsScreenShot from "../../img/dashboardCards.png";
import dashboardMap from "../../img/dashboardMap.png";
import addContactCard from "../../img/addContactCard.png";
import dashboardMetrix from "../../img/dashboardMetrix.png";
import addNotes from "../../img/addNotes.png";

export default class MyCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const height = 450;
    let slides = [
      <img
        src={cardsScreenShot}
        alt="1"
        style={{
          width: "100%",
          height,
          borderRadius: "10px",
          objectFit: "cover",
        }}
      />,
      <img
        src={dashboardMap}
        alt="2"
        style={{
          width: "100%",
          height,
          borderRadius: "10px",
          objectFit: "cover",
        }}
      />,
      <img
        src={addContactCard}
        alt="3"
        style={{
          width: "100%",
          height,
          borderRadius: "10px",
          objectFit: "cover",
        }}
      />,
      <img
        src={dashboardMetrix}
        alt="4"
        style={{
          width: "100%",
          height,
          borderRadius: "10px",
          objectFit: "fill",
        }}
      />,
      <img
        src={addNotes}
        alt="5"
        style={{
          width: "100%",
          height,
          borderRadius: "10px",
          objectFit: "cover",
          objectPosition: "top",
        }}
      />,
    ];
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
          margin: "10% 0 10% 5%",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${background})`,
            backgroundPosition: "right center",
            backgroundSize: "cover",
            width: "100%",
            height: "700px",
            borderRadius: "5%",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div style={{ width: "100%", textAlign: "center", marginLeft: "10%" }}>
          <div style={{ width: "80%", marginBottom: 20 }}>
            <h1
              style={{
                fontFamily: "Just Another Hand, cursive",
                fontSize: "7em",
                letterSpacing: ".15em",
                overflow: "hidden",
                fontSize: "100px",
                color: "#1A2E35",
              }}
            >
              Discover <br></br> our App!
            </h1>
          </div>
          <div style={{ width: "80%", height }}>
            <Carousel slides={slides} autoplay={true} interval={2000} arrows={false} />
          </div>
        </div>
      </div>
    );
  }
}
