// css
import './sideNavbar.scss';
import Button from '@mui/material/Button';
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { NextContext } from '../../../Context';
import { useNavigate } from 'react-router-dom';
import SidebarApp from './SideBarComponents/SideBarApp';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import WorkIcon from '@mui/icons-material/Work';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function SideNavbar({ setCurrent }) {
  const { currentUser, brand, setCurrentUser } = useContext(NextContext);
  // const [active, setActive] = useState('board');
  const [active, setActive] = useState('board');

  const navigate = useNavigate();

  // useEffect(() => setCurrent(active), [active]);
  // const activeName = useMemo(() => active.name, [active]);

  const handleActive = (name) => {
    setActive(name);
    setCurrent(name);
  };

  const handleLogOut = () => {
    if (Object.keys(currentUser).length) {
      setCurrentUser({});
      localStorage.removeItem('authorizedUser');
      navigate('/');
    }
  };


  return (
    <motion.div
      className='side-nav-container position-relative col'
      layout
      style={{ textAlign: 'center' }}
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -200 }}
      transition={{ ease: 'anticipate', duration: 0.5 }}
    >
      <div className='side-nav-body d-flex flex-column justify-content-evenly align-items-center h-100'>
        <Link
          to='/'
          className=''
        >
          <img
            src={brand}
            alt=''
            style={{ width: 64 }}
          />
        </Link>
        {/* Apps Bar */}
        <div className={`side-nav-apps font-weight-bold `}>
          {/* Dashboard button */}

          <SidebarApp
            func={(e) => handleActive('board')}
            current={'board'}
            active={active === 'board'}
          />

          {/* Activities */}
          <SidebarApp
            func={(e) => handleActive('todo')}
            Icon={ListRoundedIcon}
            current={'todo'}
            active={active === 'todo'}
          />

          {/* Contacts */}

          <SidebarApp
            func={(e) => handleActive('contacts')}
            Icon={ConnectWithoutContactRoundedIcon}
            current={'contacts'}
            active={active === 'contacts'}
          />

          {/* Map */}

          <SidebarApp
            func={(e) => handleActive('map')}
            Icon={MapRoundedIcon}
            current={'map'}
            active={active === 'map'}
          />

          {/* Metrics */}

          <SidebarApp
            func={(e) => handleActive('metrics')}
            Icon={EqualizerRoundedIcon}
            current={'metrics'}
            active={active === 'metrics'}
          />

          <SidebarApp
            func={(e) => handleActive('jobs')}
            Icon={WorkIcon}
            current={'jobs'}
            active={active === 'jobs'}
          />
        </div>
        <div
          className='side-nav-profile rounded'
          // style={{ width: "fit-content" }}
        >
          <p style={{ fontWeight: 'bold' }}>
            Hi {currentUser?.firstName || 'there'}!
          </p>
          <Link to='/profile'>
            <img
              src={
                currentUser?.image ||
                `https://api.dicebear.com/7.x/adventurer/svg?seed=${currentUser.firstName}.svg`
              }
              alt='profile'
              // className="rounded"
              style={{
                width: '80%',
                height: '80%',
                objectFit: 'cover',
                objectPosition: 'center',
                backgroundColor: '#fff',
                borderRadius: '10%',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                borderBottomRightRadius: '50px',
              }}
            />
          </Link>
          <Button onClick={handleLogOut}>Log Out</Button>
        </div>
      </div>
    </motion.div>
  );
}
