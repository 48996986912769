import React, { useContext, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { NextContext } from '../Context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './EditProfile.scss';

const EditProfile = () => {
  const { currentUser, setCurrentUser } = useContext(NextContext);
  const [pt, setPt] = useState(currentUser?.personalToDos);

  const navigate = useNavigate();
  const [flag, setFlag] = useState(false);

  const [data, setData] = useState({});
  const [addressData, setAddressData] = useState({
    street: '',
    houseNumber: '',
    zipcode: '',
    city: '',
    country: '',
  });
  const [fileUrl, setFileUrl] = useState('');
  const [blobFile, setBlobFile] = useState(null);
  const [userAge] = useState();

  //alert

  const [showAlert, setShowAlert] = useState(false);
  const handleShowAlert = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      navigate('/dashboard');
    }, 1000);
  };
  // IMAGE AND AGE
  useEffect(() => {
    setData({
      ...data,
      ...currentUser,
      age: userAge,
    });
    setFileUrl(currentUser?.image);
    setAddressData(currentUser?.address);
  }, []);

  delete data.personalToDos;

  // DATA
  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // HANDLE ADDRESS DATA
  const handleAddress = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  // IMAGE
  const handleImageChange = (e) => {
    const file = e.currentTarget.files[0];

    setFileUrl(URL.createObjectURL(file)); // create a url from file user chose and update the state

    setBlobFile(e.currentTarget.files[0]);
  };

  // SAVE
  const handleSaveProfile = async () => {
    const formdata = new FormData();

    delete currentUser?.personalToDos;
    formdata.set('_id', currentUser._id);

    Object.entries(data).forEach((item) => formdata.set(item[0], item[1]));
    formdata.append('address[street]', addressData?.street);
    formdata.append('address[houseNumber]', addressData?.houseNumber);
    formdata.append('address[zipcode]', addressData?.zipcode);
    formdata.append('address[city]', addressData?.city);
    formdata.append('address[country]', addressData?.country);
    formdata.append('[personalToDos]', pt);

    if (blobFile) formdata.set('image', blobFile, 'profile_image');

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const response = await axios.patch('users/profile', formdata, config);

    if (response.data.success) setCurrentUser({ ...response.data.user });

    setFlag(!flag);
    //setFileUrl("");
    handleShowAlert();
    setData({});
    setAddressData({});
  };

  //CLOSE
  const handleClose = () => {
    navigate('/dashboard');
  };

  return (
    <div
      className='editProfile'
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='profile container shadow-lg'>
        <Alert
          className='w-100'
          show={showAlert}
          variant='info'
        >
          Profile successfully updated!
        </Alert>
        <h1 style={{ padding: '3%', color: '#ff3547' }}> Edit Profile</h1>
        <Row className=''>
          <Col className=''>
            {' '}
            <div className='main-div'>
              <div className='form'>
                {/* FIRSTNAME */}
                <input
                  name='firstName'
                  id='firstname'
                  className='form__input'
                  onChange={(e) => handleDataChange(e)}
                  value={data?.firstName}
                />
                <label
                  className='form__label'
                  htmlFor='firstname'
                >
                  First Name
                </label>
              </div>

              <div className='form'>
                {/* LASTNAME */}
                <input
                  name='lastName'
                  id='lastname'
                  className='form__input'
                  onChange={(e) => handleDataChange(e)}
                  value={data?.lastName}
                />
                <label
                  className='form__label'
                  htmlFor='lastname'
                >
                  Last Name
                </label>
              </div>

              <div className='form'>
                {/* EMAIL */}
                <input
                  name='email'
                  id='email'
                  className='form__input'
                  readOnly
                  value={data?.email}
                  onChange={(e) => handleDataChange(e)}
                />
                <label
                  className='form__label'
                  htmlFor='email'
                >
                  Email{' '}
                </label>
              </div>

              <div className='form'>
                {/* PHONE */}
                <input
                  name='phone'
                  id='phone'
                  className=' form__input'
                  onChange={(e) => handleDataChange(e)}
                  value={data?.phone}
                />
                <label
                  htmlFor='phone'
                  className='form__label'
                >
                  Phone
                </label>
              </div>

              <div className='form'>
                {/* BIRTHDATE */}
                <input
                  name='birthDate'
                  type='date'
                  id='birthDate'
                  className=' form__input'
                  onChange={(e) => handleDataChange(e)}
                  value={data?.birthDate}
                />
                <label
                  htmlFor='birthDate'
                  className='form__label'
                >
                  Birthdate
                </label>
              </div>
              {/* ADDRESS */}
              <div className='form'>
                <input
                  name='street'
                  id='street'
                  className=' form__input'
                  onChange={(e) => handleAddress(e)}
                  value={addressData?.street}
                />

                <label
                  className='form__label'
                  htmlFor='street'
                >
                  Street
                </label>
              </div>

              <div className='form'>
                <input
                  name='houseNumber'
                  id='houseNumber'
                  className=' form__input'
                  onChange={(e) => handleAddress(e)}
                  value={addressData?.houseNumber}
                />

                <label
                  className='form__label'
                  htmlFor='houseNumber'
                >
                  House Number
                </label>
              </div>

              <div className='form'>
                <input
                  name='zipcode'
                  id='zipcode'
                  className=' form__input'
                  onChange={(e) => handleAddress(e)}
                  value={addressData?.zipcode}
                />

                <label
                  className='form__label'
                  htmlFor='zipcode'
                >
                  Zipcode
                </label>
              </div>

              <div className='form'>
                <input
                  name='city'
                  id='city'
                  className=' form__input'
                  onChange={(e) => handleAddress(e)}
                  value={addressData?.city}
                />

                <label
                  className='form__label'
                  htmlFor='city'
                >
                  City
                </label>
              </div>

              <div className='form'>
                <input
                  name='country'
                  id='country'
                  className=' form__input'
                  onChange={(e) => handleAddress(e)}
                  value={addressData?.country}
                />

                <label
                  className='form__label'
                  htmlFor='country'
                >
                  Country
                </label>
              </div>
            </div>
          </Col>
          {
            <Col
              className='text-center '
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {' '}
              <div>
                <img
                  className='rounded'
                  src={
                    fileUrl ||
                    currentUser?.image ||
                    `https://api.dicebear.com/7.x/adventurer/svg?seed=${currentUser.firstName}.svg`
                  }
                  alt=''
                  style={{
                    height: '300px',
                    width: '300px',
                    objectFit: 'cover',
                  }}
                />{' '}
              </div>
              <div
                style={{}}
                className='mx-auto'
              >
                <label
                  className='btn btn-info w-50 mt-5 '
                  htmlFor='file'
                  style={{ cursor: 'pointer' }}
                >
                  Upload image
                </label>
                <input
                  accept='image/*'
                  onChange={handleImageChange}
                  id='file'
                  type='file'
                  style={{ visibility: 'hidden' }}
                />
              </div>
            </Col>
          }
        </Row>

        <Row className='buttonRow mb-2'>
          <Col className='buttonCol'>
            <button
              className='closeButton btn  '
              onClick={handleClose}
            >
              Close
            </button>
          </Col>
          <Col className='buttonCol'>
            <button
              className='saveButton btn  '
              onClick={handleSaveProfile}
            >
              Save profile
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditProfile;
