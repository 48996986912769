import React, { useState } from 'react';
import _ from 'lodash';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoComplete() {
  const [fetchedResult, setFetchedResult] = useState({
    results: [],
    selectedResult: null,
  });

  const [selectedResult, setSelectedResult] = useState(null);

  const [locationName, setLocationName] = useState({ titles: [], keys: [] });

  const devUrl = 'http://dev.virtualearth.net/REST/v1/Locations';
  const apiKey =
    'Alix2kuC4rjJawt6I31aPFwAI7i4w30FwWZUNWg1U51F9KADLQLj56Q0H6zNqt-E';
  const handleSearchChange = async (event, { value }) => {
    try {
      // create the response object with the devUrl and add also pass the api key and the query with headers
      // const response = await fetch(devUrl + '=' + value + `&key=${apiKey}`);
      const response = await fetch(devUrl + '?q=' + value + `&key=${apiKey}`);

      const data = await response.json();
      const resultsRaw = data.resourceSets[0].resources;
      const results = resultsRaw.map((result, idx) => ({
        id: idx + 1,
        title: result.name,
        url: result.url,
      }));

      const titles = results.map((result) => result.title);
      const keys = results.map((result) => result.id);

      setFetchedResult({ results });

      setLocationName({ titles, keys });
    } catch (error) {
      console.error(`Error fetching search ${value}`, error);
    }
  };

  const handleResultSelect = (e) => setSelectedResult(e);
  // setFetchedResult({ selectedResult: result });

  console.log('%c locationName', 'color: red; font-size: 20px', selectedResult);

  return (
    <div>
      {/* <Search
        onSearchChange={_.debounce(handleSearchChange, 1000, {
          leading: true,
        })}
        results={state.results}
        onResultSelect={handleResultSelect}
      /> */}
      {/* <TextField
        id="standard"
        label="Location"
        variant="standard"
        type="text"
        placeholder="Search location"
        // value={jobCard.link}
        // onChange={(e) => setJobCard({ ...jobCard, link: e.target.value })}
        // onChange={_.debounce(handleSearchChange, 1000, {
        //   leading: true,
        // })}

        onChange={e => handleSearchChange(e, { value: e.target.value })}
      />
      <SelectedResult result={state.selectedResult} key={state.id} /> */}
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={locationName.titles || []}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            // onChange={(e) => handleSearchChange(e, { value: e.target.value })}
            // value={selectedResult}
            onChange={_.debounce(
              (e) => handleSearchChange(e, { value: e.target.value }),
              1000,
              {
                leading: true,
              }
            )}
          />
        )}
        // create a render option function to display the results in the dropdown
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              // onClick={() => handleResultSelect(option)}
              key={locationName.keys + option}>
              {option}
            </li>
          );
        }}
        // onSelectCapture={(e) => handleResultSelect(e)}
      />
      
    </div>
  );
}

const SelectedResult = ({ result }) => (
  <a
    style={{ color: 'red' }}
    href={result && result.url}>
    {result && result.title}
  </a>
);
