import React, { useMemo } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useImageBackgroundColorsSetterStore } from '../Jobs/stores/imageBackgroundColorsSetterStore';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, stringavatarstyle) {
  const splitName = () => {
    if (!name) return '';
    const parts = name.split(' ');
    if (parts.length >= 2) {
      return `${parts[0][0]}${parts[1][0]}`;
    }
    return name.charAt(0);
  };

  // console.log('%c Name', 'color: red; font-size: 2rem;', name);
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...stringavatarstyle,
    },
    children: splitName(name),
  };
}

export default function BackgroundLetterAvatars({
  name,
  stringavatarstyle,
  variant = 'rounded',
  ...rest
}) {
  const avatarProps = useMemo(
    () => stringAvatar(name, stringavatarstyle),
    [name, stringavatarstyle]
  );

  const { setLetterAvatarColors, cardClicked } =
    useImageBackgroundColorsSetterStore((state) => ({
      setLetterAvatarColors: state.setLetterAvatarColors,
      cardClicked: state.cardClicked,
    }));

  React.useEffect(() => {
    setLetterAvatarColors(avatarProps.sx.bgcolor);
  }, [avatarProps.sx.bgcolor, setLetterAvatarColors, cardClicked]);

  return (
    <Stack
      direction='row'
      justifyContent='center'
      alignItems='center'
      boxShadow={'0px -2px 11px 0px rgb(0 0 0 / 38%)'}
      {...rest}
    >
      <Avatar
        {...avatarProps}
        variant={variant}
      />
    </Stack>
  );
}
