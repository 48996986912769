import React from 'react';
import { NextContext } from '../../../Context';
import { useContext, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import ColorBox from './ColorPicker';
import MenuItem from '@mui/material/MenuItem';
import BrushIcon from '@mui/icons-material/Brush';
import Tooltip from '@mui/material/Tooltip';

import AutoComplete from '../../../Dashboard/MapComponent/BingMapComponents/AutoComplete';
// import { inputLabelClasses } from "@mui/material/InputLabel";
// import Autocomplete from "react-google-autocomplete";
// import CompanyAutoComplete from "./companiesAPI/Companies";

import './addCard.scss';
// import {
//   GoogleMap,
//   LoadScript,
//   StandaloneSearchBox,
// } from "@react-google-maps/api";
// import GooglePlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from "react-google-places-autocomplete";

export default function AddCardInfo() {
  //-------States---------
  const { jobCard, setJobCard, requiredData, favorite, setFavorite } =
    useContext(NextContext);

  const [isVisibleColorPicker, setIsVisibleColorPicker] = useState('hidden');

  /* -------Status-------------- */

  const statusType = [
    {
      value: 'wishlist',
      label: 'Wishlist',
    },
    {
      value: 'applied',
      label: 'Applied',
    },
    {
      value: 'interview',
      label: 'Interview',
    },
    {
      value: 'offer',
      label: 'Offer',
    },
    {
      value: 'rejected',
      label: 'Rejected',
    },
  ];

  //-------Favorite--------------
  const handleFavorite = () => {
    setFavorite(!favorite);
    setJobCard({ ...jobCard, favorites: !favorite });
  };

  //-------getting color from ColorPicker---------
  const getColor = (color) => {
    setJobCard({ ...jobCard, color: color });
  };

  useEffect(() => {
    jobCard?.color?.length > 1 && setIsVisibleColorPicker('hidden');
    const styles = {
      floatingLabelFocusStyle: {
        color: 'red',
      },
    };
  }, [jobCard?.color]);

  //-------Autocomplete MAPS---------

  const [value, setValue] = useState(jobCard?.location?.city || '');

  // useEffect(() => {
  //   const getCoords = async () => {
  //     const address = await geocodeByAddress(
  //       value?.value?.description || "Berlin, Germany"
  //     );
  //     const { lat, lng } = await getLatLng(address[0]);

  //     setJobCard({ ...jobCard, location: { city: value?.label, lat, lng } });
  //   };
  //   getCoords();
  // }, [value]);

  console.log("🚀 ~ file: AddCardInfo.jsx:98 ~ AddCardInfo ~ jobCard:", jobCard)

  return (
    <>
      <div style={{ marginTop: '80px' }}>
        <div className="jobCardContainer">
          <div>
            {jobCard?.favorites ? (
              <div className="favoriteContainer">
                <StarIcon
                  className="favorite"
                  onClick={handleFavorite}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ) : (
              <div className="favoriteContainer" style={{ cursor: 'pointer' }}>
                <Tooltip title="Add to favorite" arrow placement="top">
                  <StarOutlineIcon
                    className="notFavorite"
                    onClick={handleFavorite}
                  />
                </Tooltip>
              </div>
            )}
            <Tooltip title="Choose card color" arrow placement="top">
              <div
                onClick={() => setIsVisibleColorPicker('visible')}
                className="colorPickerCircle"
                style={{
                  backgroundColor: jobCard.color,
                }}>
                <BrushIcon style={{ fontSize: ' 2rem' }} />
              </div>
            </Tooltip>

            <ColorBox getColor={getColor} isVisible={isVisibleColorPicker} />
          </div>
        </div>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off">
          {/* <div> */}
          <TextField
            style={
              requiredData && jobCard?.company === ''
                ? { borderBottom: '1px solid red' }
                : null
            }
            required
            id="standard-required"
            label="Company Name"
            variant="standard"
            type="text"
            placeholder="Enter Company Name"
            value={jobCard.company}
            onChange={(e) =>
              setJobCard({ ...jobCard, company: e.target.value })
            }
          />
          <TextField
            style={
              requiredData && jobCard?.title === ''
                ? { borderBottom: '1px solid red' }
                : null
            }
            id="standard-required"
            label="Job Title"
            required
            variant="standard"
            type="text"
            placeholder="Enter your Job Title"
            value={jobCard?.title}
            onChange={(e) => setJobCard({ ...jobCard, title: e.target.value })}
          />
          <TextField
            id="standard"
            label="Link"
            variant="standard"
            type="text"
            placeholder="Enter Job URL"
            value={jobCard.link}
            onChange={(e) => setJobCard({ ...jobCard, link: e.target.value })}
          />
          {/* <div className="google-autoComplete MuiFormControl-root MuiTextField-root css-1u3bzj6-MuiFormControl-root-MuiTextField-root" style={{display: 'inline-block'}}>
            <GooglePlacesAutocomplete
              selectProps={{
                placeholder: value || "Enter Location",
                isFocused: false,
                styles: {
                  container: (provided) => ({
                    ...provided,
                    border: 0,
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: 0,
                    boxShadow: "none",
                    borderBottom: "1px solid black",
                    borderRadius: "0",
                    color: "black",
                    marginTop: 10,
                    "&:hover": {
                      borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      cursor: "text",
                    },
                  }),
                },
                input: (provided) => ({}),

                value,
                onChange: setValue,
              }}
            />
          </div> */}
          
          <AutoComplete />

          <TextField
            id="standard"
            label="Salary"
            variant="standard"
            type="text"
            placeholder="Enter salary"
            value={jobCard.salary}
            onChange={(e) => setJobCard({ ...jobCard, salary: e.target.value })}
          />

          <TextField
            id="standard-select-currency"
            select
            label="Status"
            value={jobCard.status}
            onChange={(e) => setJobCard({ ...jobCard, status: e.target.value })}
            helperText="Current status of your application"
            variant="standard">
            {statusType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{
              width: '97%',
            }}
            multiline
            minRows={4}
            label="Description"
            variant="outlined"
            type="text"
            placeholder="Enter job description"
            value={jobCard.description}
            onChange={(e) =>
              setJobCard({ ...jobCard, description: e.target.value })
            }
          />
        </Box>
        <div
          style={{
            width: '36%',
            marginTop: '30px',
            textAlign: 'center',
          }}></div>
      </div>
    </>
  );
}
