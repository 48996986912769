import { create } from 'zustand';
import axios from 'axios';
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';

export const useJobsStore = create((set, get) => ({
  loading: true,
  message: 'Fetching Jobs...',
  title: 'Search Jobs',
  fetchedJobs: [],
  currentJobs: [],
  error: '',
  isJobsInitialized: false,
  itemsPerPage: 12,
  page: 0,
  totalJobs: 0,
  maxResults: 0,
  jobsLength: 0,
  notFound: false,

  fetchJobsHandler: async (query) => {
    set({ message: 'Fetching Jobs...', loading: true });
    // console.log('%c It was rendered here!', 'color: #bada55', query);
    try {
      const result = await fetchJobs(query);

      const { title, loading, message, maxResults, notFound, status } = result;

      if (status === 200 && result?.jobs?.length > 0) {
        if (maxResults === 0) {
          return set((state) => ({
            currentJobs: [],
            isJobsInitialized: true,
            title,
            loading,
            message,
            maxResults,
          }));
        }
        const jobsWithImageURL = result.jobs.map((jobData) => ({
          ...jobData,
          image: {
            ...jobData.image,
            imageURL: createImageURL(
              jobData?.image?.imageData,
              jobData?.image?.imageType
            ),
          },
        }));

        set({
          fetchedJobs: jobsWithImageURL,
          currentJobs: sliceJobs(
            jobsWithImageURL,
            get().page,
            get().itemsPerPage
          ),
          isJobsInitialized: true,
        });

        // set((state) => ({
        //   currentJobs: sliceJobs(result.jobs, state.page, state.itemsPerPage),
        //   isJobsInitialized: true,
        // }));

        set((state) => ({
          notFound,
          title,
          loading,
          message,
          maxResults,
          jobsLength: result?.jobs?.length,
          totalJobs: result?.jobs?.length,
          error: null,
          // totalJobs: result.jobs.length + Math.ceil(result.jobs.length / state.itemsPerPage),
        }));
      } else {
        console.log('=============RESULTS FROM ERROR============== ', result);
        set({
          error: '2.Something went wrong, please try again later',
          title: 'Something went wrong!',
          jobsLength: result?.jobs?.length,
          totalJobs: result?.jobs?.length,
          currentJobs: [],
          isJobsInitialized: true,
          loading: false,
          message: 'No jobs found',
          maxResults: 0,
        });
      }

      if (status === 500) {
        set({
          error: '1.Something went wrong, please try again later',
          title: 'Something went wrong!',
        });
      }

      set({ loading: false });
    } catch (error) {
      console.log(error);
      set({
        error: '3.Something went wrong, please try again later',
        title: 'There is an error!',
      });
      set({ loading: false });
    }
  },

  // Actions and Mutations
  setLoading: (loading) => set({ loading }),
  setLoadingMessage: (loadingMessage) => set({ loadingMessage }),
  setFetchedJobs: (fetchedJobs) => set({ fetchedJobs }),
  setCurrentJobs: (currentJobs) => set({ currentJobs }),
  setError: (error) => set({ error }),
  setIsJobsInitialized: (isJobsInitialized) => set({ isJobsInitialized }),

  setTotalJobs: () => {
    set((state) => ({ totalJobs: state.jobsLength }));
    // console.log('%c It was rendered here!', 'color: #bada55', get().jobsLength);
  },

  setItemsPerPage: (itemsPerPage) => {
    set((state) => ({ itemsPerPage }));
    set((state) => ({
      currentJobs: sliceJobs(state.fetchedJobs, state.page, itemsPerPage),
    }));
    set((state) => ({
      totalJobs: state.jobsLength + Math.ceil(state.jobsLength / itemsPerPage),
    }));
  },
  setPage: (page) => {
    set((state) => ({ page }));
    set((state) => ({
      currentJobs: sliceJobs(state.fetchedJobs, page, state.itemsPerPage),
    }));
  },
}));

const fetchJobs = async (query) => {
  if (!query) {
    query = {
      what: '',
      where: 'Berlin',
      radius: '25',
      offerType: '',
      page: '1',
      pav: 'false',
      size: '100',
      workingHours: [],
    };
  }

  let loadingMessage = '';
  try {
    const workingHoursJoined =
      query?.workingHours?.length > 1
        ? query?.workingHours.join(';')
        : query?.workingHours[0] || [];

    const response = await axios.get('/browse-jobs/arbeitsAgentur/', {
      params: { ...query, workingHours: workingHoursJoined },
    });
    // const { jobs, maxResults, title, loading, error, success } = response?.data;
    // return { jobs, maxResults, title, loading, loadingMessage, status: response.status, error, success };
    return { ...response?.data, status: response.status, loadingMessage };
  } catch (error) {
    console.log(error);
    loadingMessage = 'Something went wrong, please try again later';
    return { loadingMessage, status: 500 };
  }
};

const sliceJobs = (jobs, currentPage, itemsPerPage) => {
  const fixCurrentPage = currentPage + 1;
  const prevPage = fixCurrentPage - 1;
  const firstIndex = prevPage * itemsPerPage;
  const lastIndex = fixCurrentPage * itemsPerPage;
  return jobs?.slice(firstIndex, lastIndex);
};

// Helper function to create imageURL from imageData and imageType
const createImageURL = (imageData, imageType) => {
  if (!imageData || !imageType) return null;

  const buffer = Buffer.from(imageData, 'base64');
  const blob = new Blob([buffer], { type: imageType });
  return URL.createObjectURL(blob);
};
