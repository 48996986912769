import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect, useState, useRef } from 'react';
import Routers from './Routers';
import { useLocation } from 'react-router-dom';
import Navbar from './components/LandingPage/LandingPageComps/Navbar';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { ToastContainer } from 'react-toastify';
import { NextContext } from './components/Context';
import Loading from './components/Loading/Loading';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';

import useScrollTrigger from '@mui/material/useScrollTrigger';

function App() {
  const routesContainerRef = useRef(null);
  const { loading } = useContext(NextContext);
  const { pathname } = useLocation();
  const currentLocation = pathname?.substring(1)?.toLowerCase();
  const [showNavbar, setShowNavbar] = useState(false);

  const trigger = useScrollTrigger({
    target: routesContainerRef.current || undefined,
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    if (
      currentLocation !== 'dashboard' &&
      currentLocation !== 'register' &&
      currentLocation !== 'login'
    ) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  }, [currentLocation]);

  if (loading) {
    return <Loading loading={loading} />;
  }

  return (
    <motion.div className='App overflow-hidden'>
      <AnimatePresence exitBeforeEnter>
        {showNavbar ? (
          <Paper
            component={motion.div}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ ease: 'anticipate', duration: 0.7 }}
            elevation={trigger ? 1 : 0}
            sx={{
              padding: '2% 6%',
              backgroundColor: 'inherit',
              // margin: '2% 6% 0',
              zIndex: 1000,
              position: 'relative',
            }}
            // className='w-100 navbar-parent'
          >
            <Navbar />
          </Paper>
        ) : null}
      </AnimatePresence>
      <ToastContainer />

      <div
        className='routers-container overflow-auto h-100 position-relative'
        ref={routesContainerRef}
      >
        <Routers />
      </div>
    </motion.div>
  );
}

export default App;
