import React, { useState, useContext, useEffect } from "react";
import InputSection from "./ContactsComponents/InputSection";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Container from "./ContactsComponents/Container";
// icon
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import Color from "color"; // v3.2.1

// Avatar Config
import { genConfig } from "react-nice-avatar";

// Context
import { NextContext } from "../../../../Context";
import randomColor from "randomcolor";
import { motion } from "framer-motion";

export default function Add({ isShow, style }) {
  const { jobCard, setJobCard } =
    useContext(NextContext);

  const color = randomColor();

  const config = {
    // sex: gender,
    bgColor: `linear-gradient(to top, ${color}, ${Color(color)
      .rotate(24)
      .lighten(0.8)}
        )`,
  };

  const myConfig = genConfig(config);
  const [avatar, setAvatar] = useState(myConfig);

  const [contactData, setContactData] = useState({
    name: "",
    jobTitle: "",
    linkedin: "",
    companyWebsite: "",
    contactEmail: "",
    phone: "",
    backgroundColor: color,
    avatar: avatar,
  });

  useEffect(() => {
    setAvatar(myConfig);
  }, [jobCard]);

  const resetInput = (e) => {
    setContactData({
      name: "",
      jobTitle: "",
      linkedin: "",
      companyWebsite: "",
      contactEmail: "",
      phone: "",
      avatar: avatar,
    });
  };

  const handleName = (value) => {
    setContactData({ ...contactData, name: value });
  };
  const handleJobTitle = (value) => {
    setContactData({ ...contactData, jobTitle: value });
  };
  const handleLinkedin = (value) => {
    setContactData({ ...contactData, linkedin: value });
  };
  const handleCompanyWebsite = (value) => {
    setContactData({ ...contactData, companyWebsite: value });
  };

  const handleContactEmail = (value) => {
    setContactData({ ...contactData, contactEmail: value });
  };

  const handlePhone = (value) => {
    setContactData({ ...contactData, phone: value });
  };

  const handleAddContact = async (e) => {
    if (
      !contactData.name &&
      !contactData.linkedin &&
      !contactData.companyWebsite &&
      !contactData.contactEmail &&
      !contactData.phone &&
      !contactData.jobTitle
    )
      return;

    setJobCard({ ...jobCard, contacts: [...jobCard["contacts"], contactData] });

    resetInput();
  };

  return (
    <motion.div style={{ height: "100%" }}>
      <Container style={{ height: "100%" }}>
        <InputSection
          label="Full Name"
          icon={<PersonRoundedIcon />}
          func={handleName}
          value={contactData.name}
        />
        <InputSection
          label="Phone Number"
          icon={<LocalPhoneRoundedIcon />}
          func={handlePhone}
          value={contactData.phone}
        />
        <InputSection
          label="Email Address"
          icon={<AlternateEmailRoundedIcon />}
          func={handleContactEmail}
          value={contactData.contactEmail}
        />
        <InputSection
          label="Job Title"
          icon={<WorkRoundedIcon />}
          func={handleJobTitle}
          value={contactData.jobTitle}
        />
        <InputSection
          label="LinkedIn Profile URL"
          icon={<LinkedInIcon />}
          func={handleLinkedin}
          value={contactData.linkedin}
        />
        <InputSection
          label="Company Website"
          icon={<LanguageRoundedIcon />}
          func={handleCompanyWebsite}
          value={contactData.companyWebsite}
        />
        <div style={{ width: "fit-content", }} className="my-2">
          <Button
            className="addContact-button"
            variant="contained"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.54)",
              ml: 7,
              "&:hover": {
                backgroundColor: "#000",
              },
            }}
            onClick={handleAddContact}
          >
            Add contact
          </Button>
        </div>
      </Container>
      <hr />
    </motion.div>
  );
}
