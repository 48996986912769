import React from "react";
import { useContext, useState, useEffect } from "react";
import axios from "axios";
import "./search.scss";
import { NextContext } from "../../Context";

export default function SearchBar() {
  const { currentUser, setAllPosts } = useContext(NextContext);

  const [searchText, setSearchText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.get(
      `/posts/list/${currentUser?._id}/search/${searchText}`
    );
    if (response?.data) {
      setAllPosts(response.data);
    }
  }
  useEffect(() => {
    const handleReset = async () => {
      const response = await axios.get(`/posts/list/${currentUser?._id}`)
        setAllPosts(response?.data?.posts);  
    }
    handleReset()
  }, [currentUser?._id, setAllPosts])

  return (
    <div className="searchbar" style={{ fontSize: "20px" }}>
      <form className="formSearch" onSubmit={handleSubmit} method="GET">
        <label htmlFor="search">Search</label>
        <input
          className="inputSearch"
          type="search"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button className="buttonSearch" type="submit"> Go!</button>
      </form>
    </div>
  );
}
