import React from 'react';

export default function NoJobsFound({ title, timer }) {
  return (
    <div>
      {title}
      <h1>retry in {timer}</h1>
      <div className='image-container'>
        <img
          src='https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif'
          alt='no jobs found'
        />
      </div>
    </div>
  );
}
