import { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { NextContext } from '../Context';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';

// react icon
import { FcGoogle } from 'react-icons/fc';

import { toast } from 'react-toastify';
import { toastConfig } from '../../utils/toasts';

export default function LoginForm({ className = '' }) {
  const [data, setData] = useState({
    email: '',
    pass: '',
  });

  const [error, setError] = useState({
    email: '',
    pass: '',
    message: '',
  });

  const { setCurrentUser, setLoading } = useContext(NextContext);

  const navigate = useNavigate();

  //main
  const handleLogin = async () => {
    toast.dismiss();
    // if (!data.pass) return;
    if (!data.email || !data.pass) {
      setError({ email: '', pass: '' }); // Clear previous errors

      if (!data.email?.length) {
        setError((prevError) => ({ ...prevError, email: 'Email is required' }));
      }
      if (!data.pass?.length) {
        setError((prevError) => ({
          ...prevError,
          pass: 'Password is required',
        }));
      }
      return;
    }
    console.log('%c data', 'color: red; font-size: 20px', data);

    const response = await axios.post('/users/login', data);

    if (response.data.success) {
      setCurrentUser(response.data.user);
      toast.success('Logged in successfully', { ...toastConfig, icon: '👍' });
      navigate('/dashboard');
    }
    if (!response.data.success) {
      toast.error(response.data.message, {
        ...toastConfig,
        icon: '🚫',
      });
      setError({ error: response.data.message });
      console.log('%c response', 'color: red; font-size: 20px', response.data);
    }
  };

  useEffect(() => {
    console.log('%c error', 'color: red; font-size: 20px', error);
  }, [error]);

  return (
    <motion.div
      className={`login-form bg-light text-center h-100 ${className}`}>
      <p className="display-1">Login</p>
      <FormControl
        component="form"
        sx={{
          width: '70%',
          '& > :not(style)': { m: 1 },
          textAlign: 'center',
        }}
        autoComplete="off">
        <TextField
          id="email"
          label="Email "
          variant="standard"
          placeholder="sample@nextin.com"
          margin="normal"
          onChange={(e) => setData({ ...data, email: e.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
        />
        <TextField
          id="password-login"
          label="Password "
          type="password"
          variant="standard"
          margin="normal"
          onChange={(e) => setData({ ...data, pass: e.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleLogin();
            }
          }}
        />

        <FormControlLabel
          value="end"
          control={<Checkbox />}
          label="Remember me"
        />
        {/* {error && <p className="text-danger">{error}</p>} */}
        {/* <AnimatePresence>
          {
            error.email && (
              // error.map((err, idx) => (
              <motion.p
                // key={idx}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
                className="text-danger">
                {error.email}
              </motion.p>
            )
            // ))
          }
          {
            error.pass && (
              // error.map((err, idx) => (
              <motion.p
                // key={idx}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2, delay: 0.1 }}
                className="text-danger">
                {error.pass}
              </motion.p>
            )
            // ))
          }
          {error.message && (
            // error.map((err, idx) => (
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2, delay: 0.2 }}
              className="text-danger">
              {error.message}
            </motion.p>
          )}
        </AnimatePresence> */}
        <AnimatePresence>
          {Object.keys(error).map((field, index) => (
            <motion.p
              key={index}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2, delay: index * 0.1 }}
              className="text-danger">
              {error[field]}
            </motion.p>
          ))}
        </AnimatePresence>
        <br />
        <Button
          variant="contained"
          onClick={(e) => {
            handleLogin();
          }}
          sx={{
            borderRadius: '5px',
            fontSize: '1.4em',
            backgroundColor: '#28170d',
            '&:hover': { backgroundColor: '#150d08' },
          }}
          className="button">
          Login
        </Button>
        <Link
          to="/forgotpass"
          className="mt-3">
          Forgot Password?
        </Link>

        <Button
          sx={{
            width: 'fit-content',
            border: '2px solid #9e3134',
            color: '#9e3134',
            fontWeight: 'bold',
            padding: '10px 30px',
            '&:hover': { color: '#fff', backgroundColor: '#9e3134' },
          }}
          className="login-google align-self-center"
          href="users/google"
          onClick={(e) => setLoading(true)}
          startIcon={<FcGoogle />}>
          Log in with Google
        </Button>
        <Divider />
        <p>
          Not a member? <Link to="/register">Register</Link>
        </p>
      </FormControl>
    </motion.div>
  );
}
