// tagNameFixer.js
export const tagNameFixer = (tagName, isLocation) => {
  if (Array.isArray(tagName) && isLocation) {
    return tagName[0];
  }
  if (typeof tagName !== 'string') {
    return tagName;
  }
  tagName = tagName.replace(/_/g, ', ');
  tagName = tagName.replace(/\n/g, '<br /><hr />');
  return tagName;
};
