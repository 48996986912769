import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import OpenInFullRoundedIcon from '@mui/icons-material/OpenInFullRounded';

import { NextContext } from '../../../../../Context';

import { Tooltip } from '@mui/material';

import StarIcon from '@mui/icons-material/Star';

import Color from 'color';
import moment from 'moment';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ post, index, postID, ...rest }) {
  const { setCurrentCardData, setJobCard } = useContext(NextContext);

  const { handleDeleteCard } = rest;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleCardClick = () => {
    setCurrentCardData({ post: post, show: true });
    setJobCard(post);
  };

  const lettersSplitter = (name) => {
    const firstLetter = name[0];
    let secondLetter = name?.split(' ')[1] || '';
    return firstLetter + (secondLetter[0] || name[1]);
  };

  return (
    <Card
      sx={{
        // maxWidth: 345,
        mb: 2,
        borderRadius: '1rem',
        // background: gradientColor(post?.color, 5),
        backgroundColor: Color(post?.color)
          .lighten(0.2)
          .darken(0.2)
          .fade(0.2)
          .toString(),
        // backgroundColor: post?.color,
        color: 'white',
        '& .MuiCardHeader-avatar': {
          m: 0,
        },
      }}
      onDoubleClick={handleCardClick}
    >
      <CardHeader
        className='m-0'
        avatar={
          <Avatar
            sx={{
              bgcolor: Color(post?.color).darken(0.2).toString(),
              // for the small screen
              '@media (max-width: 1600px)': {
                height: '2.5rem',
                width: '2.5rem',
                position: 'absolute',
                top: '-1.25rem',
                left: 0,
                right: 0,
                margin: 'auto',
              },
            }}
            aria-label='company-title'
          >
            {lettersSplitter(post?.company)}
          </Avatar>
        }
        action={
          <IconButton
            aria-label='expand'
            onClick={() => {
              setCurrentCardData({ post: post, show: true });
              setJobCard(post);
            }}
          >
            <OpenInFullRoundedIcon
              sx={{
                color: Color(post?.color)?.isDark()
                  ? Color(post?.color).lighten(0.9).toString()
                  : Color(post?.color).darken(0.5).toString(),
              }}
            />
          </IconButton>
        }
        title={
          <Tooltip
            title={post?.company}
            arrow
            placement='top'
          >
            <p className='mt-2 mb-0'>
              {post?.company.length > 15
                ? post?.company?.slice(0, 15) + '...'
                : post?.company}
              <a
                className='linkToJob'
                href={post?.link}
                target='_blank'
                rel='noreferrer'
              >
                <DoubleArrowOutlinedIcon className='linkArrow' />
              </a>
            </p>
          </Tooltip>
          // <p className='mt-2 mb-0'>
          //   {post?.company}
          //   </p>
        }
        // subheader={
        //   Date(post?.date).toString().slice(0, 15) +
        //   ' ' +
        //   Date(post?.date).toString().slice(16, 21)
        // }
        subheader={moment(post?.date).fromNow()}
      />

      <CardContent>
        <Typography
          variant='body2'
          color='text.secondary'
        >
          {post?.title}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          sx={{
            // visibility: post?.favorites ? 'visible' : 'hidden',
            display: post?.favorites ? 'inherit' : 'none',
          }}
          aria-label='add to favorites'
        >
          <StarIcon />
        </IconButton>
        <a
          className='linkToJob'
          href={post?.link}
          target='_blank'
          rel='noreferrer'
        >
          <IconButton aria-label='open in new tab'>
            <OpenInNewRoundedIcon className='OpenInNewRoundedIcon' />
          </IconButton>
        </a>
        <ExpandMore
          aria-label='delete card'
          onClick={handleDeleteCard}
        >
          <DeleteIcon className='deleteIcon' />
        </ExpandMore>
      </CardActions>
    </Card>
  );
}
