import { create } from 'zustand';
import Color from 'color';

// export const useImageBackgroundColorsSetterStore = create((set, get) => ({
//   colorPaletteStyle: {},
//   colorPalette: {
//     darkestColor: '',
//     lightestColor: '',
//   },

//   getDarkestColor: (colors) => {
//     const getDarkestColor = colors?.filter((c) => Color(c).isDark())[0];

//     return getDarkestColor;
//   },

//   getLightestColor: (colors) => {
//     return colors.reduce((prev, curr) => {
//       return Color(prev).isLight() ? prev : curr;
//     });
//   },

//   getContrastColorWithOpacity: (color, opacity) => {
//     return Color(color).isDark()
//       ? `rgba(255, 255, 255, ${opacity})`
//       : `rgba(0, 0, 0, ${opacity})`;
//   },

//   setColors: (colors) => {
//     if (colors.length) {
//       const darkestColor = get().getDarkestColor(colors);
//       const lightestColor = get().getLightestColor(colors);
//       return set({
//         colorPalette: { darkestColor, lightestColor },
//       });
//     }

//     return set({
//       colorPalette: [],
//     });
//   },
// }));

export const useImageBackgroundColorsSetterStore = create((set, get) => ({
  cardClicked: false,

  colorPaletteStyle: {},
  colorPalette: {
    darkestColor: '',
    lightestColor: '',
  },

  avatarColors: {
    darkestColor: '',
    lightestColor: '',
  },

  toggleCardClick: () => set((state) => ({ cardClicked: !state.cardClicked })),

  getDarkestColor: (colors) => {
    return colors.reduce((darkest, color) => {
      return Color(color).isDark() &&
        Color(color).luminosity() < Color(darkest).luminosity()
        ? color
        : darkest;
    }, colors[0]);
  },

  // getLightestColor: (colors) => {
  //   return colors.reduce((lightest, color) => {
  //     return Color(color).isLight() &&
  //       Color(color).luminosity() > Color(lightest).luminosity()
  //       ? color
  //       : lightest;
  //   }, colors[0]);
  // },

  getLightestColor: (colors) => {
    return colors.reduce((lightest, color) => {
      return Color(color).isLight() &&
        (!lightest || Color(color).luminosity() > Color(lightest).luminosity())
        ? color
        : lightest;
    }, null);
  },

  getContrastColorWithOpacity: (color, opacity) => {
    return Color(color).isDark()
      ? `rgba(255, 255, 255, ${opacity})`
      : `rgba(0, 0, 0, ${opacity})`;
  },

  setColors: (colors, isImageColor = true) => {
    if (colors.length && isImageColor) {
      const darkestColor = get().getDarkestColor(colors);
      const lightestColor = get().getLightestColor(colors) || '#fff';
      return set({
        colorPalette: { darkestColor, lightestColor },
      });
    }
    return set({
      colorPalette: { darkestColor: '', lightestColor: '' },
    });
  },

  setLetterAvatarColors: (color) => {
    if (color) {
      return set({
        avatarColors: {
          darkestColor: color,
          lightestColor: Color.rgb(color).lighten(0.8).hex(),
        },
      });
    }
  },
}));
