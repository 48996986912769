import React from "react";
import Box from "@mui/material/Box";

export default function Container({ children, style }) {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        textAlign: "center",
        ...style
      }}
      noValidate
      autoComplete="off"
    >
      {children}
    </Box>
  );
}
