import './board.scss';
import React, { useEffect } from 'react';

import GridCards from './BoardComponents/GridCards';

export default function Board({
  setCurrentCardData,
  currentUser,
  allPosts,
  jobCard,
  setJobCard,
  setAllPosts,
  defaultJobCard,
}) {
  return (
    <div className='d-flex justify-self-center my-auto overflow-auto'>
      {/* <div className='w-100 h-100'> */}
        <div className='w-100'>
          <GridCards
            setCurrentCardData={setCurrentCardData}
            allPosts={allPosts}
            currentUser={currentUser}
            jobCard={jobCard}
            setJobCard={setJobCard}
            setAllPosts={setAllPosts}
            defaultJobCard={defaultJobCard}
          />
        </div>
      {/* </div> */}
    </div>
  );
}
