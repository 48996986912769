import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Section from './Section';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDetailsViewerStyleVariationsStore } from 'src/components/Jobs/stores/detailsViewerStyleVariationsStore';

export default function CustomAccordion({
  title,
  contents,
  children,
  className,
  id,
  customizer,
  expand,
  ...res
}) {
  const [expanded, setExpanded] = useState(false);

  const topBoxShadow = useDetailsViewerStyleVariationsStore(
    (state) => state.topBoxShadow
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (expand) {
      setExpanded(id);
    } else {
      setExpanded(false);
    }
  }, [expand, id]);

  return (
    <Accordion
      expanded={expanded === id}
      onChange={handleChange(id)}
      sx={{
        border: 'none',
        boxShadow: 'none',
        p: 0,
        ...res.sx,
      }}
    >
      <AccordionSummary
        aria-controls={`${id}bh-content`}
        id={`${id}bh-header`}
        className='accordion-summary'
        expandIcon={
          <ExpandMoreIcon
            sx={{
              fontSize: '2.5rem',
            }}
          />
        }
        sx={{
          boxShadow: topBoxShadow,
          borderRadius: '10px',
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,

          '& .MuiAccordionSummary-content': {
            justifyContent: 'center',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '1rem', md: '1.2rem', lg: '1.5rem' },
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          px: 0,
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {customizer ? customizer : null}
        <Section
          sx={{
            boxShadow: 1,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          {children}
        </Section>
      </AccordionDetails>
    </Accordion>
  );
}
