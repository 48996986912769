import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import CustomCard from "./CustomCard";
import Tooltip from "@mui/material/Tooltip";

// icons
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { PeopleEdit } from "@styled-icons/fluentui-system-filled/PeopleEdit";
import EmailIcon from '@mui/icons-material/Email';

export default function AllCards({ allPosts }) {
  const [hover, setHover] = useState(false);

  const [isCopiedMail, setIsCopiedMail] = useState('')
  const [isCopiedPhone, setIsCopiedPhone] = useState('')

  return (
    <div className="AllContactCards-parent">
      {allPosts?.map(
        (post, idx) =>
          allPosts[idx]?.contacts?.length > 0 && (
            <div key={idx}>
              <div className="d-flex justify-content-between align-items-center container-header">
                <h2>Company: {post.company}</h2>
                <PeopleEdit
                  style={{ width: 44, height: 44, cursor: "pointer" }}
                />
              </div>
              <Grid container spacing={4}>
                {post?.contacts.map((contact, index) => (
                  <Grid item key={index} style={{ width: "20rem" }}>
                    <CustomCard
                      color={contact?.backgroundColor}
                      avatar={contact.avatar}
                      title={contact.name}
                      brand={contact.jobTitle}
                      linkedin={
                        <a
                          href={contact.linkedin}
                          className="card-link"
                          target="_blank" rel="noreferrer"
                        >
                          <LinkedInIcon />
                        </a>
                      }
                      website={
                        <a
                          href={contact.companyWebsite}
                          className="card-link"
                          target="_blank"
                        >
                          <LanguageIcon />
                        </a>
                      }
                      cover={
                        // "https://previews.123rf.com/images/kuzenkova/kuzenkova1908/kuzenkova190800171/130337753-closeup-of-wet-colorfull-sand-pattern-of-a-beach.jpg"
                        "https://p4.wallpaperbetter.com/wallpaper/631/101/285/3d-abstract-fractal-fractal-flowers-wallpaper-preview.jpg"
                      }
                      phone={
                        <Tooltip
                          title={idx === isCopiedPhone ? 'copied' : `${contact?.phone} click to copy`}
                          arrow
                          placement="top"
                          onClick={() => {
                            navigator.clipboard.writeText(contact.phone);
                            setIsCopiedPhone(idx)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <LocalPhoneIcon />
                        </Tooltip>
                      }
                      email={
                        <Tooltip
                          title={idx === isCopiedMail ? 'copied' :  `${contact?.contactEmail} click to copy` }
                          arrow
                          placement="top"
                          onClick={() => {
                            navigator.clipboard.writeText(contact?.contactEmail);
                            setIsCopiedMail(idx);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <EmailIcon />
                        </Tooltip>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          )
      )}
    </div>
  );
}
