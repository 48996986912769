import { useState, useRef, useEffect } from 'react';

export const useObserveElementWidth = () => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const refCurrent = ref.current;

    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (refCurrent) {
      observer.observe(refCurrent);
    }

    return () => {
      refCurrent && observer.unobserve(refCurrent);
    };
  }, []);

  return {
    width,
    ref,
  };
};
