import { create } from 'zustand';

const useDetailsViewerStyleVariationsStore = create((set) => ({
  bgColor: '#f8f8f8',
  setBgColor: (color) => set({ bgColor: color }),
  color: '#000000',
  setColor: (color) => set({ color: color }),
  fontSize: '1.2rem',
  setFontSize: (size) => set({ fontSize: size }),
  fontWeight: 'normal',
  setFontWeight: (weight) => set({ fontWeight: weight }),
  lineHeight: '1.5',
  setLineHeight: (height) => set({ lineHeight: height }),
  topBoxShadow:
    '0px -3px 1px -2px rgba(0,0,0,0.2), 0px -2px 2px 0px rgba(0,0,0,0.14), 0px -1px 5px 0px rgba(0,0,0,0.12)',
  setBoxShadow: (shadow) => set({ topBoxShadow: shadow }),
}));

export { useDetailsViewerStyleVariationsStore };
