import './Login.scss';

import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import LoginForm from './LoginForm';
// import image
import woman1 from './img/woman1.png';
import woman2 from './img/woman2.png';
import woman3 from './img/business-lady.png';

import Register from '../Register/Register';

//react router dom
import { Link } from 'react-router-dom';
import { LazyMotion, domAnimation, AnimatePresence, m } from 'framer-motion';

import {
  getImageColors,
  linearRadialColorGenerator,
} from '../../utils/admin_functions';

import useMediaQueries from '../../utils/useMediaQueries';

export default function Login() {
  const location = useLocation();

  const { isTabletOrMobile } = useMediaQueries();

  const currentLocation = location?.pathname?.substring(1)?.toLocaleLowerCase();

  // create a background color variation with Color.js library get the color from the image and use it as a background color.
  const [bgColor, setBgColor] = useState('rgb(255, 255, 255)');
  // combine the image state and the direction state to avoid using multiple deceleration
  const [background, setBackground] = useState({
    image: null,
    direction: '0deg',
    rotateY: 0,
    x: 0,
  });
  const backgroundColor = {
    backgroundImage: linearRadialColorGenerator(
      bgColor,
      background?.direction,
      isTabletOrMobile ? 32 : 50,
      'l'
    ),
  };
  const imageBackground = {
    background: linearRadialColorGenerator(bgColor, null, null, 'r'),
  };

  useEffect(() => {
    if (currentLocation === 'login') {
      setBackground({
        image: woman2,
        direction: isTabletOrMobile ? '180deg' : '-90deg',
        rotateY: 90,
        x: -100,
      });
    } else {
      setBackground({
        image: woman3,
        direction: isTabletOrMobile ? '180deg' : '90deg',
        rotateY: -90,
        x: 100,
      });
    }
  }, [currentLocation, isTabletOrMobile]);

  useEffect(() => {
    const setImageColor = async () => {
      // TODO should be coming from the server
      const color = await getImageColors(background?.image);
      setBgColor(color);
    };

    setImageColor();
  }, [background.image]);

  const [lazyImageLoaded, setLazyImageLoaded] = useState(false);

  useEffect(() => {
    const handleLazyImageLoaded = () => {
      const lazyImage = new Image();
      lazyImage.src = currentLocation === 'login' ? woman2 : woman3;
      lazyImage.onload = () => {
        setLazyImageLoaded(true);
      };
    };

    handleLazyImageLoaded();
  }, [currentLocation]);

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className="login-container align-items-center vh-100 w-100 position-absolute top-0 start-0"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          ...backgroundColor,
        }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.7, type: 'spring', delay: 0.1 }}>
        <m.div
          key="loginSubContainer"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ type: 'anticipate', duration: 0.5 }}
          className="login-subcontainer justify-content-between position-relative m-auto bg-light">
          <div
            className={`login-image end-${
              currentLocation === 'register' ? 50 : 0
            }`}>
            <Link
              to="/"
              className={`login-backto-home position-absolute mt-5 zIndex text-light text-decoration-none  start-${
                currentLocation === 'register' ? 100 : 0
              }`}>
              Home
            </Link>

            <div className="imageFilter position-absolute w-100 h-100" />
            <div>
              <div className="content">
                <div className="content__container">
                  <p className="content__container__text">Hello</p>

                  <ul className="content__container__list">
                    <li className="content__container__list__item">world !</li>
                    <li className="content__container__list__item">you !</li>
                    <li className="content__container__list__item">future !</li>
                    <li className="content__container__list__item">
                      everybody !
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <m.div
              key="loginImageContainer"
              initial={{ backgroundColor: imageBackground?.background }}
              animate={{
                opacity: 1,
                background: imageBackground?.background,
              }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ textAlign: 'center' }}
              className="h-100">
              <AnimatePresence
                mountOnEnter
                exitBeforeEnter>
                {lazyImageLoaded && (
                  <m.img
                    src={background?.image}
                    key={background?.image}
                    alt=""
                    initial={{
                      x: background?.x,
                      opacity: 0,
                    }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{
                      x: background?.x,
                      opacity: 0,
                    }}
                    transition={{ type: 'tween', duration: 0.5 }}
                    // style={{

                    // }}
                    className='login-register-image'
                  />
                )}
              </AnimatePresence>
            </m.div>
          </div>
          <AnimatePresence
            mountOnEnter
            exitBeforeEnter>
            {
              {
                login: (
                  <m.div
                    key="loginForm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}>
                    <LoginForm className={`login-register-forms start-0`} />
                  </m.div>
                ),
                register: (
                  <m.div
                    key="registerForm"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}>
                    <Register className={`login-register-forms end-0`} />
                  </m.div>
                ),
              }[currentLocation]
            }
          </AnimatePresence>
        </m.div>
      </m.div>
    </LazyMotion>
  );
}
