import './singleCard.scss';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import { NextContext } from '../../../../Context';
import Tilt from 'react-parallax-tilt';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../../../utils/toasts';

import CustomCard from './SingleCardComponent/CusomtCard';
import { Cancel } from '@styled-icons/material-sharp/Cancel';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';

export default function SingleCard({ post, index, postID }) {
  const {
    currentUser,
    setAllPosts,
    setCurrentCardData,
    setJobCard,
    defaultJobCard,
    allPosts,
    setToastInfo,
  } = useContext(NextContext);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const sliceName = (name, length) => {
    return name.length >= length ? name.slice(0, length) + '...' : name;
  };

  const deleteCard = async () => {
    const postid = postID;

    setOpenDeleteModal(false);

    try {
      const response = await axios.delete(
        `/posts/delete/${currentUser._id}/${postid}`
      );

      if (response?.status === 200) {
        const { deletedPost } = response.data;
        setJobCard(defaultJobCard);

        setToastInfo({
          open: true,
          message: 'Card Successfully deleted',
          variant: 'success',
        });

        toast.success('Card Successfully deleted', toastConfig);

        const newAllPosts = allPosts.filter(
          (post) => post._id !== deletedPost._id
        );
        setAllPosts(newAllPosts);
      }
    } catch (error) {
      console.log('Error Deleting Card', error);
    }
  };

  const handleCardClick = () => {
    setCurrentCardData({ post: post, show: true });
    setJobCard(post);
  };

  const handleDeleteCard = () => {
    setOpenDeleteModal(true);
  };

  const handleClose = () => setOpenDeleteModal(false);

  return (
    <Tilt
      tiltEnable={false}
      key={index}
    >
      <CustomCard
        post={post}
        index={index}
        postID={postID}
        sliceName={sliceName}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteCard={handleDeleteCard}
        onDoubleClick={() => {
          setCurrentCardData({ post: post, show: true });
          setJobCard(post);
        }}
      />

      <Modal
        open={openDeleteModal}
        onClose={handleClose}
      >
        <Box sx={style}>
          <h2>Are you sure you want to delete this card?</h2>
          <div className='deleteOptionContainer'>
            <span
              onClick={deleteCard}
              className='deleteTooltipYes'
            >
              <CheckCircleFill
                style={{ height: '0.9em', marginRight: '3px' }}
              />
              Yes
            </span>
            <span
              className='deleteTooltipNo'
              onClick={() => setOpenDeleteModal(false)}
            >
              <Cancel style={{ height: '1rem' }} /> No
            </span>
          </div>
        </Box>
      </Modal>
    </Tilt>
  );
}
