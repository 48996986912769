/**
 * @module useImageColors
 * @category Hooks
 * @subcategory Image
 * @description Extracts a color palette from an image.
 * @param {string} imageData - The image data to extract the color from.
 * @param {number} numberOfColors - The number of colors to extract from the image.
 * @returns {string[]} The color palette from the image.
 * @see {@link https://www.npmjs.com/package/colorthief}
 */

import { useEffect, useState } from 'react';
import ColorThief from 'colorthief';

export function useImageColors(imageData, numberOfColors = 5) {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    async function fetchImageColors() {
      if (!imageData || imageData.length === 0) {
        return setColors([]);
      }

      const colorThief = new ColorThief();
      const img = new Image();

      if (isBase64Image(imageData)) {
        img.src = imageData;
      } else {
        // Assuming imageData is an image URL
        // img.crossOrigin = 'Anonymous';
        img.src = imageData;
      }

      await new Promise((resolve) => {
        img.addEventListener('load', () => {
          resolve();
        });
      });

      const colorPalette = colorThief.getPalette(img, numberOfColors); // Change 5 to the desired number of colors to extract

      const formattedColors = colorPalette.map(
        (color) => `rgb(${color[0]}, ${color[1]}, ${color[2]})`
      );
      setColors(formattedColors);
    }

    fetchImageColors();
  }, [imageData, numberOfColors]);

  return colors;
}

function isBase64Image(data) {
  return /^data:image\/(png|jpe?g|gif);base64,/.test(data);
}
