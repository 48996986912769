import React, { useState, useContext } from 'react';
import ContactCard from './ContactsComponents/ContactCard';
import Add from './Add';
import './contacts.scss';

// Context
import { NextContext } from '../../../../Context';

//motion
import { motion } from 'framer-motion';

export default function Contacts() {
  const { jobCard, currentCardData, setCurrentContactCard, allPosts } = useContext(NextContext);

  const [update, setUpdate] = useState();
  return (
    <motion.div style={{ position: 'relative', overflow: 'auto' }}>
      {currentCardData.show && <Add isShow={currentCardData.show} />}
      <ContactCard
        allPosts={allPosts}
        currentCardData={currentCardData}
        setCurrentContactCard={setCurrentContactCard}
        jobCard={jobCard}
      />
    </motion.div>
  );
}
