import "./PersonalTodos.scss";
import { Alert, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { NextContext } from "../../../Context";
import { MdDeleteForever } from "react-icons/md";
import moment from "moment";
import { Button, TextField } from "@mui/material";

const PersonalTodos = () => {
  //CONTEXT
  const {
    currentUser,
    setCurrentUser,
    todo,
    setTodo,
    allPosts,
    setAllPosts,
    totalTodoList,
    setTotalTodolist,
  } = useContext(NextContext);
  const [todoFlag, setTodoFlag] = useState(false);

  // LIST FILTER STATE
  const [todosStatus, setTodosStatus] = useState([
    "All",
    "Pending",
    "Completed",
    "Personal",
    "Company",
  ]);
  const [currentStatus, setCurrentStatus] = useState("All");
  // ALL LIST
  const getAllTodos = () => {
    let result = currentUser?.personalToDos.map((todo) => ({
      type: "user",
      ...todo,
    }));

    allPosts.forEach(
      (item) =>
        (result = [
          ...result,
          ...item.toDoList.map((todo2) => ({
            type: "company",
            postid: item._id,
            company: item.company,
            ...todo2,
          })),
        ])
    );

    if (currentStatus === "All") {
      result = result;
    } else if (currentStatus === "Pending") {
      result = result.filter((item) => item.completed === false);
    } else if (currentStatus === "Completed") {
      result = result.filter((item) => item.completed === true);
    } else if (currentStatus === "Personal") {
      result = result.filter((item) => item.type === "user");
    } else if (currentStatus === "Company") {
      result = result.filter((item) => item.type === "company");
    }

    return result;
  };
  const [totalList, setTotalList] = useState();
  useEffect(() => {
    setTotalTodolist(getAllTodos());
  }, [todoFlag]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  //TOGGLE PERSONAL TODO CHECKBOX
  const toggleComplete = async (id) => {
    const userid = currentUser?._id;
    const updatedTodos = currentUser?.personalToDos?.map((todo2) => {
      if (todo2._id === id) {
        todo2.completed = !todo2.completed;
      }
      return todo2;
    });

    const response = await axios.patch(
      `users/updatepersonaltodo/${userid}`,
      updatedTodos
    );

    if (response.data.success) {
      setCurrentUser(response.data.newUser);
      setTodoFlag(!todoFlag);
    }
  };

  // HANDLE COMPANY TODOS CHECK
  const handleCheck = async (postid, todoid) => {
    const index = allPosts?.findIndex((item) => item._id === postid);

    const updatedTodos = allPosts[index]?.toDoList?.map((todo2) => {
      if (todo2._id === todoid) {
        todo2.completed = !todo2.completed;
      }
      return todo2;
    });

    const response = await axios.patch(
      `posts/companytodo/${postid}/${todoid}`,
      updatedTodos
    );

    if (response.data.success) {
      let temp2 = allPosts?.filter((item) => item._id !== postid);
      temp2.push(response.data.newPost);

      setAllPosts(temp2);
      setTodoFlag(!todoFlag);
    }
  };

  // ADD PERSONAL TODO
  const addPersonalTodo = async (e) => {
    e.preventDefault();

    if (!todo) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return;
    }
    // NEW TO DO
    const newTodo = {
      completed: false,
      text: todo,
      date: Date.now(),
    };

    try {
      const response = await axios.post(
        `users/addpersonaltodo/${currentUser._id}`,
        newTodo
      );

      if (!response?.data.success) return;
      if (response.data.success) {
        setCurrentUser(response.data.newUser);
        setTodoFlag(!todoFlag);
      }

      setTodo("");
    } catch (error) {
      console.log("our error is", error.message);
    }
  };

  // DELETE PERSONAL TODO
  const deletePersonalTodo = async (id) => {
    const userid = currentUser?._id;
    const todoid = id;
    try {
      const response = await axios.patch(
        `users/deletepersonaltodo/${userid}/${todoid}`
      );

      if (!response?.data.success) return;
      if (response.data.success) {
        setCurrentUser(response.data.newUser);
        setTodoFlag(!todoFlag);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // DELETE COMPANY TODO
  const deleteCompanyTodo = async (postid, todoid) => {
    try {
      const response = await axios.patch(
        `posts/deletecompanytodo/${postid}/${todoid}`
      );

      if (response.data.success) {
        let temp = allPosts?.filter((item) => item._id !== postid);
        temp.push(response.data.newPost);
        setAllPosts(temp);
        setTodoFlag(!todoFlag);
      }
    } catch (error) {
      console.log("delete company todo error is: ", error.message);
    }
  };

  // LEFT SIDE FILTER BY STATUS (ALL/COMPLETED/UNCOMPLETED)
  const handleList = (status) => {
    setCurrentStatus(status);
    setTodoFlag(!todoFlag);
  };

  const handleCompanySort = (e) => {
    console.log(e.currentTarget.innerText);
  };

  return (
    <Row className="personaltodos">
      <hr style={{ marginBottom: "15px" }}></hr>
      <Col className="lefttodo col-2 ">
        <div className="m-0 p-0">
          {todosStatus.map((status, index) => {
            return (
              <div className="" key={index}>
                <h6
                  className="statusFilter  "
                  value={status}
                  onClick={() => handleList(status)}
                >
                  {status}
                </h6>
              </div>
            );
          })}
        </div>
      </Col>
      <Col className="col-10 righttodo">
        <Form className="w-100">
          <Row className="addTaskRow m-0 p-0">
            <Col>
              <TextField
                id="standard-basic"
                label="Enter a task"
                variant="standard"
                className="inputbox w-100 "
                maxLength="50"
                onChange={(e) => setTodo(e.target.value)}
                value={todo}
              />
            </Col>
            <Col>
              <Button
                className="addTaskButton "
                onClick={addPersonalTodo}
                variant="contained"
              >
                Add Task
              </Button>
            </Col>
          </Row>
          <Row>
            <Alert show={showAlert} variant="warning">
              You Need To Enter At Least 1 Task!
            </Alert>
          </Row>
        </Form>
        <h1
          className="mt-3"
          style={{
            display: totalList?.length > 0 ? "block" : "none",
          }}
        >
          ToDo
        </h1>

        <Row className="showTodo m-0 p-0 ">
          <Col className="m-0 p-0 ">
            <div className="tasks  ">
              {totalTodoList?.length > 0 &&
                totalTodoList
                  ?.filter((mylist) => mylist.completed === false)
                  ?.map((item) => (
                    <div
                      key={item._id}
                      className="singleTodo d-flex align-items-center border-bottom ps-2"
                      htmlFor={item.id}
                    >
                      <input
                        type="checkbox"
                        className="checktodo  ps-2   d-flex align-items-center justify-content-center"
                        onChange={() =>
                          item.type === "user"
                            ? toggleComplete(item._id)
                            : handleCheck(item.postid, item._id)
                        }
                        checked={item.completed}
                      />
                      <h6 className=" todoText  text-start text-dark ps-3 pt-2   d-flex align-items-center justify-content-start">
                        {item.text}
                      </h6>
                      <h6
                        onClick={(e) => handleCompanySort(e)}
                        className="todoCompany text-start pt-2"
                      >
                        {item?.company ? item?.company : "Personal"}
                      </h6>
                      <h6 className=" todoDate  text-dark pe-2 pt-1  text-end  ">
                        {moment(item.date).fromNow()}
                      </h6>
                      <div className="deleteIcon2 ms-4 ">
                        <MdDeleteForever
                          className="fs-4   "
                          onClick={() =>
                            item.type === "user"
                              ? deletePersonalTodo(item._id)
                              : deleteCompanyTodo(item.postid, item._id)
                          }
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </Col>
        </Row>
        {/* <hr
          style={{
            display: totalList.length > 0 ? "block" : "none",
          }}
          className="w-100"
        /> */}

        <Row className="showTodo m-0 p-0 ">
          <Col className="m-0 p-0 ">
            <div className="tasks  ">
              {totalTodoList?.length > 0 &&
                totalTodoList
                  ?.filter((mylist) => mylist.completed === true)
                  ?.map((item) => (
                    <div
                      key={item._id}
                      className="singleTodo d-flex align-items-center border-bottom ps-2"
                      htmlFor={item.id}
                    >
                      <input
                        type="checkbox"
                        className="checktodo  ps-2   d-flex align-items-center justify-content-center"
                        onChange={() =>
                          item.type === "user"
                            ? toggleComplete(item._id)
                            : handleCheck(item.postid, item._id)
                        }
                        checked={item.completed}
                      />
                      <h6 className="todoText completedTodoText  text-start text-dark ps-3 pt-2   d-flex align-items-center justify-content-start">
                        {item.text}
                      </h6>
                      <h6 className="todoCompany text-start pt-2">
                        {item?.company ? item?.company : "Personal"}
                      </h6>
                      <h6 className=" todoDate  text-dark pe-2 pt-1  text-end  ">
                        {moment(item.date).fromNow()}
                      </h6>
                      <div className="deleteIcon2 ms-4 ">
                        <MdDeleteForever
                          className="fs-4   "
                          onClick={() =>
                            item.type === "user"
                              ? deletePersonalTodo(item._id)
                              : deleteCompanyTodo(item.postid, item._id)
                          }
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </Col>
        </Row>
      </Col>
      <Row>{/*  <EnhancedTable totalList={totalList} /> */}</Row>
    </Row>
  );
};

export default PersonalTodos;
