import { MdDeleteForever } from 'react-icons/md';
import "./notes.scss"
import { useState } from 'react';

export default function Note({ id, text, handleDeleteNote }) {

	const [color, setColor] = useState(generateRandomColor());

	return (
		<div className='note' style={{ backgroundColor: color }}>
			<p>{text}</p>
			<div className='note-footer'>
				<MdDeleteForever
					onClick={() => handleDeleteNote(id)}
					className='delete-icon'
					size='1.3em'
				/>
			</div>
		</div>
	);
};
function generateRandomColor() {
	const r = Math.floor(Math.random() * 256);
	const g = Math.floor(Math.random() * 256);
	const b = Math.floor(Math.random() * 256);
	return `#${r.toString(16).padStart(2, "0")}${g
		.toString(16)
		.padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}