import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';

import ErrorBoundary from 'src/components/Error/ErrorBoundry';

import parse from 'html-react-parser';
import Header from './Header';

import FontSizeChanger from './DetailsViewerComponent/FontSizeChanger';
import { useDrawerWidthStore } from '../../stores/drawerWidthStore';

import DividerWithTitle from './DetailsViewerComponent/DividerWithTitle';

import CustomAccordion from './DetailsViewerComponent/CustomAccordion';
import { getChipsForJobDetails } from './helper/jobDetailsHelperFunctions';

// !NOTE This component is only the content of the drawer. It is not a full drawer.
export default function PersistentDrawerRight({ jobsDetails, companyLogo }) {
  const [userFontSize, setUserFontSize] = React.useState({
    fontSize: '1.3rem',
    disableMinus: true,
    disablePlus: false,
  });

  const setDrawerWidth = useDrawerWidthStore((state) => state.setDrawerWidth);
  const drawerWidth = useDrawerWidthStore((state) => state.drawerWidth);

  const addLineBreaks = (str) => {
    // Check if the string contains '\n'
    if (!str.includes('\n')) {
      // Add line breaks at the end of each sentence that ends with a dot
      str = str.replace(/\. /g, '.<br /><br />');
    }

    // Replace special characters with line breaks
    // str = str.replace(/[*]/g, '<br /><br />$&');
    str = str.replace(/[■]/g, '<br /><br />$&');
    // str = str.replace(/([*■])\2+/g, '<br /><br />$&');
    // str = str.replace(/[+]/g, '<br />$&');

    // Replace '\n' with line breaks
    str = str.replace(/\n/g, '<br />');

    return str;
  };

  // const handleDrawerWidth = (width) => {
  //   setDrawerWidth(width);
  // };

  const updateFontSize = (size) => {
    const currentFontSize = Number(userFontSize?.fontSize?.split('rem')[0]);
    const fontSize = 0.2;
    const newFontSize = currentFontSize;
    const viewerWidth = Number(drawerWidth.toString().split('px')[0]);
    switch (size) {
      case 'plus':
        if (newFontSize >= 4) {
          return setUserFontSize({
            fontSize: '4rem',
            disablePlus: true,
          });
        }

        if (viewerWidth <= 1000) {
          setDrawerWidth(`${viewerWidth + 100}px`);
        }

        return setUserFontSize({
          fontSize: `${newFontSize + fontSize}rem`,
          disableMinus: false,
          disablePlus: false,
        });

      case 'minus':
        if (newFontSize <= 1.3) {
          setDrawerWidth(`${viewerWidth + 100}px`);
          return setUserFontSize({
            fontSize: '1.3rem',
            disableMinus: true,
          });
        }
        return setUserFontSize({
          fontSize: `${newFontSize - fontSize}rem`,
          disableMinus: false,
          disablePlus: false,
        });

      default:
        break;
    }
  };

  const resetFontSize = () => {
    setUserFontSize({
      fontSize: '1.3rem',
      disableMinus: true,
      disablePlus: false,
    });
  };

  React.useEffect(() => {
    setDrawerWidth(`${window.innerWidth / 2}px`);
  }, []);

  // console.log('🚀 ~ file: DetailsViewer.jsx:53 ~ userFontSize:', userFontSize);

  return (
    <ErrorBoundary>
      <Container
        id='DetailsViewer'
        sx={{
          // height: '100vh',
          fontSize: '0.8rem',
          mt: 10,
        }}
        maxWidth='100%'
      >
        {jobsDetails && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              p: 2,
            }}
          >
            <Box sx={{ width: '100%', position: 'relative' }}>
              <Header
                company={jobsDetails?.arbeitgeber}
                companyLogo={companyLogo}
                applyLink={jobsDetails?.externeUrl}
                tags={{
                  workedType: jobsDetails?.arbeitszeitmodelle,
                  language:
                    jobsDetails?.sprachkenntnisse &&
                    getChipsForJobDetails(jobsDetails, 'sprachkenntnisse'),
                  workingHoursInfo:
                    jobsDetails?.informationenZurArbeitszeit && [
                      jobsDetails?.informationenZurArbeitszeit,
                    ],
                  otherTraining: jobsDetails?.sonstigesAusbildung,
                }}
                subtitle={jobsDetails?.titel}
                // locations={Object?.values(jobsDetails?.arbeitsorte)}
                locations={jobsDetails?.arbeitsorte}
                position={jobsDetails?.beruf}
                companyWebsite={
                  jobsDetails?.arbeitgeberdarstellungUrl ||
                  jobsDetails?.allianzpartnerUrl
                }
              />
            </Box>

            <CustomAccordion
              title='Job Description'
              id='description-CustomAccordion'
              expand={!jobsDetails?.staerken?.length}
              customizer={
                <FontSizeChanger
                  updateFontSize={updateFontSize}
                  resetFontSize={resetFontSize}
                  userFontSize={userFontSize}
                />
              }
              sx={{
                width: '100%',
              }}
            >
              <Typography
                // component='p'
                variant='body'
                sx={{
                  fontSize: userFontSize || {
                    xs: '1rem',
                    md: '2rem',
                    lg: '1.2rem',
                  },
                  width: '100%',
                  // p: '1rem',
                  // whiteSpace: 'pre-wrap',
                }}
                id='description'
              >
                {jobsDetails?.stellenbeschreibung &&
                  parse(
                    addLineBreaks(jobsDetails?.stellenbeschreibung)
                    // jobsDetails?.stellenbeschreibung
                  )}
              </Typography>
            </CustomAccordion>

            <DividerWithTitle
              title='Other Requirements'
              typographyColor='text.secondary'
            />

            {jobsDetails?.staerken?.length && (
              <CustomAccordion
                title='Strengths'
                id='strengths'
                sx={{
                  width: '100%',
                }}
              >
                {jobsDetails?.staerken?.map((strength, index) => (
                  <List
                    // sx={{ width: '100%' }}
                    key={index}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <StarIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={strength} />
                    </ListItem>
                  </List>
                ))}
              </CustomAccordion>
            )}

            {jobsDetails?.istGoogleJobsRelevant && (
              <CustomAccordion
                title='Google Jobs'
                id='google-jobs'
              >
                <Typography variant='body1'>
                  This job is relevant for Google Jobs
                </Typography>
              </CustomAccordion>
            )}
          </Box>
        )}
      </Container>
    </ErrorBoundary>
  );
}
