/* 
  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────────────----------───┐
  │ This component is from                                                                                                    │
  │ https://next.mui-treasury.com/?path=/story/template-card--highlight&globals=googleFont:Fjalla+One;googleFontSecondary:Sen │
  └─────────────────────────────────────────────────────────────────────────────────────────────────────────────────----------┘
 */

import React from 'react';
import AllContactCards from './AllContactCards';
import OneCard from './OneCard';

export default function CardHighlight({ allPosts, currentCardData, setCurrentContactCard, jobCard }) {
  return (
    <div>
      {currentCardData.show ? (
        <OneCard
          jobCard={jobCard}
          currentCardData={currentCardData}
          setCurrentContactCard={setCurrentContactCard}
        />
      ) : (
        <AllContactCards
          allPosts={allPosts}
          setCurrentContactCard={setCurrentContactCard}
        />
      )}
    </div>
  );
}
