import { Box, Divider } from '@mui/material';
import React from 'react';

import IconButton from '@mui/material/IconButton';
import TextIncreaseRoundedIcon from '@mui/icons-material/TextIncreaseRounded';
import TextDecreaseRoundedIcon from '@mui/icons-material/TextDecreaseRounded';
import Stack from '@mui/material/Stack';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Typography from '@mui/material/Typography';
import Color from 'color';

const CustomIconButton = ({ children, ...props }) => {
  return (
    <IconButton
      {...props}
      // aria-label='minus'
      // onClick={() => updateFontSize(-0.2)}
      // disabled={userFontSize.disableMinus}
      sx={{
        color: '#000',
        fontSize: { xs: '1rem', md: '2rem', lg: '1.2rem' },
        borderRadius: '10px',
        // height: 1,
        ...props.sx,
      }}
    >
      {children}
    </IconButton>
  );
};

export default function FontSizeChanger({
  userFontSize,
  updateFontSize,
  resetFontSize,
  sx,
  ...res
}) {
  return (
    <Stack
      className='font-size-container'
      gap={1}
      // my={2}
      direction='row'
      // justifyContent='center'
      // alignItems='center'
      alignContent='center'
      alignSelf='flex-end'
      width='fit-content'
      // boxShadow={2}
      boxShadow={`0px -3px 1px -2px rgba(0,0,0,0.2), 0px -2px 2px 0px rgba(0,0,0,0.14), 0px -1px 5px 0px rgba(0,0,0,0.12)`}
      sx={{
        borderRadius: '10px',
        bgcolor: '#f8f8f8',
        userSelect: 'none',
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        ...sx,
      }}
      position='sticky'
      top={10}
      right={10}
      mb={1}
      {...res}
    >
      <Stack
        direction='row'
        alignItems='center'
      >
        <CustomIconButton
          aria-label='minus'
          onClick={() => updateFontSize('minus')}
          disabled={userFontSize.disableMinus}
        >
          <TextDecreaseRoundedIcon />
        </CustomIconButton>
        <Divider
          orientation='vertical'
          variant='middle'
          // component='div'
          sx={{
            // height: '80% !important',
            height: 'initial !important',
          }}
          flexItem
        />

        <CustomIconButton disabled>
          <Typography
            variant='h5'
            // make the text looks like as if it is inside the background
            sx={{
              fontWeight: 'bold',
            }}
          >
            Font size
          </Typography>
        </CustomIconButton>

        <Divider
          orientation='vertical'
          variant='middle'
          // component='div'
          sx={{
            // height: '80% !important',
            height: 'initial !important',
          }}
          flexItem
        />

        <CustomIconButton
          aria-label='add'
          onClick={() => updateFontSize('plus')}
          disabled={userFontSize.disablePlus}
        >
          <TextIncreaseRoundedIcon />
        </CustomIconButton>
      </Stack>
      {/* 
      <Divider
        orientation='vertical'
        component='div'
        sx={{
          bgcolor: '#fff',
          m: '0',
          p: '0',
        }}
        flexItem
      /> */}

      <Divider
        orientation='vertical'
        // component='div'
        sx={{
          height: 'auto !important',
        }}
        flexItem
      />
      <CustomIconButton
        aria-label='reset'
        onClick={() => resetFontSize()}
        disabled={userFontSize.disableMinus}
        sx={{
          m: 0,
          borderRadius: '10px',
          color: '#000',
        }}
      >
        <RotateLeftIcon />
      </CustomIconButton>
      {/* <Box sx={{ width: '100%' }}></Box> */}
    </Stack>
  );
}
