import React, { useEffect } from 'react';
import Map, { Marker } from 'react-map-gl';

const mapboxToken =
  'pk.eyJ1Ijoia2FyZWVtMTk5MCIsImEiOiJjbGY1eXBudHgxMHRjM3NvMW1xYzIwMDVlIn0.ClIk71Tq6tATfXwQ8h1SzQ';

export default function MapboxMap({ allPosts }) {
  // refactor the code to make Marker unique when there are multiple markers
  const [marker, setMarker] = React.useState([]);

  useEffect(() => {
    const uniqueLocations = allPosts?.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.location?.lat === current?.location?.lat
      );
      if (!x && current?.location?.lat && current?.location?.lng) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    setMarker(
      uniqueLocations?.map((item, index) => ({
        latitude: item?.location?.lat,
        longitude: item?.location?.lng,
      }))
    );
  }, [allPosts]);
  console.log('%c marker', 'color: red; font-size: 20px', marker);

  const renderMarker = (item, idx) => (
    <Marker
      key={idx}
      longitude={item?.latitude}
      latitude={item?.latitude}></Marker>
  );

  return (
    <div
      key="map"
      className="h-100 w-100">
      {allPosts?.length && marker.length && (
        <Map
          initialViewState={{
            longitude: marker[0]?.longitude,
            latitude: marker[0]?.latitude,
            zoom: 14,
          }}
          style={{ width: '100%', height: '90%' }}
          mapboxAccessToken={mapboxToken}
          mapStyle="mapbox://styles/mapbox/streets-v9">
          {marker.map(renderMarker)}
        </Map>
      )}

      {!allPosts?.length && (
        <div className="text-center h-100">
          <h1 className="text-danger">No Location Selected</h1>
          <p className="text-danger">
            Please select a location when posting a new card!{' '}
          </p>
          <Map
            initialViewState={{
              longitude: -122.4,
              latitude: 37.8,
              zoom: 14,
            }}
            style={{ width: '100%', height: '90%' }}
            mapboxAccessToken={mapboxToken}
            mapStyle="mapbox://styles/mapbox/streets-v9"></Map>
        </div>
      )}
    </div>
  );
}
