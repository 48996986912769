import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { NextContext } from '../../Context';
import Tooltip from '@mui/material/Tooltip';
import { motion, AnimatePresence, m } from 'framer-motion';
import NewFeatureHighLight from 'src/components/Extra/NewFeatureHighLight';

export default function Navbar({ children }) {
  const { currentUser, setCurrentUser, brand } = useContext(NextContext);

  const handleLogOut = () => {
    setCurrentUser({});
    localStorage.removeItem('authorizedUser');
  };

  return (
    <motion.div
      className='navbar-container d-flex align-items-center justify-content-between'
      id='navbar-container'
      style={{
        // padding: '0 20px 20px',
        // margin: '2% 6% 0',
      }}
    >
      <div className='navbar-brand'>
        <Link
          style={{ width: '20%' }}
          to='/'
        >
          <img
            src={brand}
            alt='black logo'
            style={{ width: '100%' }}
          />
        </Link>
      </div>
      <div
        className='nav'
        style={{ fontFamily: 'Raleway', fontSize: '1.5em' }}
      >
        {!currentUser?._id ? (
          <>
            <Link
              className='nav-link'
              to='login'
            >
              Login
            </Link>
            <Link
              className='nav-link'
              to='register'
            >
              Register
            </Link>
          </>
        ) : (
          <>
            <Link
              className='nav-link'
              to='dashboard'
            >
              Dashboard
            </Link>
          </>
        )}

        <NewFeatureHighLight
          left='0.5em'
          top='-3em'
          title='New'
        >
          <NavLink
            to='jobs-search'
            className={
              (({ isActive }) => (isActive ? 'active' : 'none'),
              'nav-link new-feature')
            }
          >
            Browse Jobs
          </NavLink>
        </NewFeatureHighLight>
        <NavLink
          to='about'
          className={
            (({ isActive }) => (isActive ? 'active' : 'none'), 'nav-link')
          }
        >
          About
        </NavLink>
        {currentUser?._id && (
          <Link
            to='#'
            className='nav-link logout-btn'
            onClick={handleLogOut}
          >
            Log Out
          </Link>
        )}
      </div>
    </motion.div>
  );
}
