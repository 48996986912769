export const getChipsForJobDetails = (jobDetails, jobDetailKey) => {
  if (jobDetails?.[jobDetailKey]) {
    const { uuid, ...jobDetailsWithoutUUID } = jobDetails[jobDetailKey];
    if (Object.keys(jobDetailsWithoutUUID)?.length >= 2) {
      return Object.values(jobDetailsWithoutUUID);
    }
    return [Object.values(jobDetailsWithoutUUID)[0]];
  }
  return [];
};
