import './error.scss';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function Error() {
  const location = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState({});

  const [timer, setTimer] = useState(10);

  const errorStatus = location?.state?.error;

  useEffect(() => {
    setError({
      leftNumber: errorStatus?.code?.toString().split('')[0],
      rightNumber: errorStatus?.code?.toString().split('')[2],
    });
  }, [errorStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   if (timer === 0) {
  //     navigate('/dashboard', { replace: true });
  //   }
  // }, [timer, navigate]);

  if (timer === 0) {
    setTimeout(() => {
      navigate('/dashboard', { replace: true });
    }, 1000);
  }

  return (
    <div
      className="error-container d-flex flex-column justify-content-center align-items-center"
      style={{
        paddingBottom: '20px',
        margin: '2% 6% 0%',
      }}>
      <h1>
        {error.leftNumber}
        <span>
          <i className="fas fa-ghost"></i>
        </span>
        {error.rightNumber}
      </h1>
      <h2>
        Error: {errorStatus?.code} {errorStatus?.message}
      </h2>
      <p>Sorry, the page you're looking for cannot be accessed</p>

      <div className="d-flex justify-content-center">
        <Link to="/" className="btn button">
          Go to Home
        </Link>
        <Link to="/#contact" target="_blank" className="btn button">
          Contact us
        </Link>
      </div>
      {/* Add the statement to tell the user that it will be recheck again in 30 seconds */}
      <p className="mt-3">
        It will be recheck again in {timer} seconds or you can click the button
        to go to home page now!
      </p>
    </div>
  );
}
