import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AddCardInfo from "./AddCardInfo.jsx";
import Contacts from "./Contacts/Contacts";
import Notes from "./notes/AppNote";
import CurrentCards from "./Contacts/ContactsComponents/OneCard";
import AddContactCard from "./Contacts/Add";
import BoxTransition from "../../../Animation/BoxTransition";
import CoverLetter from "./CoverLetter/CoverLetter.jsx";
import {motion} from 'framer-motion'
import ToDo from "./ToDo/ToDo.jsx";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-100"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function BasicTabs({ setCurrentContactCard, jobCard }) {
  const [value, setValue] = React.useState(0);
  const [openAdd, setOpenAdd] = React.useState(false);

  const iconStyle = {
    width: 45,
    height: 45,
    cursor: "pointer",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "column" },
        alignItems: "center",
        p: 5,
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          as="div"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Job Info" {...a11yProps(0)} />
          <Tab label="To Do List" {...a11yProps(1)} />
          <Tab label="Notes" {...a11yProps(2)} />
          <Tab label="Contacts" {...a11yProps(3)} />
          <Tab label="Cover Letter" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel as="div" value={value} index={0}>
        <div>
          <AddCardInfo />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div>
          <ToDo />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Notes />
      </TabPanel>

      <TabPanel value={value} index={3} style={{ overflow: "hidden" }}>
        <BoxTransition>
          <AddContactCard />
        </BoxTransition>
        <CurrentCards
          setCurrentContactCard={setCurrentContactCard}
          jobCard={jobCard}
        />


      </TabPanel>

      <TabPanel value={value} index={4} className="w-100 h-100">
        <CoverLetter />
      </TabPanel>
    </Box>
  );
}
