import React, { useState, useContext, useEffect } from 'react';
import Tabs from './AddJobCard/Tabs';
import '././AddJobCard/addCard.scss';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { NextContext } from '../../Context';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../utils/toasts';

function AddCardModalContent(props) {
  const {
    currentUser,
    jobCard,
    setJobCard,
    currentCardData,
    setCurrentContactCard,
    requiredData,
    setRequiredData,
    setAllPosts,
    allPosts,
  } = useContext(NextContext);

  const resetJobCard = () => {
    return setJobCard({
      owner: currentUser?._id,
      company: '',
      title: '',
      description: '',
      location: '',
      salary: '',
      date: '',
      link: '',
      status: 'applied',
      favorites: false,
      color: 'hotpink',
      contacts: [],
      toDoList: [],
      notes: [],
    });
  };
  //-------Saving JobCard---------
  const handleSaveJob = async () => {
    toast.dismiss();
    try {
      if (jobCard?.title === '' || jobCard.company === '') {
        toast.warn('Company name and Job title are required', toastConfig);
        setRequiredData(true);
      } else {
        const jobCardWithDate = { ...jobCard, date: new Date() };
        const response = await axios.post('/posts/add', jobCard);
        if (response.data.success) {
          resetJobCard();
          // update the current AllPosts with the new post
          const newPost = response.data.post;
          const newAllPosts = [...allPosts, newPost];
          setAllPosts(newAllPosts);
          toast.success('Job Card Saved Successfully', {
            ...toastConfig,
            icon: '🚀',
          });
        }

        setRequiredData(false);
        props.onHide();

        if (!response?.data.success) {
          toast.error('Error, Job Card Not Saved', toastConfig);
          return;
        }
      }
    } catch (error) {
      toast.error(`Save Failed: ${error.message} 🤯`, toastConfig);
      console.log('our error is', error.message);
    }
  };

  //-------Updating Card---------

  const handleUpdateCard = async () => {
    const postid = currentCardData.post._id;

    try {
      if (jobCard?.title === '' || jobCard.company === '')
        return setRequiredData(true);

      // check if the user changed something in the job card
      const isJobCardChanged = Object.keys(jobCard).some(
        (key) => jobCard[key] !== currentCardData.post[key]
      );

      if (!isJobCardChanged) {
        toast.info('No Changes Was Made', toastConfig);
        props.onHide();
        return;
      }

      const response = await axios.put(
        `/posts/update/${currentUser._id}/${postid}`,
        jobCard
      );

      if (response.data.success) {
        // update the current AllPosts with the new post
        const newPost = response.data.post;
        const newAllPosts = allPosts.map((post) =>
          post._id === newPost._id ? newPost : post
        );
        setAllPosts(newAllPosts);
        resetJobCard();
        toast.success('Job Card Updated Successfully', {
          ...toastConfig,
          icon: '🚀',
        });
      }
      setRequiredData(false);
      props.onHide();
      if (!response?.data.success) return;
    } catch (error) {
      toast.error(`Update Fails: ${error.message} 🤯`, toastConfig);
      console.log('our error is', error.message);
    }
  };

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{
        justifyContent: 'space-between',
      }}
    >
      <Modal.Body
        className='px-5 py-0'
        style={{ height: '48em' }}
      >
        <Tabs
          setCurrentContactCard={setCurrentContactCard}
          jobCard={jobCard}
        />
      </Modal.Body>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant='none'
          style={{ width: '50%', backgroundColor: '#f5f5f5' }}
          onClick={props.onHide}
        >
          Close
        </Button>
        {currentCardData.post ? (
          <Button
            onClick={handleUpdateCard}
            variant='none'
            style={{ width: '50%', backgroundColor: 'lightblue' }}
          >
            Update Card
          </Button>
        ) : (
          <Button
            onClick={handleSaveJob}
            variant='none'
            style={{ width: '50%', backgroundColor: 'lightblue' }}
          >
            Save card
          </Button>
        )}
      </div>
      {(requiredData && jobCard?.company === '') ||
      (requiredData && jobCard?.title === '') ? (
        <Alert
          variant='outlined'
          severity='error'
        >
          Company name and Job title are required <sup>*</sup>
        </Alert>
      ) : null}
    </Modal>
  );
}

export default function AddCardModal() {
  const { setCurrentCardData, currentCardData, setJobCard, currentUser } =
    useContext(NextContext);
  const [modalShow, setModalShow] = useState(currentCardData.show);

  useEffect(() => {
    if (currentCardData.show) {
      setModalShow(currentCardData.show);
    }
  }, [currentCardData.show]);

  return (
    <div className='modal-container'>
      {/* <Link to="/dashboard">Go to dashboard</Link> */}

      <AddCardModalContent
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setCurrentCardData({ show: false });

          setJobCard({
            owner: currentUser?._id,
            company: '',
            title: '',
            description: '',
            location: '',
            salary: '',
            link: '',
            status: 'applied',
            favorites: false,
            color: 'hotpink',
            contacts: [],
            toDoList: [],
            notes: [],
          });
        }}
      />
    </div>
  );
}
