import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { NextContext } from '../Context';
import './Profile.scss';
import { Row, Col } from 'react-bootstrap';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';

const Profile = () => {
  const { currentUser, allPosts } = useContext(NextContext);

  // // actual date
  // const [date, setDate] = useState({
  //   actualYear: new Date().getFullYear(),
  //   actualMonth: new Date().getMonth() + 1,
  //   actualDay: new Date().getDate(),
  // });

  // user date
  // const [myDate, setMyDate] = useState({
  //   year: currentUser?.birthDate
  //     ? Number(currentUser.birthDate.substring(0, 4))
  //     : null,
  //   month: currentUser?.birthDate
  //     ? Number(currentUser.birthDate.substring(5, 7))
  //     : null,
  //   day: currentUser?.birthDate
  //     ? Number(currentUser.birthDate.substring(8))
  //     : null,
  // });

  // const [userAge, setUserAge] = useState(
  //   date.actualMonth >= myDate.month
  //     ? date.actualYear - myDate.year
  //     : date.actualYear - myDate.year - 1
  // );

  return (
    <div className='profile '>
      <div className='mainDiv'>
        <div className='card '>
          <div className='profile-image'>
            <img
              src={
                currentUser?.image ||
                `https://api.dicebear.com/7.x/adventurer/svg?seed=${currentUser.firstName}.svg`
              }
              alt='...'
            />
          </div>
          <section className='card-notblur'></section>
          <section className='card-blur'>
            <div className='blur-background'></div>
            <div className='card-texts'>
              <h1 className='title'>
                {`${currentUser?.firstName || ''} ${
                  currentUser?.lastName || ''
                } `}
              </h1>
              <div>
                <Row className='metrics-row m-0 p-0 '>
                  <Col className='metrics'>
                    <h5>WISHLIST</h5>
                    <h6>
                      {
                        allPosts?.filter((item) => item.status === 'wishlist')
                          .length
                      }
                    </h6>
                  </Col>
                  <Col className='metrics '>
                    <h5>APPLIED</h5>
                    <h6>
                      {
                        allPosts?.filter((item) => item.status === 'applied')
                          .length
                      }
                    </h6>
                  </Col>
                  <Col className='metrics '>
                    <h5>INTERVIEW</h5>
                    <h6>
                      {
                        allPosts?.filter((item) => item.status === 'interview')
                          .length
                      }
                    </h6>
                  </Col>
                  <Col className='metrics '>
                    <h5>OFFER</h5>
                    <h6>
                      {
                        allPosts?.filter((item) => item.status === 'offer')
                          .length
                      }
                    </h6>
                  </Col>
                  <Col className='metrics '>
                    <h5>REJECTED</h5>
                    <h6>
                      {
                        allPosts?.filter((item) => item.status === 'rejected')
                          .length
                      }
                    </h6>
                  </Col>
                </Row>
              </div>
              <div className='user-infos '>
                <p>
                  {<AlternateEmailIcon />} <span>{currentUser?.email}</span>
                </p>
                <p>
                  {<LocalPhoneIcon />} <span>{currentUser?.phone || ''}</span>
                </p>

                <p>
                  {<HomeIcon />}
                  <span>{`${currentUser?.address?.street || ''} ${
                    currentUser?.address?.houseNumber || ''
                  } ${currentUser?.address?.zipcode || ''} ${
                    currentUser?.address?.city || ''
                  } ${currentUser?.address?.country || ''}`}</span>
                </p>
              </div>

              <div className='edit-div'>
                <Link to='/editProfile'>
                  <button className='learn-more'>
                    <span
                      className='circle'
                      aria-hidden='true'
                    >
                      <span className='icon arrow'></span>
                    </span>
                    <span className='button-text'>Edit Profile</span>
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Profile;
