import React, { useEffect, useState } from 'react';
import { useJobsStore } from 'src/stores/jobStore';

// Custom hook for fetching jobs and handling errors
const useFetchAndHandleJobs = () => {
  const fetchJobsHandler = useJobsStore((state) => state.fetchJobsHandler);
  const [timer, setTimer] = useState(10);
  const [prevTimer, setPrevTimer] = useState(10);
  const error = useJobsStore((state) => state.error);
  const maxResults = useJobsStore((state) => state.maxResults);

  useEffect(() => {
    if (error && maxResults === 0) {
      const interval = setInterval(() => {
        setTimer((timer) => timer - 1);
        setPrevTimer((prevTimer) => prevTimer + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [error, maxResults, timer]);

  useEffect(() => {
    if (timer <= 0) {
      fetchJobsHandler();
      setTimer(10);
    }
  }, [timer, fetchJobsHandler, prevTimer]);

  return {
    error,
    timer,
    maxResults,
    fetchJobsHandler,
    prevTimer,
    setPrevTimer,
    setTimer,
  };
};

export default useFetchAndHandleJobs;
