import React, { useEffect, useContext } from "react";
import { CirclePicker } from "react-color";
import "./addCard.scss";

export default class ColorBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      background: "#f2f2f2",
    };
  }
  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
    this.props.getColor(color.hex);
  };

  render() {
    return (
      <div
        className="colorPickerContainer"
        style={{ visibility: `${this.props.isVisible}` }}
      >
        <CirclePicker
          color={this.state.background}
          onChangeComplete={this.handleChangeComplete}
        />
      </div>
    );
  }
}
