import React from 'react';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import './register.scss';

// mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Divider from '@mui/material/Divider';

import { toast } from 'react-toastify';
import { toastConfig } from '../../utils/toasts';

// react icon
import { FcGoogle } from 'react-icons/fc';

export default function Register({ className = '' }) {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    pass: '',
  });

  const [validation, setValidation] = useState({
    firstName: true,
    lastName: true,
    email: true,
    pass: true,
  });
  const { firstName, lastName, email, pass } = userData;

  const navigate = useNavigate();

  const handleValidation = () => {
    const validation = {
      firstName: firstName.length > 0,
      lastName: lastName.length > 0,
      email: email.length > 0,
      pass: pass.length > 0 && pass.length >= 6,
    };

    setValidation(validation);
    return validation;
  };

  const handleClick = async (e) => {
    toast.dismiss();
    console.log('%c e', 'color: red; font-size: 20px', e.target.id);

    const validateFields = handleValidation();

    if (Object.values(validateFields).includes(false)) {
      const errorNames = {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        pass: 'Password',
      };
      const error = Object.keys(validateFields).filter(
        (key) => !validateFields[key]
      );

      error.forEach((err) => {
        const errorName = errorNames[err] || err;
        if (err === 'pass' && pass.length < 6)
          return toast.error('Password must be at least 6 characters', {
            ...toastConfig,
            icon: '🔒🚫',
          });
        toast.error(`${errorName} is required`, { ...toastConfig, icon: '🚫' });
      });

      return null;
    }

    const response = await axios.post('/users/register', userData);
    const data = response?.data;
    const { success, error } = data;
    console.log('%c response', 'color: red; font-size: 20px', response.data);
    if (success) {
      toast.success('Registered Successfully', { ...toastConfig, icon: '👍' });
      navigate('/login');
    }
    if (!success) {
      if (error.code === 11000) {
        toast.error('Email already exists', { toastConfig, icon: '🚫' });
        navigate('/login');
      }
      toast.error(error?.message, { toastConfig, icon: '🚫' });
    }
  };

  return (
    <motion.div
      layout
      className={`login-form bg-light text-center h-100  ${className}`}>
      <p className="display-1">SIGN UP</p>
      <FormControl
        component="form"
        className="RegisterForm"
        sx={{
          width: '70%',
          '& > :not(style)': { m: 1 },
          textAlign: 'center',
        }}
        autoComplete="off">
        <TextField
          id="firstName"
          label="First Name "
          variant="standard"
          placeholder=""
          margin="normal"
          error={!validation.firstName}
          onChange={(e) => {
            setUserData({ ...userData, firstName: e.target.value });
            if (firstName.length > 0) handleValidation();
          }}
        />
        <TextField
          id="lastName"
          label="Last Name "
          variant="standard"
          placeholder=""
          margin="normal"
          error={!validation.lastName}
          onChange={(e) => {
            setUserData({ ...userData, lastName: e.target.value });

            if (lastName.length > 0) handleValidation();
          }}
        />
        <TextField
          id="email"
          label="Email "
          variant="standard"
          placeholder=""
          margin="normal"
          autoComplete="off"
          error={!validation.email}
          onChange={(e) => {
            setUserData({ ...userData, email: e.target.value });
            if (email.length > 0) handleValidation();
          }}
        />
        <TextField
          id="password-register"
          label="Password "
          placeholder=""
          type="password"
          variant="standard"
          margin="normal"
          autoComplete="off"
          error={!validation.pass}
          onChange={(e) => {
            setUserData({ ...userData, pass: e.target.value });
            if (pass.length > 0) handleValidation();
          }}
        />

        <FormControlLabel
          value="end"
          control={<Checkbox />}
          label="Remember me"
        />
        <Button
          variant="contained"
          onClick={handleClick}
          sx={{
            backgroundColor: '#161616',
            borderRadius: '5px',
            fontSize: '1.4em',

            '&:hover': { backgroundColor: 'black' },
          }}
          className="button">
          Register
        </Button>
        {/* <Link to="/forgotpass" className="mt-3">
          Forgot Password?
        </Link> */}

        <Button
          sx={{
            width: 'fit-content',
            border: '2px solid #9e3134',
            color: '#9e3134',
            fontWeight: 'bold',
            padding: '10px 30px',
            '&:hover': { color: '#fff', backgroundColor: '#9e3134' },
          }}
          className="login-google align-self-center"
          href="/users/google"
          // startIcon={<MDBIcon fab icon="google" />}
          startIcon={<FcGoogle />}>
          Register with Google
        </Button>
        <Divider />
        <p>
          Already a member? <Link to="/login">Sign In</Link>
        </p>
      </FormControl>
    </motion.div>
  );
}
