import * as React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
  MDBCardSubTitle,
  MDBFooter,
} from "mdb-react-ui-kit";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import { Button, CardActionArea, CardActions } from "@mui/material";

import { GrLinkedinOption } from "react-icons/gr";
import { AiFillGithub } from "react-icons/ai";

export default function CardMusic({
  name,
  about,
  img,
  position,
  linkedin,
  github,
  index,
}) {
  return (
    <div className="col-lg-3 col-md-6 mb-4  mb-lg-0">
      {/* <div className="card"> */}
      <MDBCard className="h-100">
        <MDBRipple
          rippleColor="light"
          rippleTag="div"
          className="bg-image hover-overlay"
        >
          {/* <img src={img} className="w-100" /> */}
          <MDBCardImage src={img} fluid alt={name + " image"}  />
          {/* <a href="#!"> */}
          <div
            className="mask"
            style={{ backgroundColor: "rgba(251, 251, 251, 0.15)" }}
          ></div>
          {/* </a> */}

          {index === 0 ? (
            <svg
              className="position-absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              style={{ left: "0", bottom: "0" }}
            >
              <path
                fill="#fff"
                d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>
          ) : index === 1 ? (
            <svg
              className="position-absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              style={{ left: "0", bottom: 0 }}
            >
              <path
                fill="#fff"
                d="M0,288L48,256C96,224,192,160,288,160C384,160,480,224,576,213.3C672,203,768,117,864,85.3C960,53,1056,75,1152,69.3C1248,64,1344,32,1392,16L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>
          ) : index === 2 ? (
            <svg
              className="position-absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              style={{ left: "0", bottom: 0 }}
            >
              <path
                fill="#fff"
                d="M0,96L48,128C96,160,192,224,288,240C384,256,480,224,576,213.3C672,203,768,213,864,202.7C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>
          ) : (
            <svg
              className="position-absolute"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              style={{ left: "0", bottom: "0" }}
            >
              <path
                fill="#fff"
                d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>
          )}
        </MDBRipple>
        <MDBCardBody className='user-select-none'>
          <MDBCardTitle className="fw-bold">{name}</MDBCardTitle>
          <MDBCardSubTitle className="mb-2">{position}</MDBCardSubTitle>
          <MDBCardText className="text-muted fs-6 text-start">
            {about}
          </MDBCardText>
        </MDBCardBody>

        <MDBFooter className="mb-4">
          <ul className="list-unstyled mb-0">
            <MDBBtn href={github} color="dark" target="_blank" rel="noreferrer">
              <AiFillGithub size="1.5rem" />
            </MDBBtn>
            <MDBBtn href={linkedin} target="_blank" rel="noreferrer">
              <GrLinkedinOption size="1.5rem" />
            </MDBBtn>
          </ul>
        </MDBFooter>
      </MDBCard>
    </div>
  );
}
