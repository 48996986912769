import './jobsContainer.scss';

import React, { useEffect, useState, useCallback } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useCurrentJobsDetailsStore } from '../stores/JobsGeneralStores';

import { Resizable } from 're-resizable';
import DetailsViewer from './DetailsViewer/DetailsViewer';

import { useDrawerWidthStore } from '../stores/drawerWidthStore';
import { useMainWidthStore } from '../stores/MainStores';

import { useObserveElementWidth } from 'src/hooks/Observer';
import { Typography } from '@mui/material';
import { useImageBackgroundColorsSetterStore } from '../stores/imageBackgroundColorsSetterStore';
let defaultDrawerWidth = '450px';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, margin }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: '-' + margin || -defaultDrawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function PersistentDrawerRight({ children, setMainRef }) {
  const theme = useTheme();

  const [resizeStopToggle, setResizeStopToggle] = useState(false);

  const { ref, width } = useObserveElementWidth();

  const drawerWidth = useDrawerWidthStore((state) => state.drawerWidth);
  const setDrawerWidth = useDrawerWidthStore((state) => state.setDrawerWidth);

  const setMainWidth = useMainWidthStore((state) => state.setMainWidth);

  const currentJobDetails = useCurrentJobsDetailsStore(
    (state) => state.currentJobDetails
  );

  const open = useCurrentJobsDetailsStore(
    (state) => state.open,
    (prev, next) => {
      return prev === next;
    }
  );
  const setOpen = useCurrentJobsDetailsStore((state) => state.setOpen);

  const { jobDetails, companyLogo } = currentJobDetails;

  // const colorPalette = useImageBackgroundColorsSetterStore(
  //   (state) => state.colorPalette
  // );

  // const avatarColors = useImageBackgroundColorsSetterStore(
  //   (state) => state.avatarColors
  // );

  const { colorPalette, avatarColors } = useImageBackgroundColorsSetterStore(
    (state) => ({
      colorPalette: state.colorPalette,
      avatarColors: state.avatarColors,
    })
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const getMainWidth = useCallback(() => {
    setMainWidth(width);
  }, [setMainWidth, width]);

  const handleDrawerClose = () => {
    setOpen(false);
    getMainWidth();
  };

  const onResizeHandler = (e, direction, drawerRef, d) => {
    const newWidth = drawerRef.style.width;
    drawerRef.style.width = newWidth;
    setDrawerWidth(newWidth);
    setMainWidth(width);
  };

  useEffect(() => {
    // This function to when resizing the window
    const calculateNewDrawerWidth = () => {
      // Calculate the new width based on your logic
      // For example, you can set a maximum width or a percentage of the window width
      // You can adjust this calculation according to your specific requirements
      const windowWidth = window.innerWidth;
      const maxWidth = window.innerWidth / 2; // Maximum width for the drawer
      const currentDrawerWidth = drawerWidth.toString().split('px')[0]; // Width of the drawer
      const newWidth = Math.min(windowWidth - currentDrawerWidth, maxWidth);

      getMainWidth();

      if (newWidth < 650) {
        setOpen(false);
      }
      return `${newWidth}px`;
    };

    const handleResize = () => {
      // Update the drawer width based on the new window width
      const newWidth = calculateNewDrawerWidth();
      setDrawerWidth(newWidth);
    };
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [drawerWidth, getMainWidth, setDrawerWidth, setMainWidth, setOpen]); // Empty dependency array to only run the effect once

  useEffect(() => {
    setTimeout(() => {
      setMainWidth(width);
    }, 200);
  }, [open, setMainWidth, ref, width]);

  useEffect(() => {
    if (ref.current) {
      setMainRef(ref);
    }
  }, [ref, setMainRef]);


  return (
    <Box
      id='jobs-container'
      sx={{ display: 'flex', hight: open ? '100%' : 'initial' }}
    >
      <CssBaseline />
      <Main
        margin={drawerWidth}
        open={open}
        className='jobs-container-main zIndex'
        id='jobs-container-main'
        sx={{
          overflow: open ? 'auto' : 'hidden',
          height: open ? 'calc(100vh - 10rem)' : 'initial',
          // height: 'initial',
          position: 'sticky',
          top: 0,
        }}
        ref={ref}
      >
        <DrawerHeader />
        {children}
      </Main>

      <Drawer
        className='drawer'
        id='drawer'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: 2,
          position: 'relative',
          '& .MuiDrawer-paper': {
            top: 'auto',
            width: drawerWidth,
            height: 'calc(100vh - 10rem)',
          },
        }}
        variant='persistent'
        anchor='right'
        open={open}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', zIndex: 150 }}>
          <Box
            className='Drawer-top-header-container'
            sx={{
              position: 'fixed',
              top: 'auto',
              width: drawerWidth,
              zIndex: 2,
              backgroundColor: 'background.paper',
            }}
          >
            <DrawerHeader
              className='drawer-top-header'
              sx={{
                backgroundColor:
                  colorPalette?.darkestColor || avatarColors?.darkestColor,
              }}
            >
              <Box className='drawer-top-header-subContainer'>
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{
                    borderRadius: '10px',
                  }}
                >
                  {theme.direction === 'rtl' ? (
                    <ChevronLeftIcon />
                  ) : (
                    // <ChevronRightIcon />
                    <Typography
                      sx={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        color:
                          colorPalette?.lightestColor ||
                          avatarColors?.lightestColor ||
                          'white',
                      }}
                    >
                      Close
                    </Typography>
                  )}
                </IconButton>
              </Box>

              {/* TODO add here some styling control */}
            </DrawerHeader>
            <Box sx={{ flexGrow: 1 }}>
              <Divider
                sx={{
                  width: '100%',
                  height: '1px',
                }}
              />
            </Box>
          </Box>

          <DetailsViewer
            jobsDetails={jobDetails}
            companyLogo={companyLogo}
          />
        </Box>

        <Resizable
          style={{
            position: 'fixed',
            top: 'auto',
            zIndex: '-150 !important',
          }}
          size={{ width: drawerWidth, height: '100%' }}
          minWidth={defaultDrawerWidth}
          maxWidth={window.innerWidth - 500}
          enable={{ top: false, left: true }}
          className='resizable'
          id='resizable'
          onResize={(e, direction, ref, d) => {
            onResizeHandler(e, direction, ref, d);
          }}
          onResizeStop={(e, direction, ref, d) => {
            setResizeStopToggle(!resizeStopToggle);
          }}
          handleClasses={{
            left: 'resize-left-handle',
          }}
        />
      </Drawer>
    </Box>
  );
}
