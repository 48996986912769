import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import Box from '@mui/material/Box';
import BackgroundLetterAvatars from 'src/components/LetterAvatar/LetterAvatar';
import Tags from './Tag';
import { Typography } from '@mui/material';
import randomColor from 'randomcolor';
import Color from 'color';
import { useSelectedLocationStore } from '../../stores/detailsViewerStore';
import { Stack } from '@mui/material';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { experimentalStyled as styled } from '@mui/material/styles';

import { useDrawerWidthStore } from '../../stores/drawerWidthStore';
import { useDetailsViewerStyleVariationsStore } from '../../stores/detailsViewerStyleVariationsStore';

import Section from './DetailsViewerComponent/Section';

import { useImageColors } from 'src/hooks/useImageColors';
import { useImageBackgroundColorsSetterStore } from '../../stores/imageBackgroundColorsSetterStore';

// const RegionLocationText = ({ children, title, location }) => {
//   return (
//     <Box
//       // mb={1}
//       display='flex'
//       gap={2}
//       className='region-location-text align-items-center'
//     >
//       <Typography
//         variant='p'
//         fontWeight={700}
//         fontSize='1.2rem'
//         display
//         sx={{
//           color: 'text.secondary',
//         }}
//       >
//         {title}:
//       </Typography>
//       <Typography
//         variant='subtitle1'
//         fontSize='1.2rem'
//       >
//         {location || 'N/A'}
//       </Typography>
//     </Box>
//   );
// };
// RegionLocationText

const RegionLocationText = React.memo(({ title, location }) => {
  return (
    <Box
      display='flex'
      gap={2}
      className='region-location-text align-items-center'
    >
      <Typography
        variant='p'
        fontWeight={700}
        fontSize='1.2rem'
        display
        sx={{ color: 'text.secondary' }}
      >
        {title}:
      </Typography>
      <Typography
        variant='subtitle1'
        fontSize='1.2rem'
      >
        {location || 'N/A'}
      </Typography>
    </Box>
  );
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// const Locations = ({ location }) => {
//   const drawerWidth = useDrawerWidthStore((state) =>
//     Number(state.drawerWidth.split('px')[0])
//   );

//   const [columns, setColumns] = useState(1);

//   // useEffect(() => {
//   //   if (drawerWidth < 650 && location.length > 1) {
//   //     setColumns(1);
//   //   } else if (drawerWidth < 950 && location.length > 1) {
//   //     setColumns(6);
//   //   } else if (drawerWidth > 1225 && location.length > 3) {
//   //     setColumns(12);
//   //   }
//   // }, [drawerWidth, location]);

//   // useEffect(() => {
//   //   let columns = 1;

//   //   if (drawerWidth < 650 && location.length > 1) {
//   //     columns = 1;
//   //   } else if (drawerWidth < 950 && drawerWidth > 651 && location.length > 1) {
//   //     columns = 6;
//   //   } else if (drawerWidth < 1225 && drawerWidth > 951 && location.length > 1) {
//   //     columns = 9;
//   //   } else if (drawerWidth > 1225 && location.length > 3) {
//   //     columns = 12;
//   //   }

//   //   setColumns(columns);
//   // }, [drawerWidth, location]);

//   return (
//     <Grid
//       container
//       sx={{
//         bgcolor: '#f8f8f8',
//         borderRadius: '10px',
//         boxShadow: 2,
//         // justifyContent: 'center',
//         width: 'fit-content',
//       }}
//     >
//       {location.map((location, idx) => (
//         <Grid
//           item
//           // xs={columns >= 1 ? 12 / columns : 12}
//           // sm={columns >= 6 ? 12 / columns : 6}
//           // md={columns >= 12 ? columns / 4 : 4}
//           // lg={columns >= 12 ? columns / 4 : 3}
//           key={idx}
//           sx={{
//             p: 2,
//             bgcolor: '#f8f8f8',
//           }}
//         >
//           <Box
//             sx={{
//               bgcolor: '#f8f8f8',
//               borderRadius: '10px',
//               boxShadow: 2,
//               p: 2,
//               height: '100%',
//             }}
//           >
//             <RegionLocationText
//               title='Location'
//               location={location?.ort}
//             />
//             <RegionLocationText
//               title='Post code'
//               location={location?.plz}
//             />
//             <RegionLocationText
//               title='Street'
//               location={location?.strasse}
//             />
//             <RegionLocationText
//               title='District'
//               location={location?.ortsteil}
//             />
//           </Box>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// Locations

const Locations = React.memo(({ location }) => {
  const drawerWidth = useDrawerWidthStore((state) =>
    Number(state.drawerWidth.split('px')[0])
  );
  const columns = useMemo(() => {
    if (drawerWidth < 650 && location.length > 1) return 1;
    else if (drawerWidth < 950 && location.length > 1) return 6;
    else if (drawerWidth < 1225 && location.length > 3) return 9;
    else if (drawerWidth > 1225 && location.length > 3) return 12;
    else return 1;
  }, [drawerWidth, location]);
  return (
    <Grid
      container
      sx={{
        bgcolor: '#f8f8f8',
        borderRadius: '10px',
        boxShadow: 2,
        width: 'fit-content',
      }}
    >
      {location.map((location, idx) => (
        <Grid
          item
          key={location.uuid}
          sx={{ p: 2, bgcolor: '#f8f8f8' }}
        >
          <Box
            sx={{
              bgcolor: '#f8f8f8',
              borderRadius: '10px',
              boxShadow: 2,
              p: 2,
              height: '100%',
            }}
          >
            <RegionLocationText
              title='Location'
              location={location?.ort}
            />
            <RegionLocationText
              title='Post code'
              location={location?.plz}
            />
            <RegionLocationText
              title='Street'
              location={location?.strasse}
            />
            <RegionLocationText
              title='District'
              location={location?.ortsteil}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
});

export default function Header({
  companyLogo,
  company,
  tags,
  subtitle,
  position,
  locations,
  companyWebsite,
  applyLink,
  otherTraining,
  scrollTop,
}) {
  const [companyWebsiteLink, setCompanyWebsiteLink] = useState('');
  const [locationArray, setLocationArray] = useState([]);
  const imageColors = useImageColors(companyLogo?.imageURL);
  const [imagesColorPalette, setImagesColorPalette] = useState([]);
  const setColors = useImageBackgroundColorsSetterStore(
    (state) => state.setColors
  );
  const scrollTriggerRef = useRef(null);
  const selectedLocation = useSelectedLocationStore(
    (state) => state.selectedLocation
  );
  const setSelectedLocation = useSelectedLocationStore(
    (state) => state.setSelectedLocation
  );
  const setBgColor = useDetailsViewerStyleVariationsStore(
    (state) => state.setBgColor
  );
  const currentLocationColor = randomColor({
    luminosity: 'dark',
    hue: 'random',
    seed: selectedLocation ? selectedLocation[0]?.region : 'Berlin',
  });

  const handleSelectedLocation = useCallback(
    (location) => {
      setSelectedLocation(location);
    },
    [setSelectedLocation]
  );

  useEffect(() => {
    const locationArrayMaker = () => {
      const updatedLocationArray = locations?.reduce((acc, location) => {
        const existingLocation = acc?.find(
          (loc) => loc.name === location.region
        );
        if (existingLocation) {
          existingLocation.count += 1;
          existingLocation.otherOptions = [
            ...existingLocation?.otherOptions,
            location,
          ];
        } else {
          acc.push({
            name: location.region,
            count: 1,
            color: randomColor({
              luminosity: 'dark',
              hue: 'random',
              seed: location.region,
            }),
            otherOptions: [location],
          });
        }
        return acc;
      }, []);
      return updatedLocationArray;
    };
    setLocationArray(locationArrayMaker());
  }, [locations]);

  useEffect(() => {
    if (!companyWebsite?.includes('http'))
      setCompanyWebsiteLink(`https://${companyWebsite}`);
    else setCompanyWebsiteLink(companyWebsite);
  }, [companyWebsite]);

  useEffect(() => {
    const setImageColors = async () => {
      if (imageColors) {
        const getDarkestColor = imageColors?.filter((c) =>
          Color(c).isDark()
        )[0];
        const getLightestColor = imageColors?.filter((c) =>
          Color(c).isLight()
        )[0];

        setImagesColorPalette([getDarkestColor, getLightestColor]);
      } else {
        setImagesColorPalette([]);
      }
    };
    setImageColors();
  }, [imageColors, companyLogo, setBgColor]);

  useEffect(() => {
    if (imageColors) {
      setColors(imageColors);
    }
  }, [companyLogo, imageColors, imagesColorPalette, setColors]);

  return (
    <Box
      className='header-container'
      mb={4}
      ref={scrollTriggerRef}
    >
      <Box
        className='details__viewer-image__container'
        sx={{
          transition: 'all 0.3s ease-in-out',
          // height: 'fit-content',
        }}
      >
        {companyLogo?.imageData ? (
          <Box
            component='img'
            sx={{
              // height: 233,
              objectFit: 'contain',
              height: { xs: 233, md: 167, lg: 233 },
              maxWidth: { xs: 350, md: 250, lg: '50%' },
            }}
            alt={company}
            src={companyLogo?.imageURL}
            title={`Company Logo - ${company}`}
          />
        ) : (
          <BackgroundLetterAvatars
            name={company}
            variant='circular'
            stringavatarstyle={{
              height: 233,
              width: 233,
              fontSize: '3rem',
            }}
            sx={{
              bgcolor: '#fff',
              height: 233,
              // width: '1 00%',
              // margin: '0 auto',
            }}
            enableBackground='true'
          />
        )}
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          className='details__viewer-link__container'
        >
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              textDecoration: 'none',
              width: 'fit-content',
              padding: '0.5rem 1rem',
              bgcolor: 'hotpink',
              color: '#fff',
              borderRadius: '5px',
              transition: 'all 0.3s ease-in-out',

              '&:hover': {
                bgcolor: Color('#ff69b4').darken(0.2).hex(),
                color: '#fff',
                textDecoration: 'none',
              },
            }}
            component={'a'}
            href={companyWebsiteLink}
            target='_blank'
          >
            Go to Website
          </Typography>

          {/* Links section */}
          {applyLink && (
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                textDecoration: 'none',
                width: 'fit-content',
                padding: '0.5rem 1rem',
                bgcolor: '#2f2f2f',
                color: '#fff',
                borderRadius: '5px',
                transition: 'all 0.3s ease-in-out',

                '&:hover': {
                  bgcolor: '#000',
                  color: '#fff',
                  textDecoration: 'none',
                },
              }}
              component={'a'}
              href={applyLink}
              target='_blank'
            >
              Check out the job
            </Typography>
          )}
        </Stack>
      </Box>
      <hr />

      <Box className='details__viewer-heading'>
        <Typography
          variant='h5'
          fontWeight={600}
        >
          {company}
        </Typography>
        <Typography variant='body1'>{subtitle}</Typography>
        <Typography variant='body1'>{position}</Typography>
      </Box>

      <Box
        className='tags-container'
        sx={{
          textAlign: 'left',
          bgcolor: 'background.paper',
          color: 'text.secondary',
          mt: 4,
          p: 2,
        }}
      >
        <Stack
          direction='row'
          gap={2}
          flexWrap='wrap'
          alignContent='stretch'
        >
          {tags?.workedType && (
            <Section className='tag-workType-container'>
              <Typography
                variant='h6'
                fontWeight={600}
                mb={1}
              >
                Working Time:
              </Typography>

              <Tags
                mode='drawer'
                title='workType'
                chips={tags?.workedType}
                bgcolor='#000'
                classReset
              />
            </Section>
          )}

          {/* {tags?.language?.length > 0 && ( */}
          <Section className='language-tags-container'>
            <Typography
              variant='h6'
              textAlign='start'
              fontWeight={600}
              mb={1}
            >
              Language
              {tags?.language?.length > 1 ? 's' : ''}:
            </Typography>
            <Tags
              mode='drawer'
              title='language'
              chips={tags?.language || ['Deutsch']}
              classReset
            />
          </Section>
          {/* )} */}

          {tags?.workingHoursInfo?.length && (
            <Section className='weeklyWorkingHours-tags-container'>
              <Typography
                variant='h6'
                textAlign='start'
                fontWeight={600}
                mb={1}
              >
                Weekly Working Hours:
              </Typography>
              <Tags
                mode='drawer'
                title='Weekly Working Hours'
                itemComponent='div'
                chips={tags?.workingHoursInfo}
                classReset
                bgcolor={Color('#f2f2f2').darken(0.7).hex()}
                itemStyle={{
                  textAlign: 'start',
                  p: 2,
                  lineHeight: '1.5rem',
                }}
                badgeStyle={{
                  width: 'fit-content',
                }}
              />
            </Section>
          )}

          {tags?.otherTraining?.length && (
            <Section className='otherTraining-tags-container'>
              <Typography
                variant='h6'
                textAlign='start'
                fontWeight={600}
                mb={1}
              >
                Other Training:
              </Typography>
              <Tags
                mode='drawer'
                title='Other Training'
                itemComponent='div'
                chips={
                  Array.isArray(tags?.otherTraining)
                    ? tags?.otherTraining
                    : [tags?.otherTraining]
                }
                classReset
                // bgcolor={Color('#f2f2f2').darken(0.8).hex()}
                bgcolor={Color('#d3d3d3').darken(0.8).hex()}
                itemStyle={{
                  textAlign: 'start',
                  p: 2,
                  lineHeight: '1.5rem',
                }}
                badgeStyle={{
                  width: 'fit-content',
                }}
              />
            </Section>
          )}
        </Stack>

        {/* End of Tags */}

        <Section
          className='tag-location-container'
          mt={2}
        >
          <Typography
            variant='h6'
            fontWeight={600}
            mb={1}
          >
            Location{locations?.length > 1 ? 's' : ''}:
          </Typography>

          <Tags
            mode='drawer'
            title='location'
            chips={locationArray}
            classReset
            isLocation
            // selectedLocation={selectedLocation}
            randomBgColor
            handleSelectedLocation={handleSelectedLocation}
          />

          {selectedLocation && (
            <Section
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 0,
              }}
            >
              <Typography
                variant='h6'
                fontWeight={600}
                sx={{
                  // bgcolor: 'background.paper',
                  bgcolor: '#f8f8f8',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                  boxShadow: 2,
                  color: currentLocationColor || '#000',
                  p: 2,
                }}
              >
                {selectedLocation[0]?.region}
              </Typography>
              <Locations location={selectedLocation} />
            </Section>
          )}
        </Section>
      </Box>
    </Box>
  );
}
