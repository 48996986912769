import React, { useState, useMemo, useEffect } from 'react';
import { useRoutes, useLocation, Routes, Route } from 'react-router-dom';
import Unknown from './components/Unknown';
import LandingPage from './components/LandingPage/LandingPage';
import AddCard from './components/Card/CardComponents/AddJobCard/AddCardInfo';
import AddCardModal from './components/Card/CardComponents/AddCardModal';
import Dashboard from './components/Dashboard/Dashboard';
import Contacts from './components/Card/CardComponents/AddJobCard/Contacts/Contacts';
import Login from './components/Login/Login';
import GLogin from './components/Login/GLogin';
import Profile from './components/Profile/Profile';
import EditProfile from './components/Profile/EditProfile';
import { AnimatePresence, motion } from 'framer-motion';
import About from './components/About/About';
import Error from './components/Error/Error';

import Jobs from './components/Jobs/Jobs';

export default function Routers() {
  const location = useLocation();

  const [key, setKey] = useState(undefined);

  useMemo(() => {
    if (location?.pathname === '/login' || location?.pathname === '/register') {
      setKey('login-register');
    } else {
      setKey(location?.pathname);
    }
  }, [location?.pathname]);

  const routes = useRoutes([
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
      children: [
        { path: 'addcard', element: <AddCard /> },
        { path: 'addcardmodal', element: <AddCardModal /> },
        { path: 'contacts', element: <Contacts /> },
      ],
    },
    {
      path: '/glogin/:id',
      element: <GLogin />,
    },
    {
      path: '/profile',
      element: <Profile />,
      children: [{ path: 'editProfile', element: <EditProfile /> }],
    },
    {
      path: '/about',
      element: <About />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/register',
      element: <Login />,
    },
    {
      path: '/jobs-search',
      element: <Jobs />,
      // children: [
      //   { path: 'jobSearch', element: <JobSearch /> },
      //   { path: 'jobSearch/:id', element: <JobSearch /> },
      // ],
    },

    {
      path: '/error',
      element: <Error />,
    },
    {
      path: '*',
      element: <Unknown />,
    },
  ]);

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter
    >
      <motion.div
        className='h-100'
        key={key}
      >
        {routes}
      </motion.div>
    </AnimatePresence>
  );
}
