import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function InputSection({ label, icon, func, value }) {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{icon}</InputAdornment>
        ),
      }}
      id="standard-basic"
      label={label}
      value={value}
      variant="standard"
      onChange={(e) => func(e.target.value)}
    />
  );
}
