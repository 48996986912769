import './jobs.scss';

import React, { useEffect, useState, useContext, useRef } from 'react';
import Loading from 'src/components/Loading/Loading';

import { NextContext } from 'src/components/Context';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { motion } from 'framer-motion';

import SearchJobsForm from './Components/SearchJobsForm';

import CssBaseline from '@mui/material/CssBaseline';

import parse from 'html-react-parser';

import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack';

import { useJobsStore } from 'src/stores/jobStore';
import { shallow } from 'zustand/shallow';
import JobsContainer from './Components/JobsContainer';
import { useCurrentJobsDetailsStore } from './stores/JobsGeneralStores';

import NoJobsFound from './Components/NoJobsFound';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useMainWidthStore } from './stores/MainStores';

import { debounce } from 'lodash';

import JobsCardsGridContainer from './Components/JobsCardsGridContainer';
import useFetchAndHandleJobs from 'src/hooks/useFetchAndHandleJobs';

import { useImageBackgroundColorsSetterStore } from './stores/imageBackgroundColorsSetterStore';
import Color from 'color';
import { colors } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
}));

const PaperContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'inherit',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0),
  boxShadow: 'none',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  // height: '100%',
}));

const GRID_ITEM_PREFIX = 'grid-item-';

export default function Jobs({ containerRef }) {
  const { setLoadingMessage } = useContext(NextContext);
  const scrollDebouncedRef = useRef(null);

  const {
    title,
    currentJobs,
    message,
    itemsPerPage,
    page,
    totalJobs,
    loading,
    // error,
    // maxResults,
    isJobsInitialized,
  } = useJobsStore(
    (state) => ({
      ...state,
    }),
    shallow
  );
  // const cardRef = useRef();

  const isMobile = useMediaQuery('(max-width:600px)');

  const { timer, maxResults, error } = useFetchAndHandleJobs();

  const mainWidth = useMainWidthStore((state) => state.mainWidth);

  const [gridColumns, setGridColumns] = useState({
    columns: 4,
    gridResponsive: 'md',
  });

  const { columns, gridResponsive } = gridColumns;

  // const containerRef = useRef(null);
  const [mainRef, setMainRef] = useState(null);

  // const [loading, setLoading] = useState(true);

  const setItemsPerPage = useJobsStore((state) => state.setItemsPerPage);
  const setPage = useJobsStore((state) => state.setPage);

  // set the current job details for the details viewer
  const open = useCurrentJobsDetailsStore((state) => state.open);
  const setCurrentJobDetails = useCurrentJobsDetailsStore(
    (state) => state.setCurrentJobDetails
  );
  const setOpen = useCurrentJobsDetailsStore((state) => state.setOpen);

  const [currentIndex, setCurrentIndex] = useState(0);

  /**
   * @function handlePageChange
   * @description this function is to fire when the page changes
   * @param {object} event: React.MouseEvent<HTMLButtonElement> | null
   * @param {number} page: number
   * @returns {void}
   * @info event: The event source of the callback.
   *       page: The page selected.
   */
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
  };

  useEffect(() => {
    setLoadingMessage(message);
    setPage(0);
  }, [message, setLoadingMessage, setPage]);

  useEffect(() => {
    const resetSelectedCard = () => {
      setCurrentIndex(0);
      setCurrentJobDetails({
        ...currentJobs[0],
        companyLogo: currentJobs[0]?.image,
      });
    };
    if (currentJobs?.length > 0) {
      resetSelectedCard();
    }
  }, [currentJobs, page, setCurrentJobDetails]);

  useEffect(() => {
    const GRID_CONFIGS = [
      { breakpoint: 1501, gridResponsive: 'xl', columns: 3 },
      { breakpoint: 1101, gridResponsive: 'lg', columns: 4 },
      { breakpoint: 951, gridResponsive: 'md', columns: 4 },
      { breakpoint: 751, gridResponsive: 'sm', columns: 6 },
      { breakpoint: 0, gridResponsive: 'xs', columns: 12 },
    ];
    const gridConfig = GRID_CONFIGS.find(
      (config) => mainWidth >= config.breakpoint
    );

    if (gridConfig) {
      const { gridResponsive, columns } = gridConfig;
      setGridColumns({ columns, gridResponsive });
    }
  }, [mainWidth]);

  useEffect(() => {
    if (currentIndex !== null && mainRef && open) {
      const card = document.querySelector(
        `#${GRID_ITEM_PREFIX + currentIndex}`
      );

      if (card) {
        const container = mainRef.current;
        const cardRect = card.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const offset =
          cardRect.top -
          containerRect.top -
          container.clientHeight / 2 +
          card.clientHeight / 2;

        container.scrollTo({
          top: container.scrollTop + offset,
          behavior: 'smooth',
        });
      } else {
        console.error(`Card with index ${currentIndex} not found.`);
      }
    }
  }, [currentIndex, currentJobs, mainRef]);

  return (
    <JobsContainer setMainRef={setMainRef}>
      <Box
        key={'JobsContainer'}
        sx={{ flexGrow: 1 }}
      >
        <CssBaseline />
        <SearchJobsForm />
      </Box>
      {loading && (
        <Loading
          open={loading}
          close={!loading}
        />
      )}
      {!loading && (
        <>
          {maxResults === 0 && (isJobsInitialized || error) && (
            <NoJobsFound
              title={parse(title)}
              timer={timer}
            />
          )}
          {maxResults > 0 && (
            <PaperContainer key='paperContainer'>
              <Typography
                component='h1'
                variant='h2'
                sx={{
                  mt: 5,
                  mb: 2,
                  width: '100%',
                  transition: 'width 0.7s ease-in-out',
                }}
              >
                {parse(title)}
              </Typography>
              <Box
                key={'JobsCardsContainer'}
                sx={{ flexGrow: 1, position: 'relative' }}
              >
                <Stack
                  key='cards-stack'
                  spacing={1}
                >
                  <Item
                    key='pagination'
                    className='zIndex'
                    sx={{
                      ml: 'auto',
                      width: 'fit-content',
                      height: 'fit-content',
                    }}
                  >
                    <TablePagination
                      count={totalJobs}
                      size='large'
                      color='secondary'
                      page={page}
                      rowsPerPage={itemsPerPage}
                      rowsPerPageOptions={[12, 24, 48, 100]}
                      component='div'
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                    />
                  </Item>

                  <Item
                    key='cards'
                    component={motion.div}
                    animate={{ width: !open ? '100%' : '100%' }}
                    transition={{ duration: 0.2 }}
                    sx={{
                      p: 2,
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      margin: 'auto !important',
                    }}
                  >
                    <JobsCardsGridContainer
                      className='jobsCardsGridContainer'
                      currentJobs={currentJobs}
                      gridResponsive={gridResponsive}
                      columns={columns}
                      open={open}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      setCurrentJobDetails={setCurrentJobDetails}
                      setOpen={setOpen}
                      isMobile={isMobile}
                    />
                  </Item>

                  <Item
                    key='pagination-bottom'
                    className='zIndex'
                    sx={{
                      ml: 'auto !important',
                      mb: !open && '10rem !important',
                      width: 'fit-content',
                      height: 'fit-content',
                    }}
                  >
                    <TablePagination
                      count={totalJobs}
                      size='large'
                      color='secondary'
                      page={page}
                      rowsPerPage={itemsPerPage}
                      rowsPerPageOptions={[12, 24, 48, 100]}
                      component='div'
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                    />
                  </Item>
                </Stack>
              </Box>
              {/* </JobsContainer> */}
            </PaperContainer>
          )}
        </>
      )}
    </JobsContainer>
  );
}
