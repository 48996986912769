import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { NextContext } from "../../../../Context";

const AddTodo = () => {
  const { todo, setTodo, jobCard, setJobCard } = useContext(NextContext);
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);

  // ADD TODO
  const handleTodo = (e) => {
    e.preventDefault();

    if (!todo) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return;
    }

    // NEW TO DO
    const newTodo = {
      id: new Date().getTime(),
      completed: false,
      text: todo,
      date: Date.now(),
    };

    const oldData = [...jobCard["toDoList"]];
    oldData.push(newTodo);
    setJobCard({ ...jobCard, toDoList: oldData });
    setTodo("");
  };

  return (
    <Form className="w-100 mt-4">
      <Row className="addTodo m-0 p-0 ">
        <Col>
          <TextField
            id="standard-basic"
            label="Enter a task"
            variant="standard"
            className="inputbox w-100 "
            maxLength="50"
            onChange={(e) => setTodo(e.target.value)}
            value={todo}
          />
        </Col>
        <Col>
          <Button
            className="addTaskButton "
            onClick={handleTodo}
            variant="contained"
          >
            Add Task
          </Button>
        </Col>
      </Row>
      <Row>
        <Alert show={showAlert} variant="warning">
          You Need To Enter At Least A Task!
        </Alert>
      </Row>
    </Form>
  );
};

export default AddTodo;
