import React, { useContext } from "react";
import { Pie, Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import "chart.js/auto";
import { NextContext } from "../../Context";
Chart.register(ArcElement);

export default function Metrics() {
  const { allPosts } = useContext(NextContext);
  const state = {
    labels: ["Wishlist", "Applied", "Interview", "Offered", "Rejected"],
    datasets: [
      {
        label: "Rainfall",
        backgroundColor: [
          "#B21F00",
          "#C9DE00",
          "#2FDE00",
          "#00A6B4",
          "#6800B4",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
        ],
        data: [
          allPosts?.filter((item) => item.status === "wishlist").length,
          allPosts?.filter((item) => item.status === "applied").length,
          allPosts?.filter((item) => item.status === "interview").length,
          allPosts?.filter((item) => item.status === "offer").length,
          allPosts?.filter((item) => item.status === "rejected").length,
        ],
      },
    ],
  };

  const lengthOfActivity = state?.datasets[0]?.data?.reduce((a, b) => a + b, 0);
  return (
    <div style={{ width: "600px", height: "600px", marginLeft: " 600px" }}>
      <div
        style={{ fontSize: "40px", textAlign: "center", marginBottom: "10%" }}
      >
        {" "}
        JOB SEARCH ACTIVITY BOARD
      </div>
      <Pie
        data={state}
        options={{
          title: {
            display: true,
            text: "JOB SEARCH ACTIVITY BOARD",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
      <div
        style={{
          textAlign: "end",
          fontSize: "20px",
          letterSpacing: "2px",
          marginTop: "20px",
        }}
      >
        Total Applications = " {lengthOfActivity} "
      </div>
    </div>
  );
}
