import React from "react";
import cx from "clsx";
import Box from "@mui/material/Box";
import { FlexRow, Item } from "@mui-treasury/component-flex";

import Color from "color"; // v3.2.1
import { styled } from "@mui/material/styles";
// import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";

// icon
import { UserEdit } from "@styled-icons/fa-solid/UserEdit";

// avatar component
import Avatar, { genConfig } from "react-nice-avatar";

import { motion } from "framer-motion";

const defaultColor = "#747f84";

export default function CustomCard({
  color,
  cover,
  title,
  brand,
  linkedin,
  website,
  func,
  avatar,
  phone,
  email
}) {
  // 1. Add the myConfig to the jobCard -> Contacts -> currentContact
  // 2. Pass currentContact.myConfig as a prop to Custom Card.
  // 3. spread the curretnContact.myConfig in the Component.

  // const config = {
  //   "sex": "man",

  //   // "bgColor": "linear-gradient(45deg, #1729ff 0%, #ff56f7 100%)"
  //   "bgColor":  `linear-gradient(to top, ${color}, ${Color(color)
  //     .rotate(24)
  //     .lighten(0.8)}
  //       )`
  // }

  // const myConfig = genConfig(config)

  const StyledRoot = styled("div")(({ color = defaultColor }) => ({
    position: "relative",
    borderRadius: "1rem",
    paddingTop: 50,
    // marginTop: 50,
    "& a": {
      color: "white",
    },
    "&:before": {
      transition: "0.2s",
      position: "absolute",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      borderRadius: "1rem",
      zIndex: 0,
      bottom: 0,
      backgroundColor: Color(color).darken(0.3).desaturate(0.2).string(),
    },
    "&:hover": {
      "&:before": {
        bottom: -6,
      },
      "& .MuiAvatar-root": {
        transform: "scale(1.1)",
        boxShadow: "0 6px 20px 0 rgba(0,0,0,0.38)",
      },
    },
  }));

  const CardMediaCover = styled(CardMedia)(() => ({
    borderRadius: "1rem",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    backgroundPosition: "center",
  }));

  const StyledH2 = styled("h2")(() => ({
    // fontFamily: "Fjalla One",
    fontWeight: "bold",
    fontSize: "1.2rem",
    color: "#FFFFFF",
    // background: "#333333",
    textShadow: `0 -1px 4px ${Color(color)
      .darken(0.7)
      .desaturate(0.2)
      .lighten(0.5)
      .string()}, 0 -2px 10px ${Color(color)
      .darken(1.7)
      .desaturate(0.2)
      .lighten(0.5)
      .string()}, 0 -10px 20px ${Color(color)
      .darken(0.7)
      .desaturate(0.2)
      .lighten(1.5)
      .string()}, 0 -18px 40px ${Color(color)
      .darken(0.7)
      .desaturate(0.2)
      .lighten(2.5)
      .string()},
      #FF2D95 0px 0px 50px, #FF2D95 0px 0px 75px
      `,
    fontWeight: "bold",
    margin: 0,
  }));

  const StyledContent = styled("div")(({ color = defaultColor }) => ({
    position: "relative",
    zIndex: 1,
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: `0 6px 16px 0 ${Color(color).fade(0.5)}`,
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      top: 1,
      zIndex: 0,
      width: "100%",
      height: "100%",
      clipPath:
        "polygon(0% 100%, 0% 35%, 0.3% 33%, 1% 31%, 1.5% 30%, 2% 29%, 2.5% 28.4%, 3% 27.9%, 3.3% 27.6%, 5% 27%,95% 0%,100% 0%, 100% 100%)",
      borderRadius: "1rem",
      background: `linear-gradient(to top, ${color}, ${Color(color)
        .rotate(24)
        .lighten(0.8)}
          )`,
    },
  }));

  const AvatarLogo = styled(Avatar)(() => ({
    transition: "0.3s",
    width: 100,
    height: 100,
    boxShadow: "0 4px 12px 0 rgba(0,0,0,0.24)",
    borderRadius: "1rem",
  }));

  const StyledDivTeam = styled("div")(({ theme }) => ({
    fontSize: "1.1rem",
    color: "#FFFFFF",
    textShadow: `0 -1px 4px ${Color(color)
      .darken(0.7)
      .desaturate(0.2)
      .lighten(0.5)
      .string()}, 0 -2px 10px ${Color(color)
      .darken(1.7)
      .desaturate(0.2)
      .lighten(0.5)
      .string()}, 0 -10px 20px ${Color(color)
      .darken(0.7)
      .desaturate(0.2)
      .lighten(1.5)
      .string()}, 0 -18px 40px ${Color(color)
      .darken(0.7)
      .desaturate(0.2)
      .lighten(2.5)
      .string()}`,
    fontWeight: "bold",
    "&  a:hover": {
      color: "#fff",
    },
  }));

  const StyledDivIcon = styled("div")(({ theme }) => ({
    color: "#fff",
    // backgroundColor: theme.palette.text.disabled,
    background: Color(color).darken(0.5).desaturate(0.5).lighten(0.9).string(),
    opacity: 0.72,
    fontSize: "1rem",
    padding: "0.2rem",
    margin: 2,
    borderRadius: 8,
    fontWeight: "bold",
    "&:hover": {
      background: Color(color)
        .darken(1.5)
        .desaturate(0.5)
        .lighten(0.9)
        .string(),
    },
  }));

  return (
    <motion.div
     
    >
      <StyledRoot color={color}>
        <CardMediaCover image={cover} />
        <StyledContent color={color}>
          <UserEdit
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 50,
              height: 50,
              zIndex: 100,
              cursor: "pointer",
              color: Color(color)
                .darken(0.7)
                .desaturate(0.2)
                .lighten(0.5)
                .string(),
            }}
            onClick={func}
          />
          <Box position={"relative"} zIndex={1}>
            <FlexRow p={0}>
              <Item>
                <AvatarLogo {...avatar} />
              </Item>
              <Item alignSelf="flex-end">
                <StyledH2>{title}</StyledH2>
              </Item>
            </FlexRow>
            <FlexRow mt={4} alignItems={"center"}>
              <Item>
                <StyledDivTeam>{brand}</StyledDivTeam>
              </Item>
              <Item ml="auto">
                <StyledDivIcon>{linkedin}</StyledDivIcon>
              </Item>
              <Item ml="">
                <StyledDivIcon>{website}</StyledDivIcon>
              </Item>
              <Item ml="">
                <StyledDivIcon>{phone}</StyledDivIcon>
              </Item>
              <Item ml="">
                <StyledDivIcon>{email}</StyledDivIcon>
              </Item>
            </FlexRow>
          </Box>
        </StyledContent>
      </StyledRoot>
    </motion.div>
  );
}
