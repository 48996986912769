import React, { useState, useEffect } from 'react';

// import material ui components
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';

// imports motions
import { motion } from 'framer-motion';
import axios from 'axios';

// zustand
import { useJobsStore } from 'src/stores/jobStore';
import { useCurrentJobsDetailsStore } from '../stores/JobsGeneralStores';

// ----------------------------------------------
// Menu Props

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function JobSearch({ setCurrentPageJobs }) {
  // const [workingHours, setWorkingHours] = useState([]);

  // const { fetchJobsHandler, isJobsInitialized } = useJobsStore((state) => ({
  //   fetchJobsHandler: state.fetchJobsHandler,
  //   isJobsInitialized: state.isJobsInitialized,
  // }));

  const fetchJobsHandler = useJobsStore((state) => state.fetchJobsHandler);
  const isJobsInitialized = useJobsStore((state) => state.isJobsInitialized);

  const setOpen = useCurrentJobsDetailsStore((state) => state.setOpen);

  const [query, setQuery] = useState({
    what: '',
    where: 'Berlin',
    radius: '25',
    offerType: '',
    page: '1',
    pav: 'false',
    size: '100',
    workingHours: [],
  });

  const [workingHoursWithSymbols, setWorkingHoursWithSymbols] = useState({
    vz: 'Full Time',
    tz: 'Part Time',
    snw: 'Weekend Night Shift',
    ho: 'Home Office',
    mj: 'Mini Job',
  });

  const deleteSelectedWorkingHours = (workingHours, idx) => {
    const workingHoursWithSymbolsCopy = { ...workingHoursWithSymbols };
    workingHoursWithSymbolsCopy[workingHours] =
      workingHoursWithSymbols[workingHours];
    setWorkingHoursWithSymbols(workingHoursWithSymbolsCopy);

    const newWorkingHours = [...query?.workingHours];
    newWorkingHours.splice(idx, 1);
    setQuery({ ...query, workingHours: newWorkingHours });
  };

  const handleAISummary = () => {
    const response = axios
      .get('/ai/summary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify({ query }),
        body: 'THIS IS A TEST',
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOpen(true);
      });

    console.log(response);
  };

  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === 'Enter' && document.activeElement.id.includes('search')) {
        fetchJobsHandler(query);
      }
    };
    document.addEventListener('keydown', handleEnterKey);

    return () => {
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [fetchJobsHandler, query]);

  useEffect(() => {
    // fetchJobsHandler(query);
    if (!isJobsInitialized) {
      fetchJobsHandler(query);
    }
  }, [fetchJobsHandler, isJobsInitialized, query]);

  return (
    <Container
      fixed
      key='options-forms'
      component={motion.form}
      initial={{ scaleX: 0 }}
      animate={{ scaleX: 1 }}
      exit={{ scaleX: 0 }}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      // noValidate
      autoComplete='on'
    >
      <Paper
        elevation={0}
        sx={{ p: 2, backgroundColor: 'transparent' }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            // style={requiredData && jobCard?.company === '' ? { borderBottom: '1px solid red' } : null}
            required
            id='search-what'
            label='What'
            variant='standard'
            type='text'
            placeholder='Enter Job Title'
            // value={jobCard.company}
            // onChange={(e) => setJobCard({ ...jobCard, company: e.target.value })}
            onChange={(e) => setQuery({ ...query, what: e.target.value })}
          />
          <TextField
            // style={requiredData && jobCard?.title === '' ? { borderBottom: '1px solid red' } : null}
            id='search-where'
            label='Where'
            required
            variant='standard'
            type='text'
            placeholder='Enter Job Location'
            // value={jobCard?.title}
            // onChange={(e) => setJobCard({ ...jobCard, title: e.target.value })}
            onChange={(e) => setQuery({ ...query, where: e.target.value })}
          />
          <TextField
            id='search-radius'
            label='Radius'
            variant='standard'
            type='text'
            placeholder='Enter radius'
            // value={jobCard.link}
            // onChange={(e) => setJobCard({ ...jobCard, link: e.target.value })}
            onChange={(e) => setQuery({ ...query, radius: e.target.value })}
          />

          <TextField
            id='search-salary'
            label='Salary'
            variant='standard'
            type='text'
            placeholder='Enter salary'
            // value={jobCard.salary}
            // onChange={(e) => setJobCard({ ...jobCard, salary: e.target.value })}
            onChange={(e) => setQuery({ ...query, salary: e.target.value })}
          />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id='select-multiple-chip'>Working Hours</InputLabel>
            <Select
              id='working-hours'
              labelId='working-hours-label'
              multiple
              displayEmpty
              label='Working Hours'
              value={query?.workingHours}
              onChange={(e) => {
                setQuery({ ...query, workingHours: e.target.value });
              }}
              input={<OutlinedInput label='Working Hours' />}
              MenuProps={MenuProps}
              variant='standard'
              renderValue={(selected, idx) => {
                // if (selected?.length === 0) {
                //   // return <em>Working Hours</em>;
                // }

                // get all selected values from the workingHoursWithSymbols object
                const selectedValues = selected?.map((item) => {
                  if (selected?.length >= 2) {
                    return workingHoursWithSymbols[item] + ', ';
                  }

                  return workingHoursWithSymbols[item];
                });

                return selectedValues;
              }}
            >
              {Object.keys(workingHoursWithSymbols)?.map((key) => (
                <MenuItem
                  key={key}
                  value={key}
                >
                  <Checkbox checked={query?.workingHours?.indexOf(key) > -1} />
                  {workingHoursWithSymbols[key]}
                  <ListItemText
                    onClick={(e) => {
                      // remove the selected value from the workingHoursWithSymbols object
                      const workingHoursWithSymbolsCopy = {
                        ...workingHoursWithSymbols,
                      };
                      delete workingHoursWithSymbolsCopy[e.target.value];

                      setWorkingHoursWithSymbols(workingHoursWithSymbolsCopy);
                    }}
                    // primary={key}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Divider />

          <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: 320 }}>
            {query?.workingHours?.map((value, idx) => (
              <Chip
                key={value + idx}
                // className='zIndex'
                sx={{ cursor: 'pointer', zIndex: 10 }}
                label={workingHoursWithSymbols[value]}
                onDelete={() => {
                  // add the deleted value back to the workingHoursWithSymbols list
                  deleteSelectedWorkingHours([value], idx);
                }}
                onClick={() => {
                  deleteSelectedWorkingHours([value], idx);
                }}
              />
            ))}
          </Box>
        </Box>
        <Button
          variant='contained'
          onClick={(e) => {
            fetchJobsHandler(query);
            setOpen(false);
          }}
          sx={{
            mt: '1em',
            p: '1em',
            backgroundColor: '#171717',
            color: 'white',
            '&:hover': { backgroundColor: 'black' },
          }}
        >
          Search
        </Button>
      </Paper>
    </Container>
  );
}
