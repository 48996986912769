import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

const AddSpeedDial = ({
  statusCol,
  setCurrentCardData,
  setJobCard,
  jobCard,
}) => {
  const handleClick = () => {
    setCurrentCardData({ show: true });
    setJobCard({ ...jobCard, status: statusCol.toLowerCase() });
  };

  return (
    <SpeedDial
      className='addJob-button'
      ariaLabel='SpeedDial basic example'
      onClick={handleClick}
      sx={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& button ': {
          background: '#262626',
          borderRadius: '.25rem!important',
        },
        '& button:hover': {
          background: '#6d5295',
        },
      }}
      icon={<SpeedDialIcon />}
    />
  );
};

export default AddSpeedDial;