import React from 'react';
import Box from '@mui/material/Box';
import { useDetailsViewerStyleVariationsStore } from 'src/components/Jobs/stores/detailsViewerStyleVariationsStore';

export default function Section({ children, className, sx, ...res }) {
  const bgColor = useDetailsViewerStyleVariationsStore(
    (state) => state.bgColor
  );
  return (
    <>
      {children ? (
        <Box
          {...res}
          boxShadow={2}
          className={className}
          sx={{
            bgcolor: bgColor,
            borderRadius: '10px',
            p: 2,
            ...sx,
            // flexGrow: 1,
            flexWrap: 'wrap',
            // height: '100%',
            height: 'fit-content',
          }}
        >
          {children}
        </Box>
      ) : null}
    </>
  );
}
