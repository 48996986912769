import { useContext } from "react";
import { NextContext } from "../../../../Context";
import "./notes.scss";

const AddNote = ({ handleAddNote }) => {
  const { note, setNote } = useContext(NextContext);

  const handleSaveClick = () => {
    if (note.trim()?.length) {
      handleAddNote(note);
      setNote("");
    }
  };

  return (
    <div className="notenew">
      <textarea
        className="textareaNotes"
        rows="5"
        cols="10"
        placeholder="Type to add a note..."
        value={note}
        maxLength="100"
        onChange={(e) => setNote(e.target.value)}
      ></textarea>
      <div className="note-footer">
        <button className="save" onClick={handleSaveClick}>
          Add
        </button>
      </div>
    </div>
  );
};

export default AddNote;
