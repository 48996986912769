import React, { useContext, useState, useEffect } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import "./Todo.scss";
import { MdDeleteForever } from "react-icons/md";
import moment from "moment";
import { Button, TextField } from "@mui/material";
import { NextContext } from "../../../../Context";
import SingleTodo from "./SingleTodo";
import AddTodo from "./AddTodo";
import PreparedTodos from "./PreparedTodos";
import UncompletedTodosList from "./UncompletedTodosList";
import CompletedTodosList from "./CompletedTodosList";

const ToDo = () => {
  const { todo, setTodo, jobCard, setJobCard } = useContext(NextContext);

  const [tasksLength, setTasksLength] = useState(null);
  const [competedTasksLength, setCompletedTasksLength] = useState(null);
  // SHOW/HIDE TASKS LISTS
  useEffect(() => {
    setTasksLength(
      jobCard?.toDoList?.filter((mylist) => mylist.completed === false).length
    );
    setCompletedTasksLength(
      jobCard?.toDoList?.filter((mylist) => mylist.completed === true).length
    );
  }, [jobCard?.toDoList]);

  return (
    <div className="todo d-flex flex-column justify-content-center align-items-center ">
      <AddTodo />
      <PreparedTodos />
      <hr className="w-100" />
      <UncompletedTodosList />
      <hr
        style={{ visibility: competedTasksLength > 0 ? "visible" : "hidden" }}
        className="w-100"
      />
      <h6
        style={{ visibility: competedTasksLength > 0 ? "visible" : "hidden" }}
        className="completedTasksTitle m-0"
      >
        Completed
      </h6>
      <CompletedTodosList />
    </div>
  );
};

export default ToDo;
