/* eslint-disable react-hooks/exhaustive-deps */
import './card.scss';
import React, { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  CardHeader,
} from '@mui/material';
import { customLengthSlicer } from 'src/utils/usefullFunctions';
import BackgroundLetterAvatars from 'src/components/LetterAvatar/LetterAvatar';
import Zoom from '@mui/material/Zoom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useImageBackgroundColorsSetterStore } from '../stores/imageBackgroundColorsSetterStore';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardActions: {
    boxShadow: '0px 2px 11px 0px rgb(0 0 0 / 38%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    width: '100%',
  },
  typography: {
    fontSize: '0.8rem',
  },
  boldTypography: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
}));

// import BufferToImage from './BufferToImage';

// TODO: Make this component more reusable
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f8f8f8',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme?.typography?.pxToRem(15),
    border: '1px solid #dadde9',
    whiteSpace: 'pre-wrap',
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
}));

const TypographyWithTitle = ({
  titleProps,
  contentProps,
  containerClassName = '',
}) => {
  return (
    <Typography
      variant='body2'
      component='div'
      color='text.secondary'
      size='small'
      className={containerClassName}
    >
      <span className={titleProps?.className || ''}>{titleProps?.text}</span>{' '}
      <span className={contentProps?.className || ''}>{contentProps?.text}</span>
    </Typography>
  );
};

export default function JobCard2({
  job,
  companyLogo,
  className,
  initialJobDetails,
  setCurrentJobDetails,
  currentIndex,
  open,
  setOpen,
  setCurrentIndex,
  children,
  ...rest
}) {
  const length = 40;
  const classes = useStyles();

  const { jobDetails } = initialJobDetails;
  const { index } = initialJobDetails;

  const toggleCardClick = useImageBackgroundColorsSetterStore(
    (state) => state.toggleCardClick
  );

  const company = job?.arbeitgeber;
  const position = job?.beruf;
  const subtitle = job?.titel;
  const regions = jobDetails?.arbeitsorte;
  const location = job?.arbeitsort?.ort;
  const externalUrl = job?.externeUrl;
  const zipCode = job?.arbeitsort?.plz;

  const [jobInfo, setJobInfo] = useState({
    position,
    company,
    subtitle,
    location,
  });

  const [containerCardBoxShadow, setContainerCardBoxShadow] = useState(
    '0px 4px 43.35px 7.65px rgba(107, 106, 106, 0.2)'
  );

  const [jobDescription, setJobDescription] = useState(null);
  const [uniqueRegions, setUniqueRegions] = useState({
    uniqueCities: [],
    length: null,
  });

  const reduceTextLengthHandler = () => {
    setJobInfo({
      position: customLengthSlicer(position, length),
      company: customLengthSlicer(company, length),
      subtitle: customLengthSlicer(subtitle, length),
      location: customLengthSlicer(location, length),
    });
    const reduceJobDescriptionLength = customLengthSlicer(
      jobDetails?.stellenbeschreibung,
      400
    );
    setJobDescription(reduceJobDescriptionLength);
  };

  useEffect(() => {
    reduceTextLengthHandler();
  }, [position, company, subtitle, location, jobDetails, job]);

  useEffect(() => {
    toggleCardBoxShadow();
  }, [open, currentIndex]);

  useEffect(() => {
    if (regions) {
      const uniqueCities = new Set(regions.map((item) => item.region));
      setUniqueRegions({
        uniqueCities: [...uniqueCities],
        length: uniqueCities.size,
      });
    }
  }, [regions]);

  const handleOnMouseEnter = () => {
    if (currentIndex !== index) {
      setContainerCardBoxShadow(
        '0px 4px 43.35px 7.65px rgba(107, 106, 106, 0.5)'
      );
    }
  };

  const handleOnMouseLeave = () => {
    if (currentIndex !== index) {
      setContainerCardBoxShadow(
        '0px 4px 43.35px 7.65px rgba(107, 106, 106, 0.2)'
      );
    }
  };

  const toggleCardBoxShadow = () => {
    // console.log('toggleCardBoxShadow', currentIndex, index);
    if (open && currentIndex === index) {
      setContainerCardBoxShadow('0 0 43.35px 7.65px rgba(212, 36, 212, 0.5)');
    } else {
      setContainerCardBoxShadow(
        '0px 4px 43.35px 7.65px rgba(107, 106, 106, 0.2)'
      );
    }
  };

  const handleClick = () => {
    setCurrentIndex(index);
    setCurrentJobDetails({
      jobDetails,
      index,
      companyLogo,
    });
    setOpen(true);
    toggleCardClick();
  };

  return (
    <Card
      component={motion.div}
      // layout
      key={index}
      className={`job-search-card-container ${className}`}
      sx={{
        height: '100%',
        boxShadow: containerCardBoxShadow,
        borderRadius: '1.5rem',
        backgroundColor: '#f2f2f2',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      onClick={() => {
        handleClick();
      }}
    >
      <CardActionArea
        className='cardActionArea'
        sx={{
          minHeight: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          cursor: currentIndex === index ? 'default !important' : 'pointer',
        }}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      >
        {companyLogo?.imageData && (
          <CardMedia
            image={companyLogo?.imageURL}
            // image={companyLogoUrl}
            title={`Company Logo - ${jobInfo.company}`}
            sx={{
              objectFit: 'contain',
              ObjectPosition: 'center',
              height: 240,
              width: '100%',
              backgroundSize: 'contain',
              padding: '1rem',
              backgroundOrigin: 'content-box',
              backgroundColor: '#fff',
              boxShadow: '0px -2px 11px 0px rgb(0 0 0 / 38%)',
            }}
          />
        )}

        {!companyLogo?.imageData && (
          <BackgroundLetterAvatars
            name={job?.arbeitgeber}
            variant='rounded'
            stringavatarstyle={{
              height: '160px',
              width: '160px',
              fontSize: '3rem',
              fontWeight: 'bold',
            }}
            sx={{
              bgcolor: '#fff',
              height: 240,
              width: '100%',
            }}
          />
        )}

        <CardContent
          className='card-content'
          sx={{
            width: 'inherit',
            // height: 400,
          }}
        >
          <CardHeader
            title={jobInfo.company}
            subheader={
              <Typography
                variant='body2'
                color='text.secondary'
              >
                {jobInfo.subtitle}
              </Typography>
            }
            component='h2'
          />
          <Typography
            gutterBottom
            variant='h5'
            component='div'
            sx={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            {jobInfo.position}
          </Typography>
          <Typography
            variant='h6'
            component='h2'
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {jobInfo.location}
            {uniqueRegions?.length > 1 ? (
              <HtmlTooltip
                title={uniqueRegions?.uniqueCities
                  ?.map((item) => item)
                  .join('\n\n')}
                placement='right'
                TransitionComponent={Zoom}
                sx={{
                  // change the width of the tooltip
                  '&.tooltip': {
                    width: 'auto',
                    maxWidth: 'none',
                  },
                }}
              >
                <Typography
                  variant='body2'
                  component='span'
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    color: '#000',
                  }}
                >
                  {' '}
                  + {uniqueRegions?.length} more
                </Typography>
              </HtmlTooltip>
            ) : null}
          </Typography>
          {/* NOTE Little Details here. */}
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              textAlign: 'left',
            }}
          >
            {jobDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        <TypographyWithTitle
          titleProps={{
            text: 'Current publication date:',
            className: classes.boldTypography,
          }}
          contentProps={{ text: job.aktuelleVeroeffentlichungsdatum }}
        />

        <TypographyWithTitle
          titleProps={{
            text: 'Entry date:',
            className: classes.boldTypography,
          }}
          contentProps={{
            text: job.eintrittsdatum,
          }}
        />
      </CardActions>
      {children}
    </Card>
  );
}
